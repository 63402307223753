/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import PageWrapper from '../../../modules/PageWrapper';
import {useStore} from '../../../../store/store';
import Card from '../../../shared/Card';
import {useDropzone} from 'react-dropzone';
import {Button} from '../../../shared/Buttons';

const Reports = observer(({timeConfig, isVerify, setPreloader, id}) => {
  const store = useStore();

  const [data, setData] = useState({});

  useEffect(() => {
    isVerify && getData();
    setPreloader(false);
  }, [timeConfig, isVerify]);

  const getData = async () => {};

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: {
      'text/csv': [],
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-5'>
          <div
            className='card-title m-0'
            style={{flexDirection: 'column', alignItems: 'flex-start'}}
          >
            <p className='m-0'>Загрузка отчета</p>
            <p className='text-gray-600 fs-7'>
              Файл обязательно должен быть <span className='text-danger'>.csv</span> расширения
            </p>
          </div>
          <section className='container'>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <i className='ki-duotone ki-cloud-download fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside className='mt-4 pb-4'>
              <h4>Files:</h4>
              <ul>{files}</ul>
            </aside>
            <div className='pb-4'>
              <Button className=''>Отправить</Button>
            </div>
          </section>
        </div>
      </Card>
    </>
  );
});

const ReportsWrapper = () => {
  const {id} = useParams();
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: `/dashboard/main`},
        {isSeparator: true},
        {title: 'Отчеты', path: `/advertisers/reports`},
      ]}
      title={`Отчеты`}
    >
      <Reports id={id} />
    </PageWrapper>
  );
};

export default ReportsWrapper;
