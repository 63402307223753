/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {CreateAppModal} from '../../../../_metronic/partials'
import {Button} from '../../../shared/Buttons'

const ChangeCustomer = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  return (
    <>
      <div className='mb-10'>
        <Button
          color='btn-light-primary'
          action={() => {
            setShowCreateAppModal(true)
          }}
        >
          Change Customer
        </Button>
      </div>
      <CreateAppModal
        customWidth='mw-650px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title={''}
        showHeader={false}
      >
        <div className='text-center mb-12'>
          <h1 className='fw-bold mb-3'>Search Customers</h1>
          <div className='text-gray-400 fw-semibold fs-5'>Add a customer to a subscription</div>
        </div>

        <div
          id='kt_modal_customer_search_handler'
          data-kt-search-keypress='true'
          data-kt-search-min-length='2'
          data-kt-search-enter='enter'
          data-kt-search-layout='inline'
        >
          <form
            data-kt-search-element='form'
            className='w-100 position-relative mb-5'
            autocomplete='off'
          >
            <input type='hidden' />

            <i className='ki-duotone ki-magnifier fs-2 fs-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid px-15'
              name='search'
              value=''
              placeholder='Search by username, full name or email...'
              data-kt-search-element='input'
            />

            <span
              className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'
              data-kt-search-element='spinner'
            >
              <span className='spinner-border h-15px w-15px align-middle text-gray-400'></span>
            </span>

            <span
              className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none'
              data-kt-search-element='clear'
            >
              <i className='ki-duotone ki-cross fs-2 fs-lg-1 me-0'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </form>

          <div className='py-5'>
            <div data-kt-search-element='suggestions'>
              <div className='text-center px-4 pt-10'>
                <img
                  src='assets/media/illustrations/sketchy-1/4.png'
                  alt=''
                  className='mw-100 mh-200px'
                />
              </div>
            </div>

            <div data-kt-search-element='results' className='d-none'>
              <div className='mh-300px scroll-y me-n5 pe-5'>
                <div
                  className='d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1'
                  data-kt-search-element='customer'
                >
                  <div className='symbol symbol-35px symbol-circle me-5'>
                    <img alt='Pic' src='assets/media/avatars/300-6.jpg' />
                  </div>

                  <div className='fw-semibold'>
                    <span className='fs-6 text-gray-800 me-2'>Emma Smith</span>
                    <span className='badge badge-light'>Art Director</span>
                  </div>
                </div>
              </div>
            </div>

            <div data-kt-search-element='empty' className='text-center d-none'>
              <div className='fw-semibold py-0 mb-10'>
                <div className='text-gray-600 fs-3 mb-2'>No users found</div>
                <div className='text-gray-400 fs-6'>
                  Try to search by username, full name or email...
                </div>
              </div>

              <div className='text-center px-4'>
                <img
                  src='assets/media/illustrations/sketchy-1/9.png'
                  alt='user'
                  className='mw-100 mh-200px'
                />
              </div>
            </div>
          </div>
        </div>
      </CreateAppModal>
    </>
  )
}

export default ChangeCustomer
