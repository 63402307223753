/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import Pagination from '../../../shared/Pagination';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';

const DepartmentsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Департаменты', path: 'users/departments', isActive: true},
      ]}
      title={'Департаменты'}
    >
      <Departments />
    </PageWrapper>
  );
};

const Departments = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    isVerify && getDepartments();
  }, [isVerify]);

  const getDepartments = async () => {
    const result = await store.departamentsGetList();
    setDepartments(result);
    setPreloader(false);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-13'
                placeholder='Поиск'
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* <Link to={'/advertisers/departments/addOffer'}> */}
              {store.roles.includes(13) || store.roles.includes(26) || store.roles.includes(32) ? (
                <></>
              ) : (
                <Button>
                  <>
                    <i className='ki-duotone ki-plus fs-2'></i>Добавить
                  </>
                </Button>
              )}
              {/* </Link> */}
            </div>
          </div>
        </div>
        <Table departments={departments} getDepartments={getDepartments} />
      </Card>
    </>
  );
});

const Table = observer(({departments, getDepartments}) => {
  const store = useStore();
  const [portionDepartments, setPortionDepartments] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    const newDepartments = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (departments.length > i) {
        newDepartments.push(departments[i]);
      }
    }
    setPortionDepartments(newDepartments);
  }, [offset, departments]);

  useEffect(() => {
    setOffset(1);
  }, [departments]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>департамент</th>
                <th
                  className={`min-w-125px ${
                    store.roles.includes(13) || store.roles.includes(26) || store.roles.includes(32)
                      ? 'text-end'
                      : 'text-center'
                  }`}
                >
                  сотрудники
                </th>
                {store.roles.includes(13) ||
                store.roles.includes(26) ||
                store.roles.includes(32) ? (
                  <></>
                ) : (
                  <th className='text-end min-w-100px'>Действие</th>
                )}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {portionDepartments.map((el, i) => {
                return <Department key={el.id} data={el} getDepartments={getDepartments} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {departments.length > limit && (
        <Pagination
          arrLength={departments.length}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )}
    </div>
  );
});

const Department = observer(({data}) => {
  const store = useStore();
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={data.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug}
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.name}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td
        className={`min-w-125px ${
          store.roles.includes(13) || store.roles.includes(26) || store.roles.includes(32)
            ? 'text-end'
            : 'text-center'
        }`}
      >
        <div className='badge badge-light-primary fw-bold'>{data?.count_users}</div>
      </td>
      {store.roles.includes(13) || store.roles.includes(26) || store.roles.includes(32) ? (
        <></>
      ) : (
        <td className='text-end'>
          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <i className='ki-duotone ki-pencil fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>
        </td>
      )}
    </tr>
  );
});

export default DepartmentsWrapper;
