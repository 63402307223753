/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from '../../../../../shared/Buttons';

const Source = ({sourceLink}) => {
  return (
    <>
      {!!sourceLink && (
        <a href={`${sourceLink}`}>
          <Button className={`me-3 `} color='btn-light'>
            Источник
          </Button>
        </a>
      )}
      {!sourceLink && (
        <Button className={`me-3 `} color='btn-light' disabled={true}>
          Источник
        </Button>
      )}
    </>
  );
};

export default Source;
