import imagePlug from '../../_metronic/assets/media/user.png';

export const formateImage = (image, setImage) => {
  if (typeof image === 'object' && !!image) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageDataUrl = e.target.result;

      return setImage(`url(${imageDataUrl})`);
    };

    reader.readAsDataURL(image);
  } else if (!!image && typeof image === 'string') {
    return setImage(`url(${process.env.REACT_APP_API_HOST}/${image})`);
  } else if (!image) {
    return setImage(`url(${imagePlug})`);
  }
};
