/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';

import css from '../../styles/CustomSlider.module.scss';
import Card from './Card';

const WidgetCarousel = ({title, subTitle, children = []}) => {
  useEffect(() => {
    const el = document.querySelector('.slick-dots');
    const header = document.querySelector(`.${css.header}`);
    if (!!el) {
      el.style.top = `-${header.offsetHeight - 20}px`;
    }
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <></>,
    prevArrow: <></>,
    appendDots: (dots) => (
      <div>
        <ul className={css.dotsContainer}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => <div className={css.customDots}></div>,
  };

  return (
    <Card className='card card-flush carousel carousel-custom carousel-stretch slide h-xl-100'>
      <div className={classNames(css.header, 'card-header pt-5')}>
        <h4 className='card-title d-flex align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>{title}</span>
          <span className='text-gray-400 mt-1 fw-bold fs-7'>{subTitle}</span>
        </h4>
      </div>
      <div className='card-body py-6'>
        <Slider {...settings}>{children}</Slider>
      </div>
    </Card>
  );
};

export default WidgetCarousel;
