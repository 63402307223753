/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CreateAppModal} from '../../../../_metronic/partials'
import {Button} from '../../../shared/Buttons'
import Card from '../../../shared/Card'

const PaymentMethod = () => {
  return (
    <Card className='card card-flush pt-3 mb-5 mb-lg-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h2 className='fw-bold'>Payment Method</h2>
        </div>

        <NewMethod />
      </div>

      <div className='card-body pt-0'>
        <div id='kt_create_new_payment_method'>
          <div className='py-1'>
            <div className='py-3 d-flex flex-stack flex-wrap'>
              <div
                className='d-flex align-items-center collapsible toggle'
                data-bs-toggle='collapse'
                data-bs-target='#kt_create_new_payment_method_1'
              >
                <div className='btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2'>
                  <i className='ki-duotone ki-minus-square toggle-on text-primary fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <i className='ki-duotone ki-plus-square toggle-off fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </div>

                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                  className='w-40px me-3'
                  alt=''
                />

                <div className='me-3'>
                  <div className='d-flex align-items-center fw-bold'>
                    Mastercard
                    <div className='badge badge-light-primary ms-5'>Primary</div>
                  </div>
                  <div className='text-muted'>Expires Dec 2024</div>
                </div>
              </div>

              <div className='d-flex my-3 ms-9'>
                <label className='form-check form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='payment_method'
                    checked='checked'
                  />
                </label>
              </div>
            </div>

            <div id='kt_create_new_payment_method_1' className='collapse show fs-6 ps-10'>
              <div className='d-flex flex-wrap py-5'>
                <div className='flex-equal me-5'>
                  <table className='table table-flush fw-semibold gy-1'>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Name</td>
                      <td className='text-gray-800'>Emma Smith</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Number</td>
                      <td className='text-gray-800'>**** 8056</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Expires</td>
                      <td className='text-gray-800'>12/2024</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Type</td>
                      <td className='text-gray-800'>Mastercard credit card</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Issuer</td>
                      <td className='text-gray-800'>VICBANK</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>ID</td>
                      <td className='text-gray-800'>id_4325df90sdf8</td>
                    </tr>
                  </table>
                </div>

                <div className='flex-equal'>
                  <table className='table table-flush fw-semibold gy-1'>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Billing address</td>
                      <td className='text-gray-800'>AU</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Phone</td>
                      <td className='text-gray-800'>No phone provided</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Email</td>
                      <td className='text-gray-800'>
                        <span className='text-gray-900 text-hover-primary'>smith@kpmg.com</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Origin</td>
                      <td className='text-gray-800'>
                        Australia
                        <div className='symbol symbol-20px symbol-circle ms-2'>
                          <img src={toAbsoluteUrl('/media/flags/australia.svg')} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>CVC check</td>
                      <td className='text-gray-800'>
                        Passed
                        <i className='ki-duotone ki-check-circle fs-2 text-success'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='separator separator-dashed'></div>

          <div className='py-1'>
            <div className='py-3 d-flex flex-stack flex-wrap'>
              <div
                className='d-flex align-items-center collapsible toggle collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_create_new_payment_method_2'
              >
                <div className='btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2'>
                  <i className='ki-duotone ki-minus-square toggle-on text-primary fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <i className='ki-duotone ki-plus-square toggle-off fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </div>

                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                  className='w-40px me-3'
                  alt=''
                />

                <div className='me-3'>
                  <div className='d-flex align-items-center fw-bold'>Visa</div>
                  <div className='text-muted'>Expires Feb 2022</div>
                </div>
              </div>

              <div className='d-flex my-3 ms-9'>
                <label className='form-check form-check-custom form-check-solid me-5'>
                  <input className='form-check-input' type='radio' name='payment_method' />
                </label>
              </div>
            </div>

            <div id='kt_create_new_payment_method_2' className='collapse fs-6 ps-10'>
              <div className='d-flex flex-wrap py-5'>
                <div className='flex-equal me-5'>
                  <table className='table table-flush fw-semibold gy-1'>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Name</td>
                      <td className='text-gray-800'>Melody Macy</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Number</td>
                      <td className='text-gray-800'>**** 3597</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Expires</td>
                      <td className='text-gray-800'>02/2022</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Type</td>
                      <td className='text-gray-800'>Visa credit card</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Issuer</td>
                      <td className='text-gray-800'>ENBANK</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>ID</td>
                      <td className='text-gray-800'>id_w2r84jdy723</td>
                    </tr>
                  </table>
                </div>

                <div className='flex-equal'>
                  <table className='table table-flush fw-semibold gy-1'>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Billing address</td>
                      <td className='text-gray-800'>UK</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Phone</td>
                      <td className='text-gray-800'>No phone provided</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Email</td>
                      <td className='text-gray-800'>
                        <span className='text-gray-900 text-hover-primary'>melody@altbox.com</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Origin</td>
                      <td className='text-gray-800'>
                        United Kingdom
                        <div className='symbol symbol-20px symbol-circle ms-2'>
                          <img src={toAbsoluteUrl('/media/flags/united-kingdom.svg')} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>CVC check</td>
                      <td className='text-gray-800'>
                        Passed
                        <i className='ki-duotone ki-check fs-2 text-success'></i>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='separator separator-dashed'></div>

          <div className='py-1'>
            <div className='py-3 d-flex flex-stack flex-wrap'>
              <div
                className='d-flex align-items-center collapsible toggle collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_create_new_payment_method_3'
              >
                <div className='btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2'>
                  <i className='ki-duotone ki-minus-square toggle-on text-primary fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <i className='ki-duotone ki-plus-square toggle-off fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </div>

                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                  className='w-40px me-3'
                  alt=''
                />

                <div className='me-3'>
                  <div className='d-flex align-items-center fw-bold'>
                    American Express
                    <div className='badge badge-light-danger ms-5'>Expired</div>
                  </div>
                  <div className='text-muted'>Expires Aug 2021</div>
                </div>
              </div>

              <div className='d-flex my-3 ms-9'>
                <label className='form-check form-check-custom form-check-solid me-5'>
                  <input className='form-check-input' type='radio' name='payment_method' />
                </label>
              </div>
            </div>

            <div id='kt_create_new_payment_method_3' className='collapse fs-6 ps-10'>
              <div className='d-flex flex-wrap py-5'>
                <div className='flex-equal me-5'>
                  <table className='table table-flush fw-semibold gy-1'>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Name</td>
                      <td className='text-gray-800'>Max Smith</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Number</td>
                      <td className='text-gray-800'>**** 9826</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Expires</td>
                      <td className='text-gray-800'>08/2021</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Type</td>
                      <td className='text-gray-800'>American express credit card</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Issuer</td>
                      <td className='text-gray-800'>USABANK</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>ID</td>
                      <td className='text-gray-800'>id_89457jcje63</td>
                    </tr>
                  </table>
                </div>

                <div className='flex-equal'>
                  <table className='table table-flush fw-semibold gy-1'>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Billing address</td>
                      <td className='text-gray-800'>US</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Phone</td>
                      <td className='text-gray-800'>No phone provided</td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Email</td>
                      <td className='text-gray-800'>
                        <span className='text-gray-900 text-hover-primary'>max@kt.com</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>Origin</td>
                      <td className='text-gray-800'>
                        United States of America
                        <div className='symbol symbol-20px symbol-circle ms-2'>
                          <img src={toAbsoluteUrl('/media/flags/united-states.svg')} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted min-w-125px w-125px'>CVC check</td>
                      <td className='text-gray-800'>
                        Failed
                        <i className='ki-duotone ki-cross fs-2 text-danger'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const NewMethod = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  return (
    <>
      <div className='card-toolbar'>
        <Button
          color='btn-light-primary'
          action={() => {
            setShowCreateAppModal(true)
          }}
        >
          Add New Card
        </Button>
      </div>
      <CreateAppModal
        customWidth='mw-650px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title={'Add a Product'}
      >
        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
          <form id='kt_modal_new_card_form' className='form' action='#'>
            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Name On Card</span>
                <span
                  className='ms-1'
                  data-bs-toggle='tooltip'
                  title="Specify a card holder's name"
                >
                  <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </span>
              </label>

              <input
                type='text'
                className='form-control form-control-solid'
                placeholder=''
                name='card_name'
                value='Max Doe'
              />
            </div>

            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='required fs-6 fw-semibold form-label mb-2'>Card Number</label>

              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter card number'
                  name='card_number'
                  value='4111 1111 1111 1111'
                />

                <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                    alt=''
                    className='h-25px'
                  />
                </div>
              </div>
            </div>

            <div className='row mb-10'>
              <div className='col-md-8 fv-row'>
                <label className='required fs-6 fw-semibold form-label mb-2'>Expiration Date</label>

                <div className='row fv-row'>
                  <div className='col-6'>
                    <select
                      name='card_expiry_month'
                      className='form-select form-select-solid'
                      data-control='select2'
                      data-hide-search='true'
                      data-placeholder='Month'
                    >
                      <option></option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                      <option value='12'>12</option>
                    </select>
                  </div>

                  <div className='col-6'>
                    <select
                      name='card_expiry_year'
                      className='form-select form-select-solid'
                      data-control='select2'
                      data-hide-search='true'
                      data-placeholder='Year'
                    >
                      <option></option>
                      <option value='2023'>2023</option>
                      <option value='2024'>2024</option>
                      <option value='2025'>2025</option>
                      <option value='2026'>2026</option>
                      <option value='2027'>2027</option>
                      <option value='2028'>2028</option>
                      <option value='2029'>2029</option>
                      <option value='2030'>2030</option>
                      <option value='2031'>2031</option>
                      <option value='2032'>2032</option>
                      <option value='2033'>2033</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-md-4 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>CVV</span>
                  <span className='ms-1' data-bs-toggle='tooltip' title='Enter a card CVV code'>
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </label>

                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    minlength='3'
                    maxlength='4'
                    placeholder='CVV'
                    name='card_cvv'
                  />

                  <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                    <i className='ki-duotone ki-credit-cart fs-2hx'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack'>
              <div className='me-5'>
                <label className='fs-6 fw-semibold form-label'>
                  Save Card for further billing?
                </label>
                <div className='fs-7 fw-semibold text-muted'>
                  If you need more info, please check budget planning
                </div>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='1' checked='checked' />
                <span className='form-check-label fw-semibold text-muted'>Save Card</span>
              </label>
            </div>

            <div className='text-center pt-15'>
              <Button color='btn-light' className='me-3'>
                Discard
              </Button>
              <Button>Submit</Button>
            </div>
          </form>
        </div>
      </CreateAppModal>
    </>
  )
}

export default PaymentMethod
