import {api} from './api';

const dashboardsAPI = {
  async getData(method, token, timeConfig, filter = '') {
    try {
      if (!!timeConfig?.time_config) {
        const response = await api.get(
          `${method}?token=${token}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}${filter}`
        );

        return response?.data;
      }
      const response = await api.get(`${method}?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async dashboardSalesTeamLeadGetActives(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Dashboard/Sales/TeamLead/getActives${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  
  async dashboardSalesAssistantGetActives(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Dashboard/Sales/Assistant/getActives${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default dashboardsAPI;
