import React, {useState} from 'react';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';

const AddPermissions = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <Button color={'btn-light-primary'} action={() => setShowCreateAppModal(true)}>
        <i className='ki-duotone ki-plus-square fs-3'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
        </i>{' '}
        Add Permission
      </Button>
      <CreateAppModal
        customWidth='mw-650px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title={'Add a Permission'}
      >
        <form
          id='kt_modal_add_permission_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          action='#'
        >
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Permission Name</span>

              <span
                className='ms-2'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-information fs-7'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
              </span>
            </label>

            <input
              className='form-control form-control-solid'
              placeholder='Enter a permission name'
              name='permission_name'
            />

            <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
          </div>

          <div className='fv-row mb-7'>
            <label className='form-check form-check-custom form-check-solid me-9'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                name='permissions_core'
                id='kt_permissions_core'
              />
              <span className='form-check-label' for='kt_permissions_core'>
                Set as core permission
              </span>
            </label>
          </div>

          <div className='text-gray-600'>
            Permission set as a <strong className='me-1'>Core Permission</strong> will be locked and{' '}
            <strong className='me-1'>not editable</strong> in future
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-permissions-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        </form>
      </CreateAppModal>
    </>
  );
};

export default AddPermissions;
