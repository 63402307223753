import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import classNames from 'classnames';

const Navigation = ({links = []}) => {
  const location = useLocation();

  return (
    <>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap overflow-x-auto overflow-y-hidden'>
        {links.map((el, i) => {
          return (
            <li
              className='nav-item mt-2 text-nowrap'
              style={{cursor: 'pointer', marginBottom: '0px'}}
              key={el.link}
            >
              <Link
                to={`${el.link}`}
                className={classNames(
                  'nav-link text-active-primary ms-0 me-10 py-5',
                  location.pathname === `${el.link}` && 'active'
                )}
              >
                {el.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Navigation;
