/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import numeral from 'numeral';
import Finances from '../../../widgets/Finances';
import Chart from '../../../widgets/Chart';
import PageWrapper from '../../../modules/PageWrapper';
import {formatNumber} from '../../../functions/formatNumber';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();

  const [salesStats, setSalesStats] = useState({});

  const [qualificationSchedule, setQualificationSchedule] = useState([]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const getMainStats = await store.getMainStatsFacebook(timeConfig);
    const getQualificationSchedule = await store.getQualificationScheduleFacebook(timeConfig);
    setQualificationSchedule(getQualificationSchedule);
    setSalesStats(getMainStats);

    setPreloader(false);
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10 mt-0'>
          <Finances
            background={'fb'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Пользователей',
                number: formatNumber(salesStats?.count_leads) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Регистрации',
                number: formatNumber(salesStats?.count_registrations) || 0,
                icon: (
                  <i className='ki-duotone ki-user-edit fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Обращения',
                number: formatNumber(salesStats?.count_appeals) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(salesStats?.count_qualifications) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mb-xl-10 mt-xl-0'>
          <Chart data={{graph: qualificationSchedule}} />
        </div>
      </div>
    </>
  );
});

const FacebookPanel = () => {
  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Панель', path: '/dashboard/facebook', isActive: true},
      ]}
      title={'Панель Facebook'}
    >
      <DashboardPage />
    </PageWrapper>
  );
};

export default FacebookPanel;
