/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import PageWrapper from '../../../../modules/PageWrapper';
import CompaniesList from '../../influence/bloggers/list/CompaniesList';

const ListWrapper = () => {
  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Фейсбук Трафик', path: '/traffics/facebook/companies', isActive: true},
      ]}
      title={'Фейсбук Трафик'}
    >
      <List />
    </PageWrapper>
  );
};

const List = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();

  const [bloggers, setBloggers] = useState([]);

  useEffect(() => {
    isVerify && !!timeConfig.time_config && getBloggersList();
  }, [isVerify, timeConfig]);

  const getBloggersList = async () => {
    const [getBloggers, getRomiList] = await Promise.all([
      store.trafficsFacebookGetTrafficsList(timeConfig),
      store.financeCategoryGetRomiList(),
    ]);

    const newBloggers = getBloggers.map((el) => {
      const romi = Math.round(+el.romi);
      const indicator = getRomiList.find(
        (item) => romi >= +item.romi_min && romi <= +item.romi_max
      );
      return {...el, indicator};
    });

    setBloggers(newBloggers);
    setPreloader(false);
  };

  return (
    <>
      <CompaniesList
        bloggers={bloggers}
        getBloggersList={getBloggersList}
        type={'facebook'}
        title={'Компании'}
      />
    </>
  );
});

export default ListWrapper;
