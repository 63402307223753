/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import ReactSelect from 'react-select';

const PaginationBe = ({
  arrLength,
  limit,
  page,
  setPage,
  action,
  perPage = null,
  changePerPage = null,
  isCentered = false,
}) => {
  const [buttons, setButtons] = useState([]);
  const [portion, setPortion] = useState([]);
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    const totalPages = Math.ceil(arrLength / limit);
    setTotalPages(totalPages);
    const maxButtons = 3;
    const current = page || 1;
    const middleButtonIndex = Math.floor(maxButtons / 2);

    let startPage, endPage;
    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (current <= middleButtonIndex) {
        startPage = 1;
        endPage = maxButtons;
      } else if (current + middleButtonIndex >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = current - middleButtonIndex;
        endPage = current + middleButtonIndex;
      }
    }

    const newButtons = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
    setButtons(newButtons);
    setPortion(newButtons);
  }, [arrLength, limit, page]);

  return (
    <>
      <div className={isCentered ? 'd-flex justify-content-center flex-stack flex-wrap pt-10' : 'd-flex justify-content-end flex-stack flex-wrap pt-10'}>
        {buttons.length > 1 && (
          <div className='pagination'>
            <li
              className='page-item previous cursor-pointer'
              onClick={() => {
                page !== 1 && setPage(page - 1);
                page !== 1 && action(page - 1);
              }}
            >
              <span className='page-link'>
                <i className='previous'></i>
              </span>
            </li>

            {page > 3 && (
              <>
                <li
                  className={`page-item next cursor-pointer `}
                  onClick={() => {
                    setPage(1);
                    action(1);
                  }}
                >
                  <span className='page-link'>{1}</span>
                </li>
                <li
                  className={``}
                  style={{display: 'flex', alignItems: 'center', marginRight: '0.5rem'}}
                >
                  <span className=''>
                    {'.'} {'.'} {'.'}
                  </span>
                </li>
              </>
            )}
            {portion.map((item) => (
              <li
                className={`page-item next cursor-pointer ${page === item && 'active'}`}
                key={item}
                onClick={() => {
                  setPage(item);
                  action(item);
                }}
              >
                <span className='page-link'>{item}</span>
              </li>
            ))}
            {page < totalPages - 2 && (
              <>
                <li
                  className={``}
                  style={{display: 'flex', alignItems: 'center', marginRight: '0.5rem'}}
                >
                  <span className=''>
                    {'.'} {'.'} {'.'}
                  </span>
                </li>
                <li
                  className={`page-item next cursor-pointer `}
                  onClick={() => {
                    setPage(totalPages);
                    action(totalPages);
                  }}
                >
                  <span className='page-link'>{totalPages}</span>
                </li>
              </>
            )}

            <li
              className='page-item next cursor-pointer'
              onClick={() => {
                page !== buttons[buttons.length - 1] && setPage(page + 1);
                page !== buttons[buttons.length - 1] && action(page + 1);
              }}
            >
              <span className='page-link'>
                <i className='next'></i>
              </span>
            </li>
          </div>
        )}
        {!!perPage && (
          <div style={{marginLeft: '10px'}}>
            <ReactSelect
              isSearchable={false}
              menuPlacement='auto'
              options={[
                {label: 5, value: 5},
                {label: 10, value: 10},
                {label: 20, value: 20},
                {label: 50, value: 50},
              ]}
              value={{label: perPage, value: perPage}}
              onChange={(e) => changePerPage(e.value)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PaginationBe;
