import React from 'react'
import {Button} from '../../../shared/Buttons'
import Card from '../../../shared/Card'

const RecentEvents = () => {
  return (
    <>
      <Card className='card card-flush pt-3 mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h2>Recent Events</h2>
          </div>

          <div className='card-toolbar'>
            <Button color='btn-light-primary'>View All Events</Button>
          </div>
        </div>

        <div className='card-body pt-0'>
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5'
              id='kt_table_customers_events'
            >
              <tbody>
                <tr>
                  <td className='min-w-400px'>
                    <span className='fw-bold text-gray-800 text-hover-primary me-1'>
                      Emma Smith
                    </span>
                    has made payment to
                    <span className='fw-bold text-gray-800 text-hover-primary'>1633-1216</span>
                  </td>
                  <td className='pe-0 text-gray-600 text-end min-w-200px'>10 Nov 2023, 11:30 am</td>
                </tr>
                <tr>
                  <td className='min-w-400px'>
                    Invoice
                    <span className='fw-bold text-gray-800 text-hover-primary me-1'>6167-7095</span>
                    status has changed from
                    <span className='badge badge-light-info me-1'>In Progress</span>to
                    <span className='badge badge-light-primary'>In Transit</span>
                  </td>
                  <td className='pe-0 text-gray-600 text-end min-w-200px'>21 Feb 2023, 11:05 am</td>
                </tr>
                <tr>
                  <td className='min-w-400px'>
                    Invoice
                    <span className='fw-bold text-gray-800 text-hover-primary me-1'>6145-9164</span>
                    is
                    <span className='badge badge-light-info'>In Progress</span>
                  </td>
                  <td className='pe-0 text-gray-600 text-end min-w-200px'>22 Sep 2023, 9:23 pm</td>
                </tr>
                <tr>
                  <td className='min-w-400px'>
                    <span className='fw-bold text-gray-800 text-hover-primary me-1'>
                      Emma Smith
                    </span>
                    has made payment to
                    <span className='fw-bold text-gray-800 text-hover-primary'>1633-1216</span>
                  </td>
                  <td className='pe-0 text-gray-600 text-end min-w-200px'>10 Nov 2023, 11:30 am</td>
                </tr>
                <tr>
                  <td className='min-w-400px'>
                    Invoice
                    <span className='fw-bold text-gray-800 text-hover-primary me-1'>9814-8894</span>
                    status has changed from
                    <span className='badge badge-light-primary me-1'>In Transit</span>to
                    <span className='badge badge-light-success'>Approved</span>
                  </td>
                  <td className='pe-0 text-gray-600 text-end min-w-200px'>24 Jun 2023, 11:05 am</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  )
}

export default RecentEvents
