/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import Calendar from '../../../../../app/modules/calendar/Calendar';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import moment from 'moment';

const buttons = [
  {title: 'Неделя продаж', value: 'week_salles'},
  {title: 'Сегодня', value: 'today'},
  {title: 'Вчера', value: 'yesterday'},
  {title: 'За последние 7 дней', value: '7day'},
  {title: 'За последние 30 дней', value: '30day'},
  {title: 'Текущий месяц', value: 'current_month'},
  {title: 'Предыдущий месяц', value: 'last_month'},
  {title: 'Текущий год', value: 'year'},
];

const ToolbarCalendar = observer(({mode = ''}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [label, setLabel] = useState('');
  const [arrButtons, setArrButtons] = useState([]);

  useEffect(() => {
    if (mode === 'sales') {
      setArrButtons(buttons);
    } else {
      const newButtons = [...buttons];
      newButtons.splice(0, 1);
      setArrButtons(newButtons);
    }
  }, [mode]);

  useEffect(() => {
    if (store.toolbarLabel !== 'custom') {
      const res = buttons.find((el) => el.value === store?.toolbarLabel);

      return setLabel(res?.title);
    }

    const start = moment(store.timeConfig.time_start * 1000).format('DD.MM.YYYY');
    const end = moment(store.timeConfig.time_end * 1000).format('DD.MM.YYYY');

    if (start !== end) {
      return setLabel(`${start} - ${end}`);
    } else {
      return setLabel(start);
    }
  }, [store.toolbarLabel, store.timeConfig]);

  return (
    <>
      <div>
        <div
          data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
          data-kt-menu-attach='parent'
          data-kt-menu-placement={'bottom-end'}
          className='btn btn-sm bg-body shadow-xs d-flex align-items-center px-4'
        >
          <div className='text-gray-600 fw-bold'>{label || 'Loading date range...'}</div>
          <i className='ki-duotone ki-calendar-8 fs-1 ms-2 me-0'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
            <span className='path6'></span>
          </i>
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
          data-kt-menu='true'
        >
          {arrButtons.map((el) => {
            return (
              <div
                className='menu-item px-3'
                onClick={() => {
                  store.setTimeConfig({
                    time_config: el.value,
                    time_start: '',
                    time_end: '',
                  });
                }}
                key={el.value}
              >
                <span className='menu-link px-3' data-kt-users-table-filter='delete_row'>
                  {el.title}
                </span>
              </div>
            );
          })}
          <div
            className='menu-item px-3'
            onClick={() => {
              setShowCreateAppModal(!showCreateAppModal);
            }}
          >
            <span className='menu-link px-3' data-kt-users-table-filter='delete_row'>
              Выбрать период
            </span>
          </div>
        </div>

        {showCreateAppModal && (
          <Calendar
            showCreateAppModal={showCreateAppModal}
            setShowCreateAppModal={setShowCreateAppModal}
          />
        )}
      </div>
    </>
  );
});

export {ToolbarCalendar};
