/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import css from '../../../../styles/KpiRd.module.scss';
import classNames from 'classnames';
import PageWrapper from '../../../modules/PageWrapper';
import {useThemeMode} from '../../../../_metronic/partials';
import {formatNumberToCash} from '../../../functions/formatNumber';

const KpiLtcWrapper = () => {
  const [buttons, setButtons] = useState([{title: 'Менеджер', name: 'manager_kpi'}]);
  const [active, setActive] = useState('manager_kpi');

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'KPI RD', path: '/sales/kpi_rd', isActive: true},
      ]}
      title={'KPI RD'}
      buttonKPI={
        <div>
          <button
            type='button'
            className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            {buttons.find((item) => item.name === active)?.title}{' '}
            <i className='ki-duotone ki-down fs-5 ms-1'></i>
          </button>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
            data-kt-menu='true'
          >
            {buttons.map((item, index) => {
              return (
                <div className='menu-item px-3' key={`${item.name}-${index}`}>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      setActive(item.name);
                    }}
                  >
                    {item.title}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      }
    >
      <KpiRd setButtons={setButtons} active={active} />
    </PageWrapper>
  );
};

const KpiRd = observer(({isVerify, setPreloader, setButtons, active}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [data, setData] = useState([]);

  const [activeFields, setActiveFields] = useState({});

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const getKpiRdList = await store.salesGetKpiRdList();

      console.log(getKpiRdList);

      let headofsales = null;
      let manager = null;
      let teamleader = null;

      for (let i = 0; i < getKpiRdList.length; i++) {
        if (!!headofsales && !!manager && !!teamleader) break;

        if (!!getKpiRdList[i].headofsales_kpi.length && !headofsales) {
          headofsales = {title: 'Head of Sales', name: 'headofsales_kpi'};
        }
        if (!!getKpiRdList[i].manager_kpi.length && !manager) {
          manager = {title: 'Менеджер', name: 'manager_kpi'};
        }
        if (!!getKpiRdList[i].teamlead_kpi.length && !teamleader) {
          teamleader = {title: 'Тимлид', name: 'teamlead_kpi'};
        }
      }

      setActiveFields(
        getKpiRdList.map((item) => {
          return {active: false, id: item.id};
        })
      );

      setButtons([manager, teamleader, headofsales].filter((el) => el !== null));
      setData(getKpiRdList);
      setPreloader(false);
    })();
  }, [isVerify]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <div className={css.container}>
        {data.map((item, index) => {
          const newIndex = index + 1;
          return (
            <div
              className={classNames(
                css.accordion,
                css[`accordion-${newIndex}`],
                mode === 'dark' && css._dark
              )}
              key={`${item.id}-${active}`}
            >
              <section className={classNames(css.header, mode === 'dark' && css._dark)}>
                <div className={css.titleContainer}>
                  <span className={css.title}>KPI RD #{item.id}</span>
                  <span className={classNames(css.subTitle, 'text-gray-500')}>
                    {!item.count_lead_max && `${item.count_lead_min}+`}
                    {!item.count_lead_min && `0-${item.count_lead_max}`}
                    {!!item.count_lead_min &&
                      !!item.count_lead_max &&
                      `${item.count_lead_min}-${item.count_lead_max}`}{' '}
                    квалификаций
                  </span>
                </div>
                <label className={css.buttonContainer} htmlFor={`checkbox-${newIndex}`}>
                  <input
                    type='checkbox'
                    className={classNames(css.checkbox)}
                    id={`checkbox-${newIndex}`}
                    checked={activeFields.find((el) => el.id === item.id).active}
                    onChange={(event) => {
                      const newActiveFields = [...activeFields].map((el) => {
                        if (el.id === item.id) {
                          return {...el, active: event.target.checked};
                        }
                        return el;
                      });
                      setActiveFields(newActiveFields);
                    }}
                  />
                  <div className={classNames(css.button, mode === 'dark' && css._dark)}></div>
                </label>
              </section>
              <section className={css.body}>
                <div className={css.cardsList}>
                  {!!item[active].length &&
                    item[active]
                      .sort((a, b) => a.activity_min - b.activity_min)
                      .map((el) => {
                        return (
                          <div
                            className={classNames(css.card, mode === 'dark' && css._dark)}
                            key={`${el.activity_min}-${active}`}
                          >
                            <div className={css.title}>
                              <span>{el.activity_min}%</span>
                            </div>
                            {el.kpi
                              .sort((a, b) => b.RD - a.RD)
                              .map((kpi) => {
                                return (
                                  <div
                                    className={classNames(css.item, css[`_${kpi.indicator}`])}
                                    key={`${kpi.RD}-${active}`}
                                  >
                                    <span className={css.kpi}>RD {kpi.RD}$+</span>
                                    <span className={css.sum}>{kpi.pay}$</span>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                </div>
              </section>
            </div>
          );
        })}
      </div>
    </>
  );
});

export default KpiLtcWrapper;
