/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import userPlug from '../../../../_metronic/assets/media/user.png';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';
import Pagination from '../../../shared/Pagination';
import InputSearch from '../../../shared/InputSearch';
import Warning from '../../../modules/Warning';
import Card from '../../../shared/Card';

const List = ({isVerify, setPreloader}) => {
  const store = useStore();

  const [banUsers, setBanUsers] = useState([]);
  const [noBanUsers, setNoBanUsers] = useState([]);

  const [users, setUsers] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [portionUsers, setPortionUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);

  const [departmentsFilter, setDepartmentsFilter] = useState([]);

  const [typeStatus, setTypeStatus] = useState('noBan');

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const usersList = await store.getEmployees();
    const departmentsList = await store.departamentsGetList();
    const accessList = await store.usersGetAllAccess();
    const newUserList = [];
    for (let i = 0; i < usersList.length; i++) {
      let departments = departmentsList.find(
        (department) => department.id === usersList[i].departament_id
      );
      let accesses = accessList.roles.find((access) => access.id === usersList[i].role_id);

      newUserList.push({
        ...usersList[i],
        fullName: `${usersList[i].first_name} ${usersList[i].last_name}`,
        departments: departments || {},
        access: accesses || {},
      });
    }

    setDepartmentsFilter(departmentsList);
    setSearchUsers(newUserList.filter((item) => !item.is_ban));
    setNoBanUsers(newUserList.filter((item) => !item.is_ban));
    setBanUsers(newUserList.filter((item) => !!item.is_ban));
    setUsers(newUserList);
    setTypeStatus('noBan');
    setPreloader(false);
  };

  const sortStatus = (status) => {
    if (status) {
      setTypeStatus('noBan');
      setSearchUsers(noBanUsers);
    } else {
      setTypeStatus('ban');
      setSearchUsers(banUsers);
    }
  };

  const sortDepartments = (dep) => {
    let newUsers;
    if (typeStatus === 'noBan') {
      newUsers = noBanUsers.filter((item) => dep === item.departments.name);
    } else if (typeStatus === 'ban') {
      newUsers = banUsers.filter((item) => dep === item.departments.name);
    } else {
      newUsers = users.filter((item) => dep === item.departments.name);
    }
    setSearchUsers(newUsers);
  };

  useEffect(() => {
    const newUsers = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (searchUsers.length > i) {
        newUsers.push(searchUsers[i]);
      }
    }
    setPortionUsers(newUsers);
  }, [offset, searchUsers]);

  useEffect(() => {
    setOffset(1);
  }, [searchUsers]);

  return (
    <Card className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
          <InputSearch
            title={'Поиск...'}
            array={typeStatus === 'noBan' ? noBanUsers : typeStatus === 'ban' ? banUsers : users}
            newArray={setSearchUsers}
            searchParameters={['fullName', 'id']}
          />
          <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
            <button
              type='button'
              className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Статус <i className='ki-duotone ki-down fs-5 ms-1'></i>
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  onClick={() => {
                    sortStatus(true);
                  }}
                >
                  Активный
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    sortStatus(false);
                  }}
                >
                  Не активный
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    setSearchUsers(users);
                  }}
                >
                  Все статусы
                </span>
              </div>
            </div>
          </div>
          <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
            <button
              type='button'
              className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Департамент <i className='ki-duotone ki-down fs-5 ms-1'></i>
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-175px py-4'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  onClick={() => {
                    setTypeStatus('all');
                    setSearchUsers(users);
                  }}
                >
                  Все департаменты
                </span>
              </div>
              {departmentsFilter.map((el) => {
                return (
                  <div className='menu-item px-3' key={el.name}>
                    <span
                      className='menu-link px-3'
                      onClick={() => {
                        sortDepartments(el.name);
                      }}
                    >
                      {el.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='card-toolbar'>
          {store.roles.includes(13) || store.roles.includes(32) ? (
            <></>
          ) : (
            <Link to={'/users/list/addUser'}>
              <Button>
                <>
                  <i className='ki-duotone ki-plus fs-2'></i>Добавить
                </>
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className='card-body py-4'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px sorting' style={{width: '275.953px'}}>
                    Сотрудник
                  </th>
                  <th className='min-w-125px sorting' style={{width: '275.953px'}}>
                    Департамент
                  </th>
                  <th className='min-w-125px sorting' style={{width: '125px'}}>
                    Баланс
                  </th>
                  <th className='min-w-125px sorting' style={{width: '161.531px'}}>
                    Статус
                  </th>
                  <th
                    className='text-end min-w-100px sorting_disabled'
                    style={{width: '132.234px'}}
                  >
                    Действие
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {portionUsers.map((el) => {
                  return (
                    <tr className='odd' key={el.id}>
                      <td className='d-flex align-items-center'>
                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                          <span>
                            <div className='symbol-label'>
                              <img
                                src={
                                  !!el?.avatar
                                    ? `${process.env.REACT_APP_API_HOST}/${el.avatar}`
                                    : userPlug
                                }
                                alt='#'
                                className='w-100'
                              />
                            </div>
                          </span>
                        </div>

                        <div className='d-flex flex-column'>
                          <span className='text-gray-800 text-hover-primary mb-1 d-flex'>
                            {el.first_name} {el.last_name}
                            {!!el?.is_verified && (
                              <div
                                className='symbol symbol-35px symbol-circle'
                                data-bs-toggle='tooltip'
                                title={
                                  !!el?.verify_date
                                    ? moment(el?.verify_date * 1000).format('DD.MM.YYYY HH:mm')
                                    : 'Time not set'
                                }
                              >
                                <span>
                                  <i className='ki-duotone ki-verify fs-1 text-primary'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </span>
                              </div>
                            )}
                          </span>
                          <span>#{el.id}</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <span>
                              <div className='symbol-label'>
                                <img
                                  src={
                                    !!el?.departments?.avatar
                                      ? `${process.env.REACT_APP_API_HOST}/${el?.departments?.avatar}`
                                      : userPlug
                                  }
                                  alt='#'
                                  className='w-100'
                                />
                              </div>
                            </span>
                          </div>

                          <div className='d-flex flex-column'>
                            <span className='text-gray-800 text-hover-primary mb-1'>
                              {el?.departments?.name}
                            </span>
                            <span>{el?.access?.name}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='badge badge-light fw-bold'>${el.balance}</div>
                      </td>
                      <td>
                        {!!el.is_ban && (
                          <div className='badge badge-light-danger fw-bold'>Не активный</div>
                        )}
                        {!el.is_ban && (
                          <div className='badge badge-light-success fw-bold'>Активный</div>
                        )}
                      </td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link to={`/users/list/user/${el.id}/info`}>
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <i className='ki-duotone ki-switch fs-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </span>
                          </Link>
                          {store.roles.includes(13) || store.roles.includes(32) ? (
                            <></>
                          ) : (
                            <>
                              <Link to={`/users/list/user/${el.id}/settings`}>
                                <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                  <i className='ki-duotone ki-pencil fs-2'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </span>
                              </Link>

                              <Delete
                                fullName={el.fullName}
                                id={el.id}
                                status={el.is_ban}
                                searchUsers={searchUsers}
                                setSearchUsers={setSearchUsers}
                                setUsers={setUsers}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {searchUsers.length >= limit && (
            <Pagination
              arrLength={searchUsers.length}
              limit={limit}
              offset={offset}
              setOffset={setOffset}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const Delete = observer(({fullName, id, status, setSearchUsers, searchUsers, setUsers}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const banUser = async () => {
    const res = await store.usersBanUser(id, !status);

    if (!res) {
      return toast.error('Произошла ошибка в процесе деактивации пользователя!', {
        onClose: () => store.signOut(),
        theme: 'colored',
      });
    }

    const newSearchUsers = [...searchUsers].map((el) => {
      if (el.id === id) {
        el.is_ban = !status;
      }
      return el;
    });

    setSearchUsers(newSearchUsers);
    setUsers(newSearchUsers);

    toast.success(`Пользователь успешно ${!status ? 'деактивирован!' : 'активирован!'}`, {});
    setShowCreateAppModal(false);
  };

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        onClick={() => setShowCreateAppModal(true)}
      >
        {!status && (
          <i className='ki-duotone ki-trash fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        )}
        {!!status && (
          <i className='ki-duotone ki-basket-ok fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
          </i>
        )}
      </span>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <>
              Вы уверены, что хотите{' '}
              <span className='fw-bolder text-gray-700'>
                {!status ? 'деактивировать!' : 'активировать!'}`
              </span>
              , <span className='text-primary'>{fullName}</span> учетную запись?
            </>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                banUser();
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default observer(List);
