/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {Button} from '../../../../shared/Buttons';
import {CreateAppModal} from '../../../../../_metronic/partials';

const AddCrypto = ({
  setCards,
  setArrLength,
  arrLength,
  setCryptos,
  cryptos,
  setEditCrypto,
  editCrypto,
}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [data, setData] = useState({
    currency: 'USDT TRC-20',
    details: '',
    type: 'crypto',
    is_active: arrLength === 0 ? true : false,
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!!editCrypto) {
      setShowCreateAppModal(true);
      setData(editCrypto);
    }
  }, [editCrypto]);

  const changeData = (e) => {
    let {name, value, checked} = e.target;
    const newData = {...data};

    if (name === 'is_active') {
      newData[name] = checked;
      return setData(newData);
    }

    newData[name] = value;
    setData(newData);
  };

  const save = async () => {
    for (const key in data) {
      if (key === 'delete_date' || key === 'bank_name' || key === 'receiver_name') {
        continue;
      }

      if (data[key] === undefined || data[key] === null || data[key] === '') {
        return setError(true);
      }
    }

    setError(false);

    if (!!editCrypto) {
      if (data.is_active === !!editCrypto.is_active) {
        return;
      }

      const result = await store.updateWallet(data);
      notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

      return await updatedArray();
    }

    const result = await store.addWallet(data);
    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');
    return await updatedArray();
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  const updatedArray = async () => {
    const wallets = await store.getWallets();

    setArrLength(wallets.length);

    const newCards = [];
    const newCryptos = [];

    wallets.forEach((el) => {
      el.type === 'bank' ? newCards.push(el) : newCryptos.push(el);
    });
    setCards(newCards);
    setCryptos(newCryptos);

    setData({currency: 'USDT TRC-20', details: '', type: 'crypto', is_active: false});
    setEditCrypto(null);

    return setShowCreateAppModal(false);
  };

  return (
    <>
      <Button
        action={() => {
          setEditCrypto(null);
          setData({
            currency: 'USDT TRC-20',
            details: '',
            type: 'crypto',
            is_active: arrLength === 0 ? true : false,
          });
          setShowCreateAppModal(true);
        }}
      >
        Добавить
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          setEditCrypto(null);
          setData({currency: 'USDT TRC-20', details: '', type: 'crypto', is_active: false});
          setShowCreateAppModal(false);
        }}
        title={'Добавление крипто кошелька'}
      >
        <div data-kt-stepper-element='content'>
          <div className='w-100'>
            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Адрес кошелька</span>
              </label>

              <input
                type='text'
                className={classNames(
                  'form-control form-control-solid',
                  error && !data?.details && 'border border-danger'
                )}
                placeholder=''
                name='details'
                disabled={!!editCrypto ? true : false}
                value={data?.details || ''}
                onChange={changeData}
              />
              {error && !data?.details && (
                <label className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Заполните поле
                </label>
              )}
            </div>

            <div className='row mb-10'>
              <div className='col-md-6 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Криптовалюта</span>
                  <span className='ms-1' data-bs-toggle='tooltip' title='Укажите валюту'>
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </label>

                <div className='position-relative'>
                  <select
                    name='currency'
                    className={classNames('form-select form-select-solid')}
                    disabled={!!editCrypto ? true : false}
                    value={data?.currency}
                    onChange={changeData}
                  >
                    <option value='UAH'>USDT TRC-20</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack mb-7'>
              <div className='me-5'>
                <label className='fs-6 fw-semibold form-label'>
                  Сделайте этот кошелек активным
                </label>
                <div className='fs-7 fw-semibold text-muted'>
                  Этот кошелек будет использоватся по умолчанию
                </div>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='is_active'
                  disabled={arrLength === 0 || editCrypto?.is_active ? true : false}
                  checked={!!data?.is_active}
                  onChange={changeData}
                />
                <span className='form-check-label fw-semibold text-muted'></span>
              </label>
            </div>
            <div className='card-footer d-flex justify-content-end'>
              <Button action={save}>Сохранить</Button>
            </div>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
};

export default observer(AddCrypto);
