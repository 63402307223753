/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import SalesTeams from '../../../dashboards/salesDepartment/SalesRdTeam';
import PageWrapper from '../../../../modules/PageWrapper';
import ButtonExel from '../../../../modules/ButtonExel';
import {formatNumber2} from '../../../../functions/formatNumber';

const TeamsWrapper = () => {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [time, setTime] = useState({});

  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Команды RD', path: '/sales/rd/teams', isActive: true},
      ]}
      buttonKPI={
        roles.includes(4) ||
        roles.includes(8) ||
        roles.includes(30) ||
        roles.includes(13) ||
        roles.includes(32) ? (
          <ButtonExel
            data={data}
            fieldsToKeep={[
              'id',
              'team_created_at',
              'teamleader_id',
              'teamleader_first_name',
              'teamleader_last_name',
              'count_qualifications',
              'count_leads',
              'count_deposits',
              'count_users',
              'amounts_deposits',
              'count_activations',
              'activity',
              'rd',
            ]}
            columnFormats={{amounts_deposits: '$#,##0.00'}}
            fileName={'rd_teams'}
            time={time}
          />
        ) : (
          <></>
        )
      }
      title={'Команды RD'}
    >
      <Teams setObjForExel={setData} setRoles={setRoles} setTimeForExel={setTime} />
    </PageWrapper>
  );
};

const Teams = observer(
  ({timeConfig, isVerify, setPreloader, setObjForExel, setTimeForExel, setRoles}) => {
    const store = useStore();

    const [rdList, setRdList] = useState([]);
    const [rdTeams, setRdTeams] = useState({});

    useEffect(() => {
      setObjForExel(null);
      setRoles(store.roles);
      isVerify && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const tc = timeConfig;
      const rdList = await store.financeCategoryGetRdList();

      setRdList(rdList);

      getTeams(tc);
      setPreloader(false);
    };

    const getTeams = async () => {
      const tc = timeConfig;
      const getRdTeams = await store.salesTeamsRdGetTeams(tc);

      const dataForExel = getRdTeams?.data?.teams?.map((item) => {
        return {
          ...item,
          ...item?.statistics,
          team_created_at: moment(item?.created_at * 1000).format('DD.MM.YYYY HH:mm '),
          teamleader_id: item?.teamleader?.id,
          teamleader_first_name: item?.teamleader?.first_name,
          teamleader_last_name: item?.teamleader?.last_name,
          activity: formatNumber2(item.statistics.activity),
          amounts_deposits: Number(item.statistics.amounts_deposits),
        };
      });

      setTimeForExel(getRdTeams.time);
      setObjForExel(dataForExel);

      setRdTeams(getRdTeams.data);
    };

    return (
      <>
        <SalesTeams
          title={'Команды RD'}
          subTitle={`Кол-во менеджеров: `}
          headers={['Команда', 'Сотрудники', 'УД', 'RD', 'Активность']}
          arrayList={rdList}
          teams={rdTeams}
          getTeams={getTeams}
        />
      </>
    );
  }
);

export default TeamsWrapper;
