/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {CreateAppModal} from '../../../../_metronic/partials';
import {toast} from 'react-toastify';
import Warning from '../../../modules/Warning';
import {Selector} from '../../../shared/Selectors';
import Preloader from '../../../modules/Preloader';

const AddBot = ({setWarning, warning, setSave, save, show, getBots, updateData, isUpdate}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm();

  const [offersOptions, setOffersOptions] = useState([]);
  const [domainsOptions, setDomainsOptions] = useState([]);
  const [domainsServiceOptions, setDomainsServiceOptions] = useState([]);
  const [channelsMainOptions, setChannelsMainOptions] = useState([]);
  const [channelsVipOptions, setChannelsVipOptions] = useState([]);

  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    if (isUpdate) {
      getBot(updateData.id);
    }
    getSelectsOptions();
  }, []);

  const getBot = async (id) => {
    const bot = await store.botsGetBot(id);

    setValue('id', id);
    setValue('path', bot.path);
    setValue('name', bot.name);
    setValue('username', bot.username);
    setValue('support', bot.support);
    setValue('bot_token', bot.token);
    setValue('offer_id', bot.offer_id);
    setValue('domain_id', bot.domain_id);
    setValue('domain_service_id', bot.domain_service_id);
    setValue('channel_main_id', bot.channel_main_id);
    setValue('channel_vip_id', bot.channel_vip_id);
  };

  const getSelectsOptions = async () => {
    const getOffers = await store.offersGetOffersList();
    const getDomains = await store.resourceGetDomainsList();
    const getChannelsMain = await store.channelsGetChannelsList('main');
    const getChannelsVip = await store.channelsGetChannelsList('vip');

    const offers = getOffers.map((item) => {
      return {value: item.id, label: item.name};
    });

    const domains = getDomains.map((item) => {
      return {value: item.id, label: item.domain};
    });

    const domainsService = getDomains.map((item) => {
      return {value: item.id, label: item.domain};
    });

    const channelsMain = getChannelsMain.map((item) => {
      return {value: item.id, label: item.name};
    });

    const channelsVip = getChannelsVip.map((item) => {
      return {value: item.id, label: item.name};
    });

    setOffersOptions(offers);
    setDomainsOptions(domains);
    setDomainsServiceOptions(domainsService);
    setChannelsMainOptions(channelsMain);
    setChannelsVipOptions(channelsVip);
    setPreloader(false);
  };

  const onSubmit = async (data) => {
    if (!isUpdate) {
      const result = await store.botsAddBot(data);

      notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');
    } else {
      const result = await store.botsUpdateBot(data);
      notify(!!result, !!result ? 'Успешно обновлено!' : 'Произошла ошибка!');
    }

    setSave(true);
    show(false);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      getBots();
      toast.success(`${message}`, {});
    }
  };

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Название</label>
        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('name', {required: true})}
        />

        {errors?.name?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Никнейм</label>
        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('username', {required: true})}
        />

        {errors?.username?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Путь</label>
        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('path', {required: true})}
        />

        {errors?.path?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Поддержка</label>
        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('support', {required: true})}
        />

        {errors?.support?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Токен бота</label>
        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('bot_token', {required: true})}
        />

        {errors?.bot_token?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Оффер</label>

        <Controller
          name='offer_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={offersOptions} value={value} onChange={onChange} />
          )}
        />

        {errors?.offer_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Домен</label>

        <Controller
          name='domain_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={domainsOptions} value={value} onChange={onChange} />
          )}
        />

        {errors?.domain_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Сервисный домен</label>

        <Controller
          name='domain_service_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={domainsServiceOptions} value={value} onChange={onChange} />
          )}
        />

        {errors?.domain_service_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Основной канал</label>

        <Controller
          name='channel_main_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={channelsMainOptions} value={value} onChange={onChange} />
          )}
        />

        {errors?.channel_main_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>VIP канал</label>

        <Controller
          name='channel_vip_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={channelsVipOptions} value={value} onChange={onChange} />
          )}
        />

        {errors?.channel_vip_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>

      <div className='d-flex flex-end pt-10'>
        <input
          type='submit'
          className={`btn fw-bold  btn-primary cursor-pointer `}
          value={'Сохранить'}
        />
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
      {preloader && <Preloader />}
    </form>
  );
};

export default observer(AddBot);
