import React from 'react';
import BirthdayLogo from '../../../assets/media/birthday.svg';
import BirthdayIcon from '../../../assets/media/birthdayIcon.png';
import {Button} from '../../../../app/shared/Buttons';
import css from '../../../../styles/BirthdayModal.module.scss';
import classNames from 'classnames';

const BirthdayModal = ({data, setShowCreateAppModal}) => {
  const getAge = () => {
    const currentDate = new Date();
    const birth = new Date(data.date_birth);
    let age = currentDate.getFullYear() - birth.getFullYear();
    const currentMonth = currentDate.getMonth();
    const birthMonth = birth.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <div
      className={classNames(
        'd-flex justify-content-center flex-column align-items-center y-lg-10 px-lg-10 mx-auto',
        css.container
      )}
    >
      <div className={css.logo}>
        <img className='img-fluid' src={BirthdayLogo} alt='' />
      </div>
      <div className={classNames('fw-bold', css.greeting)}>
        <span>
          <img className={classNames('img-fluid', css.img)} src={BirthdayIcon} alt='' />
        </span>
        Happy birthday!
        <span>
          <img className={classNames('img-fluid', css.img)} src={BirthdayIcon} alt='' />
        </span>
      </div>
      <div className={css.hello}>
        Привет, <span className='fw-bold'>{`${data.first_name} ${data.last_name}`}!</span>
      </div>
      <div className={css.text}>
        Сегодня тебе исполнилось <span className='fw-bold'>{getAge()}</span> и мы тебя с этим
        поздравляем! Круто, что ты с нами! Твой вклад в нашу арбитражную команду невероятен, и мы
        хотим от души поздравить тебя с твоим днем. Твоя работа, твое старание и профессионализм
        реально вдохновляют. Спасибо тебе за то, что ты делаешь нас лучше каждый день. Давай
        продолжать в том же духе и добиваться новых вершин вместе!
      </div>
      <div className={css.wish}>
        С уважением и лучшими пожеланиями, <span className='fw-bold'>TraffHunt!</span>
      </div>
      <Button
        action={() => {
          setShowCreateAppModal(false);
        }}
        className={css.closeButton}
      >
        ЗАКРЫТЬ
      </Button>
    </div>
  );
};

export default BirthdayModal;
