import React, {useEffect, useState} from 'react';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import iconPlug from '../../../../../../_metronic/assets/media/user.png';
import {Controller, useForm} from 'react-hook-form';
import {Selector} from '../../../../../shared/Selectors';
import Warning from '../../../../../modules/Warning';
import {useStore} from '../../../../../../store/store';
import {formateImage} from '../../../../../functions/formatImage';
import {toast} from 'react-toastify';

const ChangeStatus = ({id, getData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const store = useStore();
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm();

  const [optionsStatuses, setOptionsStatuses] = useState([]);

  useEffect(() => {
    (async () => {
      const getStatuses = await store.trafficsInfluenceLinksGetStatuses();
      const newStatuses = getStatuses.map((el) => {
        return {value: el.id, label: `${el.name}`, is_source_link: el.is_source_link};
      });
      setOptionsStatuses(newStatuses);

    })();
  }, []);

  const onSubmit = async (data) => {

    const result = await store.trafficsLinksUpdateStatus({...data, id});

    !!result &&
    toast.success(`Успешно сохранено!`, {
      onOpen: async () => {
        setShowCreateAppModal(false)
         await getData();
      },
    });
    !result &&
    toast.error(`Произошла ошибка сохранения!`, {
      theme: 'colored',
    });
  };

  const selectedStatus = watch('status');

  const currentStatus = optionsStatuses.find(status => status.value === selectedStatus);

  return (
    <>
      <Button
        className='me-3'
        // color='btn'
        action={() => setShowCreateAppModal(true)}
      >
        Изменить статус
      </Button>
      <CreateAppModal
        customWidth='mw-450px'
        padding='scroll-y'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title="Изменение статуса"
      >
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='required fw-semibold fs-6 mb-2'>Выберите статус</label>

            <Controller
              name='status'
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value, name, ref}}) => (
                <Selector options={optionsStatuses} value={value} onChange={onChange} />
              )}
            />

            {errors?.status?.type === 'required' && (
              <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
            )}
          </div>
          {currentStatus?.is_source_link && (
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Ссылка на источник</label>
              <Controller
                name='source_link'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value, name, ref}}) => (
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    placeholder=''
                    value={value} onChange={onChange}
                  />
                )}
              />

              {errors?.source_link?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
              )}
            </div>
          )}

          <div className='d-flex flex-end pt-10'>
            <input
              type='submit'
              className={`btn fw-bold  btn-primary cursor-pointer `}
              value={'Сохранить'}
            />
          </div>
        </form>
      </CreateAppModal>
    </>
  );
};

export default ChangeStatus;
