/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useState, useEffect, forwardRef, useRef} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import BloggerCard from './BloggerCard';
import {CreateAppModal, useThemeMode} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import AddBlogger from './AddBlogger';
import classNames from 'classnames';
import css from '../../../../../../styles/CustomPicker.module.scss';
import * as PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import PaginationBe from '../../../../../shared/PaginationBe';
import {useSearchParams} from 'react-router-dom';
import Preloader from '../../../../../modules/Preloader';
import moment from 'moment/moment';

DatePicker.propTypes = {
  calendarClassName: PropTypes.string,
  dateFormat: PropTypes.string,
  selected: PropTypes.number,
  onChange: PropTypes.func,
  customInput: PropTypes.element,
};
const BloggerList = observer(({
  timeConfig,
  type,
  title,
  total,
  updateList,
  selectedBayer,
  setSelectedBayer,
  setQueryText,
  queryText,
  romiList,
  setRomiList,
  setTotalCount,
  totalCount,
  bloggersList,
  setBloggersList,
  localPreloader,
  setLocalPreloader
}) => {
  const store = useStore();
  const [mode, setMode] = useState('');
  const [limit, setLimit] = useState(6);
  const theme = useThemeMode();
  const [page, setPage] = useState(1);
  const [prePage, setPrePage] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [q, setQ] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [bayers, setBayers] = useState([]);
  const searchDebounceRef = useRef(null);
  const [prevTimeConfig, setPrevTimeConfig] = useState(false);

  useEffect(() => {
    const bayerParam = searchParams.get('bayer');
    const queryParam = searchParams.get('q');
    const pageParam = searchParams.get('page');

    if (bayerParam) {
      setSelectedBayer(Number(bayerParam));
    }
    if (queryParam) {
      setQueryText(queryParam);
      setQ(queryParam);
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }
  }, []);

  useEffect(() => {
      if(timeConfig.time_config.length > 0){
        if (prevTimeConfig && JSON.stringify(prevTimeConfig) !== JSON.stringify(timeConfig)) {
          setPrevTimeConfig(timeConfig);
          const currentParams = Object.fromEntries(searchParams.entries());
          const { page, ...rest } = currentParams;
          setSearchParams({...rest, page: '1'});
          setPage(1);
          updateList(1, selectedBayer, q, timeConfig);
        } else {
          updateList(page, selectedBayer, q, timeConfig);
        }
      }
  }, [page, timeConfig])

  const getBayersLit = async () => {
    const getBayers = await store.trafficsInfluenceGetBayersList();
    setBayers(getBayers);
  }

  useEffect(() => {
    getBayersLit()
  }, []);

  const sortByBayer = (id) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    setSelectedBayer(id)
    if (id) {
      setSearchParams({...currentParams, bayer: id, page: '1'})
    } else {
      const { bayer, page, ...rest } = currentParams;
      setSearchParams({...rest, page: '1'});
    }
    setPage(1)
   updateList(1, id, q, timeConfig);
  };

  const onSearch = (e) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    const query = e.target.value;

    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }

    setQueryText(query);

    searchDebounceRef.current = setTimeout(() => {
      if (query) {
        setQ(query);
        setSearchParams({ ...currentParams, q: query, page: '1' });
      } else {
        const { q, page, ...rest } = currentParams;
        setSearchParams({...rest, page: '1'});
        setQ(undefined);
        setQueryText(undefined);
      }

      setPage(1);
      updateList(1, selectedBayer, query, timeConfig);
    }, 1000);
  };

  const getSelectedBayerName = () => {
    if(bayers.length > 0) {
      const selected =  bayers.find((bayer) => bayer.id === Number(selectedBayer));
      return `${selected.first_name} ${selected.last_name}`
    }
  }

  const handlePageChange = (currentPage) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    setPage(currentPage);
    setSearchParams({...currentParams, page: currentPage});
  };

  return (
    <>
      <div className='d-flex flex-wrap flex-stack my-5'>
        <h2 className='fs-2 fw-semibold my-2'>
          {title}
          <span className='fs-6 text-gray-400 ms-1'></span>
        </h2>

        <div className='d-flex flex-wrap my-1'>
          <div className='d-flex align-items-center position-relative'>
            <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-13'
              placeholder='Поиск'
              value={queryText}
              onChange={onSearch}
            />
          </div>
          {title !== 'Компании' && !store.roles.includes(19) && (
            <div className='mx-5 d-flex'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {selectedBayer ? getSelectedBayerName(): 'Байер'} <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
                data-kt-menu='true'
              >
                {bayers.map((bayer) => {
                  return (
                    <div className='menu-item px-3' key={bayer.id}>
                      <span
                        className='menu-link px-3'
                        onClick={() => {
                          sortByBayer(bayer.id);
                        }}
                      >
                        {`${bayer.first_name} ${bayer.last_name}`}
                      </span>
                    </div>
                  );
                })}
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      sortByBayer(undefined);
                    }}
                  >
                    Все
                  </span>
                </div>
              </div>
            </div>
          )}
          {store.roles.includes(6) ||
          store.roles.includes(19) ||
          store.roles.includes(13) ||
          store.roles.includes(32) ? (
            <></>
          ) : (
            <ButtonAdd getBloggersList={()=>{
              updateList(1, selectedBayer, q, timeConfig)
              setPage(1)
              const currentParams = Object.fromEntries(searchParams.entries());
              const { page, ...rest } = currentParams;
              setSearchParams({...rest, page: '1'});
            }} type={type} />
          )}
        </div>
      </div>
      <div className='row g-6 g-xl-9'>
        {bloggersList.map((el, i) => {
          return <BloggerCard data={el} romiList={romiList} key={el.id} />;
        })}
        {localPreloader && <Preloader style={{border: '0', minHeight: '70vh', marginTop: '8%'}} />}
        {!localPreloader &&  <PaginationBe
          arrLength={totalCount}
          limit={6}
          page={page}
          setPage={setPage}
          action={(currentPage) => {handlePageChange(currentPage)}}
          isCentered
        />}
      </div>
    </>
  );
});

export const ButtonAdd = ({getBloggersList, type}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={type === 'facebook' ? 'Добавление компании' : 'Добавление блогера'}
      >
        <AddBlogger
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getBloggersList={getBloggersList}
          isUpdate={false}
          type={type}
        />
      </CreateAppModal>
    </>
  );
};

export default BloggerList;
