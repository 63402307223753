import {api} from './api';

const botsAPI = {
  async botsGetBots(token, type = 'all') {
    try {
      const response = await api.get(`Bots/getBots?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async botsGetListBots(token) {
    try {
      const response = await api.get(`Bots/getListBots?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async botsGetBot(token, id) {
    try {
      const response = await api.get(`Bots/getBot?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async botsAddBot(data) {
    try {
      const response = await api.post(`Bots/addBot`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async botsUpdateBot(data) {
    try {
      const response = await api.post(`Bots/updateBot`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async botsGetBotInfo(token, id) {
    try {
      const response = await api.get(`Bots/getBotInfo?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default botsAPI;
