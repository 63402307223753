/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState, useCallback} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import Header from './Header';
import List from './List';
import PageWrapper from '../../../../../modules/PageWrapper';
import {findKPILtc} from '../../../../../functions/findKPI';
import ButtonExel from '../../../../../modules/ButtonExel';

const TOPLTCTeamWrapper = () => {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [time, setTime] = useState({});

  return (
    <PageWrapper
      firstTimeConfig='week_sales_periods'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'TOP LTC команд', path: '/sales/ltc/teams', isActive: true},
      ]}
      buttonKPI={
        roles.includes(4) ||
        roles.includes(8) ||
        roles.includes(30) ||
        roles.includes(13) ||
        roles.includes(32) ? (
          <ButtonExel
            data={data}
            fieldsToKeep={[
              'id',
              'team_created_at',
              'place',
              'teamleader_id',
              'teamleader_first_name',
              'teamleader_last_name',
              'average_appeals',
              'count_appeals',
              'count_qualifications',
              'count_users',
              'ltc',
              'payout'
            ]}
            fileName={'rating_lts_teams'}
            time={time}
          />
        ) : (
          <></>
        )
      }
      title={'TOP LTC Команд'}
    >
      <TOPLTCTeam setObjForExel={setData} setRoles={setRoles} setTimeForExel={setTime} />
    </PageWrapper>
  );
};

const TOPLTCTeam = observer(
  ({isVerify, timeConfig, setPreloader, setObjForExel, setTimeForExel, setRoles}) => {
    const store = useStore();
    const [data, setData] = useState([]);

    useEffect(() => {
      setObjForExel(null);
      setRoles(store.roles);
      isVerify && getData();
    }, [isVerify, timeConfig]);

    const getData = async () => {
      const [result, LTCList, getLtcTeams] = await Promise.all([
        store.salesTeamsLtcGetTop(
          (timeConfig =
            timeConfig.time_config === 'week_sales_periods'
              ? {
                  time_config: '',
                  time_start: '',
                  time_end: '',
                }
              : timeConfig)
        ),
        store.financeCategoryGetLtcList(),
        store.salesTeamsLtcGetTeams({
          time_config: 'week_salles',
          time_start: '',
          time_end: '',
        }),
      ]);

      const newList = result.data
        .map((item) => {
          const findAvatar = getLtcTeams.data.teams.find((el) => el.id === item.id);
          if (!findAvatar) {
            return {};
          }
          let status;
          LTCList.forEach((indicator) => {
            if (item.ltc >= +indicator.ltc_min && item.ltc < +indicator.ltc_max) {
              status = indicator.indicator;
            }
          });
          return {
            ...item,
            avatar: findAvatar.teamleader.avatar,
            teamleader: findAvatar.teamleader,
            full_name: `${item.first_name} ${item.last_name}`,
            status,
            place: item?.place === 0 ? 99999 : item?.place,
            count_users: findAvatar.statistics.count_users,
          };
        })
        .sort((a, b) => a?.place - b?.place);

      const objForExel = newList
        .filter((item) => {
          return Object.keys(item).length > 0;
        })
        .map((item) => {
          return {
            ...item,
            id: item.id,
            team_created_at: moment(item?.created_at * 1000).format('DD.MM.YYYY HH:mm '),
            teamleader_id: item?.teamleader?.id,
            teamleader_first_name: item?.teamleader?.first_name,
            teamleader_last_name: item?.teamleader.last_name,
            count_appeals: item?.count_appeals,
            count_qualifications: item?.count_qualifications,
            count_users: item?.count_users,
            ltc: item?.ltc,
            payout: item?.pay
          };
        });

      setTimeForExel(result.time);
      setObjForExel(objForExel);

      setData(
        newList.filter((item) => {
          return Object.keys(item).length > 0;
        })
      );
      setPreloader(false);
    };

    return (
      <>
        <Header top_1={data[0]} top_2={data[1]} top_3={data[2]} />
        <List data={data} />
      </>
    );
  }
);

export default TOPLTCTeamWrapper;
