import React from 'react';
import clsx from 'clsx';
import {KTIcon} from '../../../../helpers';
import {useLayout} from '../../../core';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';

const ToolbarUserFilter = observer(() => {
  const store = useStore();
  const {config} = useLayout();

  const changeStatus = (event) => {
    const value = event.target.value;
    store.setConfigToolbarUserFilter({status: value});
  };
  const changeWorking = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;
    if (value === 'working') {
      return store.setConfigToolbarUserFilter({working: checked});
    }
    return store.setConfigToolbarUserFilter({notWorking: checked});
  };

  // const cleaningConfig = () => {
  //   store.setConfigToolbarUserFilter({}, true);
  // };

  const selectOptions = [
    {label: 'status1', value: 1},
    {label: 'status2', value: 2},
    {label: 'status3', value: 3},
    {label: 'status4', value: 4},
  ];

  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? 'btn-light'
    : 'bg-body btn-color-gray-700 btn-active-color-primary';

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div className='m-0'>
        <span
          href='#'
          className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          <KTIcon iconName='filter' className='fs-6 text-muted me-1' />
          Filter
        </span>

        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-7 py-5'>
            <div className='mb-10'>
              <label className='form-label fw-bold'>По статусу:</label>

              <div>
                <select
                  className='form-select form-select-solid'
                  data-placeholder='Select option'
                  onChange={changeStatus}
                >
                  {selectOptions.map((el, i) => {
                    return (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className='mb-10'>
              {/* <label className='form-label fw-bold'>Member Type:</label> */}

              <div className='d-flex'>
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='working'
                    onChange={changeWorking}
                    checked={store.configToolbarUserFilter.working}
                  />
                  <span className='form-check-label'>Работает</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='notWorking'
                    onChange={changeWorking}
                    checked={store.configToolbarUserFilter.notWorking}
                  />
                  <span className='form-check-label' onChange={changeWorking}>
                    Не работает
                  </span>
                </label>
              </div>
            </div>

            {/* <div className='d-flex justify-content-start'>
              <button className='btn btn-sm btn-primary'>
                Сбросить
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export {ToolbarUserFilter};
