/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {PageTitle} from '../../../_metronic/layout/core'
import ModeToolbar from '../../modules/ModeToolbar'
import Costs from './costs/Costs'

export const Finances = () => {
  const location = useLocation()

  return <>{location.pathname === '/finances/costs' && <Costs />}</>
}

const FinancesWrapper = () => {
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''})
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
      case '/finances/costs': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/finances/costs'},
            {isSeparator: true},
            {title: 'Финансы', path: '/finances/costs', isActive: true},
            {isSeparator: true},
            {title: 'Расходы', path: '/finances/costs', isActive: true},
          ],
          title: 'Финансы',
        })
        break
      }
    }
  }, [])

  return (
    <>
      <ModeToolbar />
      <PageTitle breadcrumbs={breadcrumbs.br}>{breadcrumbs.title}</PageTitle>
      <Finances />
    </>
  )
}

export default FinancesWrapper
