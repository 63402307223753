import React from 'react';

const Button = ({
  children,
  action,
  className = '',
  color = 'btn-primary',
  style,
  disabled = false,
  ktMenuTrigger = '',
  ktMenuPlacement = '',
}) => {
  return (
    <button
      type='button'
      disabled={disabled}
      className={`btn fw-bold  ${color} cursor-pointer ${className}`}
      style={style}
      onClick={action}
      data-kt-menu-trigger={ktMenuTrigger}
      data-kt-menu-placement={ktMenuPlacement}
    >
      {children}
    </button>
  );
};

const ButtonUpdate = ({actions}) => {
  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => actions()}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </>
  );
};

const ButtonView = () => {
  return (
    <>
      <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
        <i className='ki-duotone ki-switch fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </>
  );
};

export {Button, ButtonUpdate, ButtonView};
