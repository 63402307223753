/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import InputSearch from '../../../shared/InputSearch';
import Pagination from '../../../shared/Pagination';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import AddAdvertiser from '../../advertisers/advertisers/AddAdvertiser';
import {CreateAppModal} from '../../../../_metronic/partials';
import AddAChannel from './AddChannel';

const ChannelsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Каналы', path: '/resources/channels', isActive: true},
      ]}
      title={'Каналы'}
    >
      <Channels />
    </PageWrapper>
  );
};

const Channels = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [channels, setChannels] = useState([]);
  const [searchChannels, setSearchChannels] = useState([]);
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);
  const [bots, setBots] = useState();

  useEffect(() => {
    isVerify && getChannels();
  }, [isVerify]);

  const getChannels = async () => {
    const result = await store.channelsGetChannels();
    const botResult = await store.botsGetListBots();
    const newBots = botResult.map((el) => {
      return {label: el.name, value: el.id};
    });
    setBots(newBots);
    setSearchChannels([...result]);
    setChannels([...result]);
    setPreloader(false);
  };

  const sortStatus = (status) => {
    const newChannels = channels.filter((item) => item.config === status);

    setSearchChannels(newChannels);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
            <InputSearch
              title={'Поиск...'}
              array={channels}
              newArray={setSearchChannels}
              searchParameters={['telegram_id', 'id', 'name']}
            />
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Конфигурация <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      sortStatus('vip');
                    }}
                  >
                    Vip
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      sortStatus('main');
                    }}
                  >
                    Main
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSearchChannels(channels);
                    }}
                  >
                    Все конфиги
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <Button
                action={() => {
                  setShowCreateAppModal(true);
                  setSave(false);
                }}
              >
                <>
                  <i className='ki-duotone ki-plus fs-2'></i>Добавить
                </>
              </Button>
              <CreateAppModal
                customWidth='mw-550px'
                show={showCreateAppModal}
                handleClose={() => {
                  if (save) {
                    setShowCreateAppModal(false);
                  } else {
                    setWarning(true);
                  }
                }}
                title={'Добавление канала'}
              >
                <AddAChannel
                  bots={bots}
                  show={setShowCreateAppModal}
                  getChannels={getChannels}
                  warning={warning}
                  setWarning={setWarning}
                />
              </CreateAppModal>
            </div>
          </div>
        </div>
        <Table
          channels={channels}
          getChannels={getChannels}
          searchChannels={searchChannels}
          bots={bots}
        />
      </Card>
    </>
  );
});

const Table = ({channels, getChannels, searchChannels, bots}) => {
  const [portionChannels, setPortionChannels] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [offset, setOffset] = useState(1);

  // useEffect(() => {
  //   const newChannels = [];
  //   for (let i = limit * offset - limit; i < limit * offset; i++) {
  //     if (channels.length > i) {
  //       newChannels.push(channels[i]);
  //     }
  //   }
  //   setPortionChannels(newChannels);
  // }, [offset, channels]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>канал</th>
                <th className='min-w-125px'>telegram id</th>
                <th className='min-w-125px'>конфигурация</th>
                <th className='min-w-125px'>создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchChannels.map((el, i) => {
                return <Channel key={el.id} data={el} getChannels={getChannels} bots={bots} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {channels.length > limit && (
        <Pagination
          arrLength={channels.length}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )} */}
    </div>
  );
};

const Channel = ({data, getChannels, bots}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={data.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug}
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.name}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td>
        <div className=''>{data.telegram_id}</div>
      </td>
      <td>
        <div className='badge badge-light fw-bold'>{data.config}</div>
      </td>
      <td>{moment(data.created_at * 1000).format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <i className='ki-duotone ki-switch fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>

          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              setShowCreateAppModal(true);
              setSave(false);
            }}
          >
            <i className='ki-duotone ki-pencil fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>
          <CreateAppModal
            customWidth='mw-550px'
            show={showCreateAppModal}
            handleClose={() => {
              if (save) {
                setShowCreateAppModal(false);
              } else {
                setWarning(true);
              }
            }}
            title={'Редактирование канала'}
          >
            <AddAChannel
              bots={bots}
              show={setShowCreateAppModal}
              getChannels={getChannels}
              warning={warning}
              setWarning={setWarning}
              isUpdate
              id={data.id}
            />
          </CreateAppModal>
        </div>
      </td>
    </tr>
  );
};

export default ChannelsWrapper;
