import {Button} from './Buttons'

const Filter = ({children}) => {
  return (
    <>
      <Button
        className='me-3'
        color='btn-light-primary'
        ktMenuTrigger='click'
        ktMenuPlacement='bottom-end'
      >
        <>
          <i className='ki-duotone ki-filter fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Filter
        </>
      </Button>

      {children}
    </>
  )
}

export default Filter
