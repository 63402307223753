/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../store/store';
import numeral from 'numeral';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {KTIcon} from '../../../_metronic/helpers';
import {Link} from 'react-router-dom';
import {Button} from '../../shared/Buttons';
import Card from '../../shared/Card';
import PageWrapper from '../../modules/PageWrapper';
import {formatNumber} from '../../functions/formatNumber';

const WalletWrapper = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  useEffect(() => {
    switch (location.pathname) {
      case '/wallet': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/wallet'},
            {isSeparator: true},
            {title: 'Кошелек', path: '/wallet', isActive: true},
          ],
          title: 'Кошелек',
        });
        break;
      }
    }
  }, []);
  return (
    <PageWrapper
      breadcrumbs={breadcrumbs.br}
      title={breadcrumbs.title}
      button={
        <Link to='/account/billing'>
          <Button>Платежные методы</Button>
        </Link>
      }
    >
      <Wallet />
    </PageWrapper>
  );
};

const Wallet = observer(({isVerify, setPreloader}) => {
  const [data, setData] = useState({});

  const store = useStore();

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const transactions = await store.getTransactions();
      const wallet = await store.getWallet();

      setData({...data, transactions, wallet});
      return setPreloader(false);
    })();
  }, [isVerify]);

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10'>
          <Finances data={data?.wallet} />
        </div>
        <div className='col-xl-8 mb-5 mb-xl-10'>
          <Table data={data?.transactions} />
        </div>
      </div>
    </>
  );
});

const Finances = ({data, color = 'primary'}) => {
  return (
    <div>
      <div className='p-0'>
        <div
          className={`px-9 py-7 card-rounded h-275px w-100 bg-${color} d-flex flex-column justify-content-between`}
        >
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>Платежная информация</h3>
            <div className='ms-1'>
              <button
                type='button'
                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
              {/* <Dropdown1 /> */}
            </div>
          </div>

          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>Текущий баланс</span>
            <span className='fw-bold fs-2x pt-1'>
              {`$${numeral(data?.balance).format('0,0.00')}` || `$${0}`}
            </span>
          </div>
          <div
            className='px-4 py-4 shadow-xs card-rounded position-relative z-index-1 bg-body d-flex align-items-center'
            // style={{marginTop: '-100px'}}
          >
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <i className='ki-duotone ki-compass fs-2x'>
                    <i className='path1'></i>
                    <i className='path2'></i>
                  </i>
                </span>
              </div>

              <div className='d-flex align-items-center justify-content-between flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>Выплачено</span>
                  <div className='text-gray-400 fw-semibold fs-7'>Сума выплат</div>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>
                    {`$${numeral(data?.amount_payments).format('0.0a')}` || `$${0}`}
                  </div>
                  {/* {indicator === 'up' && ( */}
                  {/* <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' /> */}
                  {/* // )} */}
                  {/* {indicator === 'down' && (
                  <KTIcon iconName='arrow-down' className='fs-5 text-danger ms-1' />
                )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({data}) => {
  return (
    <Card className='card pt-4 mb-6 mb-xl-9'>
      <div className='card-header border-0'>
        <div className='card-title'>
          <h2>История</h2>
        </div>
      </div>

      <div className='card-body pt-0 pb-5'>
        <div className='table-responsive'>
          {!!data?.length && (
            <table
              className='table align-middle table-row-dashed gy-5'
              id='kt_table_users_login_session'
            >
              <thead className='border-bottom border-gray-200 fs-7 fw-bold'>
                <tr className='text-start text-muted text-uppercase gs-0'>
                  <th className='min-w-150px'>Дата</th>
                  <th className='min-w-150px'>Order ID</th>
                  <th className='min-w-150px'>Сумма</th>
                  <th className='d-flex justify-content-end'>Подробности</th>
                </tr>
              </thead>
              <tbody className='fs-6 fw-semibold text-gray-600'>
                {!!data?.length &&
                  data.map((el) => {
                    return (
                      <tr>
                        <td>{moment(el.created_at).format('HH:mm DD/MM/YYYY')}</td>
                        <td>{el.id}</td>
                        <td>
                          {el.type === 'deposit' && (
                            <span className='text-success'>${el.amount}</span>
                          )}
                          {el.type === 'withdrawal' && (
                            <span className='text-danger'>${el.amount}</span>
                          )}
                        </td>
                        <td className='d-flex justify-content-end'>
                          <Button color='btn-light'>Подробнее</Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          {!data?.length && (
            <span className='fs-6 fw-semibold text-gray-400'>История пока что пуста...</span>
          )}
        </div>
      </div>
    </Card>
  );
};

export default WalletWrapper;
