/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts';
import css from '../../styles/Chart.module.scss';
import {getCSS, getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import moment from 'moment';
import Card from '../shared/Card';

const Chart = ({data}) => {
  const chartRef = useRef(null);
  const {mode} = useThemeMode();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const formateDate = data?.graph
      ?.sort((a, b) => a.day - b.day)
      .map((el) => {
        return {...el, day: moment(el.day * 1000).format('DD.MM.YYYY')};
      });

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, formateDate));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  return (
    <Card className={`card h-100`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>График квалификаций</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          <span className='btn btn-sm btn-light'>Последние 30 дней</span>
          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_week_btn'
          >
            Эта неделя
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Этот месяц
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_year_btn'
          >
            Этот год
          </a> */}
        </div>
      </div>

      <div className='card-body'>
        <div className='px-9 mb-5'>
          <div className='d-flex align-items-center mb-2'>
            {/* <span className='fs-4 fw-semibold text-gray-400 align-self-start me-1'>$</span> */}
            <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>{data?.count}</span>

            {/* <span className='badge badge-success fs-base'>
              <i className='ki-duotone ki-arrow-up fs-5 text-white ms-n1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              4.5%
            </span> */}
          </div>
          <span className='fs-6 fw-semibold text-gray-400'>За последние 30 дней</span>
        </div>
        <div>
          <div
            ref={chartRef}
            id='kt_charts_widget_3_chart'
            style={{height: '350px'}}
            className={css.chart1}
          ></div>
        </div>
      </div>
    </Card>
  );
};

export default Chart;

function getChartOptions(height, graph) {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-success');
  const lightColor = getCSSVariableValue('--bs-success-light');

  function findLargestNumber(array) {
    const newArr = array?.map((el) => {
      return el.count;
    });

    if (!Array.isArray(newArr) || newArr?.length === 0) {
      return undefined;
    }

    const numericArray = newArr.map(Number);
    const largestNumber = Math.max(...numericArray);
    return largestNumber + 10;
  }

  const data = graph?.map((el) => {
    return el?.count;
  });

  return {
    series: [
      {
        name: 'count',
        data: data || [],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 80, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: graph?.map((el) => {
        return el.day;
      }),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 5,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          colors: labelColor,
          fontSize: '13px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '13px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      max: findLargestNumber(graph),
      min: 0,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '13px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return +val;
        },
      },
    },
    colors: [baseColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 3,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColor: baseColor,
      strokeWidth: 3,
    },
  };
}
