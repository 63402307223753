import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PageWrapper from '../../../modules/PageWrapper';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Stepper from '../../../modules/Stepper';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';
import Visa from '../../../../_metronic/assets/media/visa.svg';
import Mastercard from '../../../../_metronic/assets/media/mastercard.svg';
import MaskedTextInput from 'react-text-mask';
import {toast} from 'react-toastify';

const links = [
  {
    link: 'common',
    title: 'Общее',
    component: (data, error, handleChange) => (
      <Common data={data} error={error} handleChange={handleChange} />
    ),
  },
  {
    link: 'config',
    title: 'Данные',
    component: (data, error, handleChange) => (
      <Config data={data} error={error} handleChange={handleChange} />
    ),
  },
];

const mask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const AddOAccountWrapper = ({isUpdate = false}) => {
  const {id} = useParams();
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Счета', path: '/finances/accounts', isActive: true},
        {isSeparator: true},
        {
          title: isUpdate ? `Изменение данных счета #${id}` : 'Добавление счета',
          path: isUpdate ? `/finances/accounts/updateAccount/${id}` : '/finances/accounts/add',
          isActive: true,
        },
      ]}
      title={isUpdate ? `Изменение данных счета #${id}` : 'Добавление счета'}
    >
      <AddAccount isUpdate={isUpdate} />
    </PageWrapper>
  );
};

const AddAccount = ({isVerify, setPreloader, isUpdate}) => {
  const store = useStore();
  const {id} = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState('common');
  const [data, setData] = useState({});
  const [error, setError] = useState([]);

  useEffect(() => {
    if (isVerify) setPreloader(false);
  }, [isVerify]);

  function allowOnlyNumbers(str) {
    const inputValue = str;

    const regex = /^\d+(\.\d{0,2})?$/;

    if (regex.test(inputValue) || inputValue === '') {
      return inputValue;
    } else {
      return inputValue.slice(0, -1);
    }
  }

  function isValidTrxAddress(address) {
    return /^T[a-zA-Z0-9]{33}$/.test(address);
  }

  function formatBankCard(cardNumber) {
    return cardNumber.replace(/\s/g, '');
  }

  function isValidCardNumber(number) {
    return /^[0-9]{16}$/.test(number);
  }

  const handleChange = (e) => {
    let {name, value} = e.target;

    if (name === 'start_balance') {
      value = allowOnlyNumbers(value);
    }

    if (name === 'details' && data.type === 'bank') {
      value = formatBankCard(value);
    }

    const newData = {...data};

    newData[name] = value;
    setData(newData);
  };

  const findError = () => {
    const names = [];

    if (page === 'common') {
      !data.name && names.push('name');

      if (!!names.length) {
        setError(names);
        return false;
      }
    }

    if (page === 'config') {
      !data.details && names.push('details');

      if (data.type === 'crypto' && data.details && !isValidTrxAddress(data.details)) {
        names.push('validAddressError');
      }

      if (data.type === 'bank' && data.details && !isValidCardNumber(data.details)) {
        names.push('validCardNumberError');
      }

      !data.start_balance && names.push('start_balance');

      if (!!names.length) {
        setError(names);
        return false;
      }
    }

    setError([]);
    return true;
  };

  const save = async () => {
    if (!findError()) {
      return;
    }
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(`${key}`, value);
    }

    const result = await store.financeAccountsAdd(formData);

    !!result && toast.success(`Успешно сохранено!`, {}) && navigate('/finances/accounts');
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  const nextPage = () => {
    switch (page) {
      case 'common':
        if (!findError()) {
          break;
        }
        setPage('config');
        break;
      case 'finances':
        break;
    }
  };
  const prevPage = () => {
    setPage('common');
  };
  return (
    <Card className='card'>
      <div className='card-body'>
        <Stepper links={links} page={page} />
        <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
          {links.map((el, i) => {
            return (
              <div className='w-100' key={i}>
                {el.link === page && el.component(data, error, handleChange)}
              </div>
            );
          })}
          <div className='d-flex flex-stack pt-10'>
            <div className='me-2'>
              {page === 'config' && (
                <Button
                  action={() => {
                    prevPage();
                  }}
                  color='btn-light-primary'
                >
                  <i className='ki-duotone ki-arrow-left fs-3 me-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  Назад
                </Button>
              )}
            </div>
            <div>
              {page === 'common' && (
                <Button
                  action={(e) => {
                    nextPage();
                  }}
                >
                  Далее
                  <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </Button>
              )}
              {page === 'config' && !isUpdate && (
                <Button
                  action={(e) => {
                    save();
                  }}
                >
                  Сохранить
                  <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </Button>
              )}
              {page === 'config' && isUpdate && (
                <Button action={(e) => {}}>
                  Сохранить изменения
                  <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const Common = ({error, data, handleChange}) => {
  const [checked, setChecked] = useState('bank');
  useEffect(() => {
    if (!data.type) {
      const event = {target: {name: 'type', value: checked}};
      handleChange(event);
    }
    if (data.type) {
      setChecked(data.type);
    }
  }, []);
  return (
    <>
      <Title title={'Общая информация'} subTitle={'Выберите тип и введите название счета'} />
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Выберите тип счета</span>
        </label>
        <div className='row row-cols-1 row-cols-md-2 g-5'>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='type'
              id='kt_radio_buttons_2_option_1'
              checked={checked === 'bank'}
              value='bank'
              onChange={(event) => {
                setChecked('bank');
                handleChange(event);
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100'
              htmlFor='kt_radio_buttons_2_option_1'
            >
              <i className='ki-duotone ki-dollar fs-3hx text-primary'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
              <span className='d-block fw-semibold text-start'>
                <span className='text-gray-900 fw-bold d-block fs-3'>Cash</span>
                <span className='text-muted fw-semibold fs-6'>
                  Top up wallet using cash from one of your saved payment options.
                </span>
              </span>
            </label>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='type'
              id='kt_radio_buttons_2_option_2'
              checked={checked === 'crypto'}
              value='crypto'
              onChange={(event) => {
                setChecked('crypto');
                handleChange(event);
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100'
              htmlFor='kt_radio_buttons_2_option_2'
            >
              <i className='ki-duotone ki-bitcoin fs-3hx text-primary'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <span className='d-block fw-semibold text-start'>
                <span className='text-gray-900 fw-bold d-block fs-3'>Crypto</span>
                <span className='text-muted fw-semibold fs-6'>
                  Top up wallet using a crypto currency.
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Название счета</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          value={data.name || ''}
          name='name'
          onChange={handleChange}
        />
        {error.includes('name') && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
    </>
  );
};

const Config = ({data, error, handleChange}) => {
  return (
    <>
      <Title
        title={data.type === 'bank' ? 'Банк' : 'Криптовалюта'}
        subTitle={
          data.type === 'bank'
            ? 'Введите номер карты, введите стартовый баланс'
            : 'Введите адрес кошелька, введите стартовый баланс'
        }
      />
      {data.type === 'bank' ? (
        <>
          <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid'>
            <label className='required fs-6 fw-semibold form-label mb-2'>Номер карты</label>
            <div className='position-relative'>
              <MaskedTextInput
                className='form-control form-control-solid mb-3 mb-lg-0 mw-100'
                name={'details'}
                onChange={handleChange}
                value={data.details}
                mask={mask}
              />
              <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                <img src={Visa} alt='' className='h-25px' style={{marginRight: '5px'}} />
                <img src={Mastercard} alt='' className='h-25px' />
              </div>
            </div>
            {error.includes('details') && (
              <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
            )}
            {error.includes('validCardNumberError') && (
              <div className='text-danger mt-2'>Введите валидный номер карты</div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label'>
              <span className='required'>Стартовый баланс $</span>
            </label>
            <input
              className='form-control form-control-lg form-control-solid'
              value={data.start_balance || ''}
              name='start_balance'
              onChange={handleChange}
            />
            {error.includes('start_balance') && (
              <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid'>
            <label className='required fs-6 fw-semibold form-label mb-2'>Адрес кошелька</label>
            <input
              className='form-control form-control-lg form-control-solid'
              value={data.details || ''}
              name='details'
              onChange={handleChange}
            />
            {error.includes('details') && (
              <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
            )}
            {error.includes('validAddressError') && (
              <div className='text-danger mt-2'>Введите валидный адрес кошелька</div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label'>
              <span className='required'>Стартовый баланс $</span>
            </label>
            <input
              className='form-control form-control-lg form-control-solid'
              value={data.start_balance || ''}
              name='start_balance'
              onChange={handleChange}
            />
            {error.includes('start_balance') && (
              <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-10 pb-lg-15'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>
    </div>
  );
};

export default observer(AddOAccountWrapper);
