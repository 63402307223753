import React from 'react'
import { Button } from '../../../shared/Buttons'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Card from '../../../shared/Card'

const CreateSubscription = () => {
  return (
    <>
      <Card
        className='card card-flush pt-3 mb-0'
        data-kt-sticky='true'
        data-kt-sticky-name='subscription-summary'
        data-kt-sticky-offset="{default: false, lg: '200px'}"
        data-kt-sticky-width="{lg: '250px', xl: '300px'}"
        data-kt-sticky-left='auto'
        data-kt-sticky-top='150px'
        data-kt-sticky-animation='false'
        data-kt-sticky-zindex='95'
      >
        <div className='card-header'>
          <div className='card-title'>
            <h2>Summary</h2>
          </div>
        </div>

        <div className='card-body pt-0 fs-6'>
          <div className='mb-7'>
            <h5 className='mb-3'>Customer details</h5>

            <div className='d-flex align-items-center mb-1'>
              <span className='fw-bold text-gray-800 text-hover-primary me-2'>Sean Bean</span>

              <span className='badge badge-light-success'>Active</span>
            </div>

            <span className='fw-semibold text-gray-600 text-hover-primary'>sean@dellito.com</span>
          </div>

          <div className='separator separator-dashed mb-7'></div>

          <div className='mb-7'>
            <h5 className='mb-3'>Product details</h5>

            <div className='mb-0'>
              <span className='badge badge-light-info me-2'>Basic Bundle</span>

              <span className='fw-semibold text-gray-600'>$149.99 / Year</span>
            </div>
          </div>

          <div className='separator separator-dashed mb-7'></div>

          <div className='mb-10'>
            <h5 className='mb-3'>Payment Details</h5>

            <div className='mb-0'>
              <div className='fw-semibold text-gray-600 d-flex align-items-center'>
                Mastercard
                <img
                  src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                  className='w-35px ms-2'
                  alt=''
                />
              </div>

              <div className='fw-semibold text-gray-600'>Expires Dec 2024</div>
            </div>
          </div>

          <div className='mb-0'>
            <Button>Create Subscription</Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default CreateSubscription
