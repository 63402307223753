/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';

const Pagination = ({arrLength, limit, offset, setOffset, children}) => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const newButtons = [];
    for (let i = 0; i < arrLength / limit; i++) {
      newButtons.push(i);
    }
    setButtons(newButtons);
  }, [arrLength]);

  return (
    <>
      {children}
      <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='fs-6 fw-semibold text-gray-700'>{/* Showing 1 to 10 of 50 entries */}</div>

        <ul className='pagination'>
          <li
            className='page-item previous cursor-pointer'
            onClick={() => {
              if (offset > 1) {
                setOffset(offset - 1);
              }
            }}
          >
            <span className='page-link'>
              <i className='previous'></i>
            </span>
          </li>
          {buttons.map((el, i) => {
            return (
              <li
                className={`page-item cursor-pointer ${i + 1 === offset && 'active'}`}
                key={`${i + 1}-pag`}
                onClick={() => setOffset(i + 1)}
              >
                <span className='page-link'>{i + 1}</span>
              </li>
            );
          })}
          <li
            className='page-item next cursor-pointer'
            onClick={() => {
              if (offset * limit < arrLength) {
                setOffset(offset + 1);
              }
            }}
          >
            <span className='page-link'>
              <i className='next'></i>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;
