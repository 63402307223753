import React from 'react'
import imagePlug from '../../../../_metronic/assets/media/user.png'
import ChangeCustomer from './ChangeCustomer'
import AddProduct from './AddProduct'
import PaymentMethod from './PaymentMethod'
import CreateSubscription from './CreateSubscription'
import Card from '../../../shared/Card'

const Create = () => {
  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0'>
          <form className='form' action='#' id='kt_subscriptions_create_new'>
            <Card className='card card-flush pt-3 mb-5 mb-lg-10'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2 className='fw-bold'>Customer</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='text-gray-500 fw-semibold fs-5 mb-5'>
                  Select or add a customer to a subscription:
                </div>

                <div className='d-flex align-items-center p-3 mb-2'>
                  <div className='symbol symbol-60px symbol-circle me-3'>
                    <img alt='#' src={imagePlug} />
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='fs-4 fw-bold text-gray-900 text-hover-primary me-2'>
                      Sean Bean
                    </span>

                    <span className='fw-semibold text-gray-600 text-hover-primary'>
                      sean@dellito.com
                    </span>
                  </div>
                </div>

                <ChangeCustomer />

                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6'>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-semibold'>
                      <h4 className='text-gray-900 fw-bold'>
                        This is a very important privacy notice!
                      </h4>
                      <div className='fs-6 text-gray-700'>
                        Writing headlines for blog posts is much science and probably cool audience.
                        <span className='fw-bold'>Learn more</span>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Card className='card card-flush pt-3 mb-5 mb-lg-10'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2 className='fw-bold'>Products</h2>
                </div>

                <AddProduct />
              </div>

              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 fw-semibold gy-4'
                    id='kt_subscription_products_table'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <th className='min-w-300px'>Product</th>
                        <th className='min-w-100px'>Qty</th>
                        <th className='min-w-150px'>Total</th>
                        <th className='min-w-70px text-end'>Remove</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600'>
                      <tr>
                        <td>Beginner Plan</td>
                        <td>1</td>
                        <td>149.99 / Month</td>
                        <td className='text-end'>
                          <span
                            className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='tooltip'
                            title='Delete'
                            data-kt-action='product_remove'
                          >
                            <i className='ki-duotone ki-trash fs-3'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                              <span className='path4'></span>
                              <span className='path5'></span>
                            </i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>

            <PaymentMethod />

            <Card className='card card-flush pt-3 mb-5 mb-lg-10'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2 className='fw-bold'>Advanced Options</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='d-flex flex-column mb-15 fv-row'>
                  <div className='fs-5 fw-bold form-label mb-3'>
                    Custom fields
                    <span
                      className='ms-2 cursor-pointer'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Add custom fields to the billing invoice.'
                    >
                      <i className='ki-duotone ki-information fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </div>

                  <div className='table-responsive'>
                    <table
                      id='kt_create_new_custom_fields'
                      className='table align-middle table-row-dashed fw-semibold fs-6 gy-5'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                          <th className='pt-0'>Field Name</th>
                          <th className='pt-0'>Field Value</th>
                          <th className='pt-0 text-end'>Remove</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              name='row-name'
                              value=''
                            />
                          </td>
                          <td>
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              name='row-value'
                              value=''
                            />
                          </td>
                          <td className='text-end'>
                            <button
                              type='button'
                              className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3'
                              data-kt-action='field_remove'
                            >
                              <i className='ki-duotone ki-trash fs-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                                <span className='path5'></span>
                              </i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <button
                    type='button'
                    className='btn btn-light-primary me-auto'
                    id='kt_create_new_custom_fields_add'
                  >
                    Add custom field
                  </button>
                </div>

                <div className='d-flex flex-column mb-10 fv-row'>
                  <div className='fs-5 fw-bold form-label mb-3'>
                    Invoice footer
                    <span
                      className='ms-2 cursor-pointer'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover focus'
                      data-bs-html='true'
                      data-bs-content='Add an addition invoice footer note.'
                    >
                      <i className='ki-duotone ki-information fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </div>

                  <textarea
                    className='form-control form-control-solid rounded-3'
                    rows='4'
                  ></textarea>
                </div>

                <div className='d-flex flex-column mb-5 fv-row rounded-3 p-7 border border-dashed border-gray-300'>
                  <div className='fs-5 fw-bold form-label mb-3'>
                    Usage treshold
                    <span
                      className='ms-2 cursor-pointer'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover focus'
                      data-bs-html='true'
                      data-bs-delay-hide='1000'
                      data-bs-content="Thresholds help manage risk by limiting the unpaid usage balance a customer can accrue. Thresholds only measure and bill for metered usage (including discounts but excluding tax). &lt;a href='#'&gt;Learn more&lt;/a&gt;."
                    >
                      <i className='ki-duotone ki-information fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </div>

                  <label className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked='checked'
                      value='1'
                    />
                    <span className='form-check-label text-gray-600'>
                      Bill immediately if usage treshold reaches 80%.
                    </span>
                  </label>
                </div>

                <div className='d-flex flex-column fv-row rounded-3 p-7 border border-dashed border-gray-300'>
                  <div className='fs-5 fw-bold form-label mb-3'>
                    Pro-rate billing
                    <span
                      className='ms-2 cursor-pointer'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover focus'
                      data-bs-html='true'
                      data-bs-delay-hide='1000'
                      data-bs-content="Pro-rated billing dynamically calculates the remainder amount leftover per billing cycle that is owed. &lt;a href='#'&gt;Learn more&lt;/a&gt;."
                    >
                      <i className='ki-duotone ki-information fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </div>

                  <label className='form-check form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                    <span className='form-check-label text-gray-600'>
                      Allow pro-rated billing when treshold usage is paid before end of billing
                      cycle.
                    </span>
                  </label>
                </div>
              </div>
            </Card>
          </form>
        </div>

        <div className='flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2'>
          <CreateSubscription />
        </div>
      </div>
    </>
  )
}

export default Create
