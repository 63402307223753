/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import css from '../../../../../styles/CustomPicker.module.scss';
import styles from '../../../../../styles/Activity.module.scss';
import classNames from 'classnames';
import {useThemeMode} from '../../../../../_metronic/partials';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Preloader from '../../../../modules/Preloader';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import DatePicker from 'react-datepicker';
import { formatNumber } from "../../../../functions/formatNumber";

const momentTZ = require('moment-timezone');

const Activity = ({id, type}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [prel, setPrel] = useState(false);
  const [mode, setMode] = useState('');

  const [activity, setActivity] = useState([]);
  const [activityContainerWidth, setActivityContainerWidth] = useState(0);

  const [date, setDate] = useState('');
  const [startTimeForRequest, setStartTimeForRequest] = useState('');

  useEffect(() => {
    initTime();
  }, []);

  useEffect(() => {
    if (!startTimeForRequest) return;
    const container = document.getElementById('activity-line-0');
    setActivityContainerWidth(container.offsetWidth);
    getActivity(moment(startTimeForRequest).format('YYYY-MM-DD'));
  }, [startTimeForRequest]);

  const getActivity = async (date) => {
    setPrel(true);
    const activity = await store.trafficsGetActives(id, date);
    setActivity(activity);
    setPrel(false);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const CustomInput = forwardRef(({value, onClick}, ref) => (
    <div
      className='form-control form-control-solid mb-lg-0 w-100 h-100'
      onClick={onClick}
      ref={ref}
      value={value}
    >
      {value}
    </div>
  ));

  const getKyivOffset = () => {
    return (moment.tz('Europe/Kiev').utcOffset() / 60) * 60 * 60 * 1000;
  };

  const getTimezoneOffset = (date) => {
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    const hoursOffset = (Math.abs(timezoneOffsetInMinutes) / 60) * 60 * 60 * 1000;

    return {timezoneOffsetInMinutes, hoursOffset};
  };

  const initTime = () => {
    const kievOffset = getKyivOffset();
    const {timezoneOffsetInMinutes, hoursOffset} = getTimezoneOffset(new Date());

    if (timezoneOffsetInMinutes > 0) {
      const diff = hoursOffset + kievOffset;
      const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
      setDate(currentTime);
      setStartTimeForRequest(currentTime - hoursOffset);
    } else if (timezoneOffsetInMinutes < 0) {
      if (kievOffset > hoursOffset) {
        const diff = kievOffset - hoursOffset;
        const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
        setDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      } else if (kievOffset < hoursOffset) {
        const diff = hoursOffset - kievOffset;
        const currentTime = new Date(new Date().getTime() - diff).setHours(0, 0, 0);
        setDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      } else {
        const currentTime = new Date().setHours(0, 0, 0);
        setDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      }
    } else {
      const diff = hoursOffset + kievOffset;
      const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
      setDate(currentTime);
      setStartTimeForRequest(currentTime);
    }
  };

  const changeDate = async (start, e) => {
    function getMillisecondsForMidnightInKievFromGMT(h, m, s, date) {
      const kievTime = momentTZ(date).tz('Europe/Kiev');
      const utcTime = kievTime.clone().utc();
      utcTime.hours(h);
      utcTime.minutes(m);
      utcTime.seconds(s);
      const milliseconds = utcTime.valueOf();
      return milliseconds;
    }

    setDate(start);

    const yearStart = start.getFullYear();
    const monthStart = start.getMonth();
    const dayStart = Number(e.target.innerText);
    const hoursStart = 0;
    const minutesStart = 0;
    const secondsStart = 0;

    const selectDateStart = new Date();
    selectDateStart.setUTCFullYear(yearStart);
    selectDateStart.setUTCMonth(monthStart);
    selectDateStart.setUTCDate(dayStart);
    selectDateStart.setUTCHours(hoursStart, minutesStart, secondsStart);

    const midnightInMillisStart = getMillisecondsForMidnightInKievFromGMT(0, 0, 0, selectDateStart);

    getActivity(moment(midnightInMillisStart).format('YYYY-MM-DD'));
  };

  const calculateTotalDuration = (segments) => {
    let totalDurationMs = 0;

    segments.forEach((segment) => {
      const segmentDurationMs = segment.time_end * 1000 - segment.time_start * 1000;
      totalDurationMs += segmentDurationMs;
    });

    const totalDuration = moment.duration(totalDurationMs);
    const hours = Math.floor(totalDuration.asHours());
    const minutes = totalDuration.minutes();

    return (
      <span
        className={`badge badge-light-${hours < 6 ? 'danger' : 'success'} fw-bold px-4 py-3`}
      >{`${hours} ч ${minutes} мин`}</span>
    );
  };

  const calculateIntervalWidth = (
    startDayMs,
    endDayMs,
    intervalStartMs,
    intervalEndMs,
    blockWidth
  ) => {
    const dayDurationMs = endDayMs - startDayMs;
    const intervalDurationMs = intervalEndMs - intervalStartMs;
    const intervalPercentage = (intervalDurationMs / dayDurationMs) * 100;
    const intervalWidth = (intervalDurationMs / dayDurationMs) * blockWidth;

    return {
      intervalPercentage: intervalPercentage,
      intervalWidth: intervalWidth,
    };
  };

  const formatDuration = (durationMs) => {
    const duration = moment.duration(durationMs);
    const minutes = Math.floor(duration.asMinutes());
    return `${minutes} мин`;
  };

  const getTimeSegments = (time_start, time_end, arrSegments) => {
    const containerWidth = activityContainerWidth;

    const dayDurationMs = time_end - time_start;
    const arrDivs = [];

    let previousEnd = time_start;

    arrSegments.forEach((segment, index) => {
      const segment_start = segment.time_start * 1000;
      const segment_end = segment.time_end * 1000;
      if (segment_start > previousEnd) {
        const gapDurationMs = segment_start - previousEnd;
        const gapPercentage = (gapDurationMs / dayDurationMs) * 100;
        const gapWidth = (gapDurationMs / dayDurationMs) * containerWidth;
        const gapTime = `${moment(previousEnd).format('HH:mm')}-${moment(segment_start).format(
          'HH:mm'
        )}`;
        const gapDurationStr = formatDuration(gapDurationMs);

        arrDivs.push({
          type: 'gap',
          durationMs: gapDurationMs,
          percentage: gapPercentage,
          width: gapWidth,
          color: 'red',
          timePeriod: `${gapTime} (${gapDurationStr})`,
        });
      }

      const segmentWidth = calculateIntervalWidth(
        time_start,
        time_end,
        segment_start,
        segment_end,
        containerWidth
      );
      const segmentTime = `${moment(segment_start).format('HH:mm')}-${moment(segment_end).format(
        'HH:mm'
      )}`;
      const segmentDurationStr = formatDuration(segment_end - segment_start);

      arrDivs.push({
        type: 'segment',
        durationMs: segment_end - segment_start,
        percentage: segmentWidth.intervalPercentage,
        width: segmentWidth.intervalWidth,
        color: 'green',
        timePeriod: `${segmentTime} (${segmentDurationStr})`,
      });

      previousEnd = segment_end;
    });

    if (previousEnd < time_end) {
      const gapDurationMs = time_end - previousEnd;
      const gapPercentage = (gapDurationMs / dayDurationMs) * 100;
      const gapWidth = (gapDurationMs / dayDurationMs) * containerWidth;
      const gapTime = `${moment(previousEnd).format('HH:mm')}-${moment(time_end).format('HH:mm')}`;
      const gapDurationStr = formatDuration(gapDurationMs);

      arrDivs.push({
        type: 'gap',
        durationMs: gapDurationMs,
        percentage: gapPercentage,
        width: gapWidth,
        color: 'red',
        timePeriod: `${gapTime} (${gapDurationStr})`,
      });
    }

    if (!arrDivs.length) {
      return (
        <div className={styles.segmentContainer}>
          <div
            className={styles.gap}
            style={{
              width: `${containerWidth}px`,
            }}
          ></div>
          <span className={classNames(styles.gapTime, 'fs-8 fw-600')}>
            <span className={styles.marker} />
            00:00-23:59 (1440 мин)
          </span>
        </div>
      );
    }

    return arrDivs.map((item, index) => (
      <React.Fragment key={index}>
        {item.type === 'gap' && (
          <div className={styles.segmentContainer}>
            <div
              className={styles.gap}
              style={{
                width: `${item.width}px`,
              }}
            ></div>
            <span className={classNames(styles.gapTime, 'fs-8 fw-600')}>
              <span className={styles.marker} />
              {item.timePeriod}
            </span>
          </div>
        )}
        {item.type === 'segment' && (
          <div className={styles.segmentContainer}>
            <div
              className={styles.segment}
              style={{
                width: `${item.width}px`,
              }}
            ></div>
            <span className={classNames(styles.gapTime, 'fs-8 fw-600')}>
              <span className={styles.marker} />
              {item.timePeriod}
            </span>
          </div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className='card card-flush'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Активность</h3>
        </div>
        <div className='d-flex flex-wrap align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
          <div className='me-4 mt-2 min-w-20'>
            <div className={`col-6 fv-row mb-7 px-2`}>
              <label
                htmlFor='kt_td_picker_linked_2_input'
                className='form-label d-flex align-items-center'
              >
                <DatePicker
                  calendarClassName={classNames(
                    css.container,
                    css[`_${mode}`],
                    mode === 'dark' ? css.dark : css.light
                  )}
                  dateFormat='dd/MM/yyyy'
                  selected={date}
                  onChange={changeDate}
                  customInput={<CustomInput />}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table
            id='kt_profile_overview_table'
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
          >
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th className='min-w-200px'>Менеджер</th>
                <th className='min-w-600px max-w-800px text-center' id={`activity-line-0`}>
                  Активность
                </th>
                <th className='min-w-110px text-center text-nowrap'>Время работы</th>
                <th className='min-w-100px text-center'>Сообщения</th>
              </tr>
            </thead>
            <tbody className='fs-6'>
              {activity.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-5 position-relative'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img
                              src={
                                !!item?.avatar
                                  ? `${process.env.REACT_APP_API_HOST}/${item?.avatar}`
                                  : imagePlug
                              }
                              alt=''
                            />
                          </div>
                        </div>

                        <div className='d-flex flex-column justify-content-center'>
                          <Link to={`/sales/${type}/managers/${item.id}`}>
                            <span className='d-flex align-items-center fs-6 text-gray-800 text-hover-primary cursor-pointer text-nowrap'>
                              {item.first_name} {item.last_name}
                            </span>
                          </Link>
                          <div className='fw-semibold text-gray-400'>#{item.id}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        className={classNames(styles.activityContainer)}
                        style={
                          mode === 'dark'
                            ? {'--tooltipMode': 'rgb(31, 31, 90)', '--tooltipTextMode': 'white'}
                            : {'--tooltipMode': '#ebebeb', '--tooltipTextMode': 'black'}
                        }
                      >
                        {getTimeSegments(item.time_start * 1000, item.time_end * 1000, item.times)}
                      </div>
                    </td>
                    <td className='text-center'>{calculateTotalDuration(item.times)}</td>
                    <td className='text-center'>{formatNumber(item.count_messages)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {prel && <Preloader style={{border: '0'}} />}
        </div>
      </div>
    </div>
  );
};

export default observer(Activity);
