import React, {useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {Button} from '../../../../shared/Buttons';

const AddSource = observer(() => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      {!store.roles.includes(6) && (
        <Button action={() => setShowCreateAppModal(true)}>Добавить сорс</Button>
      )}
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y px-10 px-lg-15 pt-0 pb-15'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
      >
        <form id='kt_modal_new_target_form' className='form' action='#'>
          {/*begin::Heading*/}
          <div className='mb-13 text-center'>
            {/*begin::Title*/}
            <h1 className='mb-3'>Set First Target</h1>
            {/*end::Title*/}
            {/*begin::Description*/}
            <div className='text-muted fw-semibold fs-5'>
              If you need more info, please check
              <span className='fw-bold link-primary'>Project Guidelines</span>.
            </div>
            {/*end::Description*/}
          </div>
          {/*end::Heading*/}
          {/*begin::Input group*/}
          <div className='d-flex flex-column mb-8 fv-row'>
            {/*begin::Label*/}
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
              <span className='required'>Target Title</span>
              <span
                className='ms-1'
                data-bs-toggle='tooltip'
                title='Specify a target name for future usage and reference'
              >
                <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </span>
            </label>
            {/*end::Label*/}
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter Target Title'
              name='target_title'
            />
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className='row g-9 mb-8'>
            {/*begin::Col*/}
            <div className='col-md-6 fv-row'>
              <label className='required fs-6 fw-semibold mb-2'>Assign</label>
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select a Team Member'
                name='target_assign'
                value={''}
              >
                <option value=''>Select user...</option>
                <option value='1'>Karina Clark</option>
                <option value='2'>Robert Doe</option>
                <option value='3'>Niel Owen</option>
                <option value='4'>Olivia Wild</option>
                <option value='5'>Sean Bean</option>
              </select>
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className='col-md-6 fv-row'>
              <label className='required fs-6 fw-semibold mb-2'>Due Date</label>
              {/*begin::Input*/}
              <div className='position-relative d-flex align-items-center'>
                {/*begin::Icon*/}
                <i className='ki-duotone ki-calendar-8 fs-2 position-absolute mx-4'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                  <span className='path5'></span>
                  <span className='path6'></span>
                </i>
                {/*end::Icon*/}
                {/*begin::Datepicker*/}
                <input
                  className='form-control form-control-solid ps-12'
                  placeholder='Select a date'
                  name='due_date'
                />
                {/*end::Datepicker*/}
              </div>
              {/*end::Input*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className='d-flex flex-column mb-8'>
            <label className='fs-6 fw-semibold mb-2'>Target Details</label>
            <textarea
              className='form-control form-control-solid'
              rows='3'
              name='target_details'
              placeholder='Type Target Details'
            ></textarea>
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className='d-flex flex-column mb-8 fv-row'>
            {/*begin::Label*/}
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
              <span className='required'>Tags</span>
              <span className='ms-1' data-bs-toggle='tooltip' title='Specify a target priorty'>
                <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </span>
            </label>
            {/*end::Label*/}
            <input
              className='form-control form-control-solid'
              value='Important, Urgent'
              name='tags'
            />
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className='d-flex flex-stack mb-8'>
            {/*begin::Label*/}
            <div className='me-5'>
              <label className='fs-6 fw-semibold'>Adding Users by Team Members</label>
              <div className='fs-7 fw-semibold text-muted'>
                If you need more info, please check budget planning
              </div>
            </div>
            {/*end::Label*/}
            {/*begin::Switch*/}
            <label className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value='1'
                defaultChecked='checked'
              />
              <span className='form-check-label fw-semibold text-muted'>Allowed</span>
            </label>
            {/*end::Switch*/}
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className='mb-15 fv-row'>
            {/*begin::Wrapper*/}
            <div className='d-flex flex-stack'>
              {/*begin::Label*/}
              <div className='fw-semibold me-5'>
                <label className='fs-6'>Notifications</label>
                <div className='fs-7 text-muted'>Allow Notifications by Phone or Email</div>
              </div>
              {/*end::Label*/}
              {/*begin::Checkboxes*/}
              <div className='d-flex align-items-center'>
                {/*begin::Checkbox*/}
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    name='communication[]'
                    value='email'
                    defaultChecked='checked'
                  />
                  <span className='form-check-label fw-semibold'>Email</span>
                </label>
                {/*end::Checkbox*/}
                {/*begin::Checkbox*/}
                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    name='communication[]'
                    value='phone'
                  />
                  <span className='form-check-label fw-semibold'>Phone</span>
                </label>
                {/*end::Checkbox*/}
              </div>
              {/*end::Checkboxes*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Input group*/}
          {/*begin::Actions*/}
          <div className='text-center'>
            <button type='reset' id='kt_modal_new_target_cancel' className='btn btn-light me-3'>
              Cancel
            </button>
            <button type='submit' id='kt_modal_new_target_submit' className='btn btn-primary'>
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
          {/*end::Actions*/}
        </form>
      </CreateAppModal>
    </>
  );
});

export default AddSource;
