/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import 'moment/locale/ru';
import moment from 'moment';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';

const Security = ({isUser = null, token, getAuthData, revokeToken}) => {
  const store = useStore();
  const [data, setData] = useState({});

  useEffect(() => {
    moment.locale('ru');
    setNewData();
  }, []);

  const setNewData = async () => {
    const result = await getAuthData(isUser);

    if (result.length === 0) {
      return;
    }

    const newResult = result.map((el) => {
      return {...el, platform: parseUserAgent(el.user_agent)};
    });

    const arrAuth = newResult.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
    });

    const currentAuthIndex = arrAuth.findIndex((obj) => obj.main_token === token);

    const currentAuth = arrAuth[currentAuthIndex];

    currentAuthIndex !== -1 && arrAuth.splice(currentAuthIndex, 1);

    setData({currentAuth, otherAuth: arrAuth});
    return;
  };

  const parseUserAgent = (userAgent) => {
    const operatingSystems = {
      Windows: /Windows/i,
      MacOS: /Macintosh|Mac OS X/i,
      iOS: /iPhone|iPad|iPod/i,
      Android: /Android/i,
      Linux: /Linux/i,
    };

    const browsers = {
      Chrome: /Chrome/i,
      Firefox: /Firefox/i,
      Safari: /Safari/i,
      Edge: /Edg/i,
      InternetExplorer: /MSIE|Trident/i,
    };

    const detectedOS =
      Object.keys(operatingSystems).find((os) => operatingSystems[os].test(userAgent)) ||
      'Unknown OS';
    const detectedBrowser =
      Object.keys(browsers).find((browser) => browsers[browser].test(userAgent)) ||
      'Unknown Browser';

    return {operatingSystem: detectedOS, browser: detectedBrowser};
  };

  const deleteAuth = async (token) => {
    const result = await revokeToken(token);

    notify(!!result, !!result ? 'Вы успешно закрыли авторизацию!' : 'Произошла ошибка!');

    !!result && setNewData();
  };

  function formatTimeAgo(milliseconds) {
    if (new Date().getTime() - milliseconds * 1000 < 3600000) {
      return moment(milliseconds * 1000)
        .startOf('minute')
        .fromNow();
    }

    return moment(milliseconds * 1000)
      .startOf('hour')
      .fromNow();
  }

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  return (
    <>
      <Card className='card pt-4 mb-6 mb-xl-9'>
        <div className='card-header border-0'>
          <div className='card-title'>
            <h2>История Авторизаций</h2>
          </div>
        </div>

        <div className='card-body pt-0 pb-5'>
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed gy-5'
              id='kt_table_users_login_session'
            >
              <thead className='border-bottom border-gray-200 fs-7 fw-bold'>
                <tr className='text-start text-muted text-uppercase gs-0'>
                  <th className='min-w-100px'>Геолокация</th>
                  {!isUser ? null : <th className='d-none d-md-block min-w-125px border-0' />}
                  <th>Время</th>
                  {!isUser ? null : <th className='d-none d-md-block min-w-125px border-0' />}
                  <th>Девайс</th>
                  {!isUser ? null : <th className='d-none d-md-block min-w-125px border-0' />}
                  <th className='min-w-125px'>IP Адрес</th>
                  {!isUser && <th className='min-w-70px'>Статус</th>}
                </tr>
              </thead>
              <tbody className='fs-6 fw-semibold text-gray-600'>
                {!!data?.currentAuth && (
                  <tr>
                    <td>{data?.currentAuth?.geo}</td>
                    <td>{formatTimeAgo(data?.currentAuth?.created_at)}</td>
                    <td>
                      {data?.currentAuth?.platform?.browser} -{' '}
                      {data?.currentAuth?.platform?.operatingSystem}
                    </td>
                    <td>{data?.currentAuth?.ip}</td>
                    {!isUser && (
                      <td>
                        <span className='badge badge-light-success fs-7'>Текущая</span>
                      </td>
                    )}
                  </tr>
                )}
                {!!data.otherAuth &&
                  data?.otherAuth.map((el) => {
                    return (
                      <tr key={el.created_at}>
                        <td>{el?.geo}</td>
                        {!isUser ? null : <td className='d-none d-none d-md-block border-0' />}
                        <td>{formatTimeAgo(el?.created_at)}</td>
                        {!isUser ? null : <td className='d-none d-md-block border-0' />}
                        <td>
                          {el?.platform?.browser} - {el?.platform?.operatingSystem}
                        </td>
                        {!isUser ? null : <td className='d-none d-md-block border-0' />}
                        <td>{el?.ip}</td>
                        {!isUser && (
                          <td>
                            <Button
                              color={'badge-light-warning'}
                              className='btn-active-light-warning btn-sm text-nowrap'
                              action={() => deleteAuth(el?.main_token)}
                            >
                              <i className='ki-duotone ki-entrance-right fs-3 text-warning'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Выйти
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};

export default observer(Security);
