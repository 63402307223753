/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Finances from '../../../widgets/Finances';
import Chart from '../../../widgets/Chart';
import PageWrapper from '../../../modules/PageWrapper';
import { formatNumber } from '../../../functions/formatNumber';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();

  const [salesStats, setSalesStats] = useState({});
  const [qualificationSchedule, setQualificationSchedule] = useState([]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [timeConfig, isVerify]);

  const getData = async (tc) => {
    const getMainStats = await store.getMainStatsInfluence(timeConfig);
    const getQualificationSchedule = await store.getQualificationScheduleInfluence(timeConfig);
    setQualificationSchedule(getQualificationSchedule);
    setSalesStats(getMainStats);

    setPreloader(false);
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10 mt-0'>
          <Finances
            background={'inf'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Пользователей',
                number: formatNumber(salesStats?.count_leads) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Регистрации',
                number: formatNumber(salesStats?.count_registrations) || 0,
                icon: (
                  <i className='ki-duotone ki-user-edit fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Обращения',
                number: formatNumber(salesStats?.count_appeals) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(salesStats?.count_qualifications) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mb-xl-10 mt-xl-0'>
          <Chart data={{graph: qualificationSchedule}} />
        </div>
      </div>
      {/* <div className='row gx-5 gx-xl-10'>
        <div className='col-xl-7 mb-5 mb-xl-10 mt-0'>
          <Card className='card card-flush h-md-100'>
            <div className='card-header pt-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>ТОП ИНФЛЮЕНСЕРОВ</span>

                <span className='text-gray-500 mt-1 fw-semibold fs-6'>За все время</span>
              </h3>

              <div className='card-toolbar'>
                <span className='btn btn-sm btn-light'>Подробнее</span>
              </div>
            </div>

            <div className='card-body pt-6'>
              <div className='table-responsive' style={{height: '365px'}}>
                <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                  <thead>
                    <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                      <th className='pb-3 min-w-175px text-start'>Инфлюенсер</th>
                      <th className='pb-3 min-w-100px text-end'>Расходы</th>
                      <th className='pb-3 min-w-100px text-end'>Доходы</th>
                      <th className='pb-3 min-w-100px text-end'>ROMI</th>
                      <th className='pb-3 min-w-100px'>Прибыль</th>
                      <th className='pb-3 w-50px text-end'>Подробнее</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-3'>
                            <img src={imagePlug} className='' alt='' />
                          </div>

                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6'>
                              NAME
                            </span>
                            <span className='text-gray-500 fw-semibold d-block fs-7'>#1</span>
                          </div>
                        </div>
                      </td>

                      <td className='text-end pe-0'>
                        <span className='text-gray-600 fw-bold fs-6'>${formatNumber2(2345.5)}</span>
                      </td>
                      <td className='text-end pe-0'>
                        <span className='text-gray-600 fw-bold fs-6'>${formatNumber2(2345.5)}</span>
                      </td>

                      <td className='text-end pe-0'>
                        <span className='badge badge-light-success fs-base'>120.25%</span>
                      </td>

                      <td className='pe-0'>
                        <span className='badge badge-light-success fs-base'>
                          ${formatNumber2(12345.5)}
                        </span>
                      </td>

                      <td className='text-end'>
                        <span className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px'>
                          <i className='ki-duotone ki-black-right fs-2 text-gray-500'></i>{' '}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-xl-5 mb-5 mb-xl-10'>
          <Card className='card card-flush h-md-100'>
            <div className='card-header border-0 pt-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>ТОП БАЙЕРОВ</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Анализ роботы байеров</span>
              </h3>
            </div>
            <div className='card-body pt-6'>
              <div className='table-responsive' style={{height: '365px'}}>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-140px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div className='symbol symbol-50px me-2'>
                          <span className='symbol-label'>
                            <img src={imagePlug} className='h-75' alt='' />
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                          Name Name
                        </span>
                        <span className='text-muted fw-semibold d-block'>#1</span>
                      </td>
                      <td>
                        <span className='text-muted fw-semibold d-block fs-7'>Прибыль</span>
                        <span className='text-gray-900 fw-bold d-block fs-5'>
                          ${formatNumber(1200000)}
                        </span>
                      </td>
                      <td className='text-end'>
                        <span className='text-muted fw-semibold d-block fs-7'>ROMI</span>
                        <span className='text-success fs-7 fw-bold'>28%</span>
                      </td>
                      <td className='text-end'>
                        <span className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                          <i className='ki-duotone ki-arrow-right fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>{' '}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </div>
      </div> */}
    </>
  );
});

const InfluencePanel = () => {
  return (
    <>
      <PageWrapper
        firstTimeConfig='current_month'
        breadcrumbs={[
          {title: 'PanelHunt', path: ''},
          {isSeparator: true},
          {title: 'Панель', path: '/dashboard/influence', isActive: true},
        ]}
        title={'Панель Influence'}
      >
        <DashboardPage />
      </PageWrapper>
    </>
  );
};

export default InfluencePanel;
