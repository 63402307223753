/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Finances from '../../../widgets/Finances';
import Carousel from '../../../shared/Carousel';
import posterImg from '../../../../_metronic/assets/media/17-dark.png';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import {Link} from 'react-router-dom';
import Employees from '../../sales/team/LTC/Employees';
import {findKPILtc} from '../../../functions/findKPI';
import {formatNumberToCash, formatNumber} from '../../../functions/formatNumber';
import Activity from './Activity';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();

  const [mainStats, setMainStats] = useState({});
  const [ltcStats, setLtcStats] = useState();
  const [managersStats, setManagerStats] = useState([]);
  const [usersCount, setUsersCount] = useState('');

  useEffect(() => {
    console.log(timeConfig)
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const getSalesStats = await store.getDashboardTeamsLtcMainStats(timeConfig);
    const getLtcStats = await store.getDashboardTeamsLtcStats(timeConfig);
    const getManagersStats = await store.getDashboardManagersStats(timeConfig);
    const LTCList = await store.salesGetKpiLtcList();

    const managersStats = getManagersStats.managers.map((item) => {
      return {...item, indicator: findKPILtc(LTCList, item?.count_appeal, item?.ltc)};
    });

    const ltcList = await store.financeCategoryGetLtcList();

    const ltc = ltcList.find(
      (el) => +getLtcStats.ltc >= +el.ltc_min && +getLtcStats.ltc <= +el.ltc_max
    );

    setLtcStats({...getLtcStats, indicator: ltc});
    setMainStats(getSalesStats);
    setManagerStats(managersStats);
    setUsersCount(getManagersStats.users_count);
    setPreloader(false);
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10 mt-0'>
          <Finances
            background={'op'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Пользователей',
                number: formatNumber(mainStats?.leads?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Регистрации',
                number: formatNumber(mainStats?.registrations?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-user-edit fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Обращения',
                number: formatNumber(mainStats?.appeals?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(mainStats?.qualifications?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mb-xl-10 mt-xl-0'>
          <div className='row g-5 g-xl-10'>
            <div className='col-xl-6 mb-xl-10'>
              <Carousel title={'LTC'} subTitle={'Tекущие KPI'}>
                <div className={`carousel-item`}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='w-80px flex-shrink-0 me-2 symbol symbol-70px symbol-circle me-5'>
                      <span
                        className={`symbol-label bg-light-${ltcStats?.indicator?.indicator} w-70`}
                      >
                        <i
                          className={`ki-duotone ki-abstract-24 fs-3x text-${ltcStats?.indicator?.indicator}`}
                        >
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>

                    <div className='m-0'>
                      <h4 className='fw-bold text-gray-800 mb-3'>
                        {ltcStats?.ltc}%
                        <span
                          className={`badge badge-light-${ltcStats?.indicator?.indicator} fs-5 fw-bold`}
                        >
                          {ltcStats?.indicator?.name}
                        </span>
                      </h4>
                      <div className='d-flex d-grid gap-5'>
                        <div className='d-flex flex-column flex-shrink-0 me-4'>
                          <span className='d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Обращений: {formatNumber(ltcStats?.count_appeals)}
                          </span>

                          <span className='d-flex align-items-center text-gray-400 fw-bold fs-7'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Квалификаций: {formatNumber(ltcStats?.count_qualifications)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={`/sales/ltc/kpi`}>
                    <Button color={'btn-primary'}>Подробнее</Button>
                  </Link>
                </div>
              </Carousel>
            </div>

            <div className='col-xl-6 mb-5 mb-xl-10'>
              <Carousel title={'Прогноз'} subTitle={'Прогноз выплаты за текущий период'}>
                <div className={`carousel-item active`}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='w-80px flex-shrink-0 me-2 symbol symbol-70px symbol-circle me-5'>
                      <span className={`symbol-label bg-light-primary w-70`}>
                        <i className={`ki-duotone ki-abstract-24 fs-3x text-primary`}>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>

                    <div className='m-0'>
                      <h4 className='fw-bold text-gray-800 mb-3'>
                        {formatNumberToCash(0)}
                        {/* <span
                          className={`badge badge-light-${rdStats?.indicator?.indicator} fs-5 fw-bold`}
                        >
                          {rdStats?.indicator?.indicator === 'danger' && 'Плохой результат'}
                          {rdStats?.indicator?.indicator === 'warning' && 'Средний результат'}
                          {rdStats?.indicator?.indicator === 'success' && 'Хороший результат'}
                        </span> */}
                      </h4>

                      <div className='d-flex d-grid gap-5'>
                        <div className='d-flex flex-column flex-shrink-0 me-4'>
                          <span className='d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Прогноз обращений: {0}
                          </span>

                          <span className='d-flex align-items-center text-gray-400 fw-bold fs-7'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Прогноз LTC: {0}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link to={`/sales/ltc/top/managers`}>
                    <Button color={'btn-primary'}>Подробнее</Button>
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>

          <Card className='card' data-bs-theme='light' style={{backgroundColor: '#1C325E'}}>
            <div className='card-body d-flex ps-xl-15'>
              <div className='m-0'>
                <div className='position-relative fs-2x z-index-2 fw-bold text-white mb-7'>
                  <span className='me-2'>
                    Приведи менеджера и получи{' '}
                    <span className='position-relative d-inline-block text-danger'>
                      <span className='text-danger opacity-75-hover'>100$</span>

                      <span className='position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
                    </span>
                  </span>
                  за каждого.
                  <br /> Условия по кнопке ниже!
                </div>
                <a
                  href='https://t.me/hr_hant'
                  target='_blank'
                  className='btn fw-bold  btn-danger cursor-pointer'
                  rel='noreferrer'
                >
                  Подробнее
                </a>
              </div>

              <img
                src={posterImg}
                className='position-absolute me-3 bottom-0 end-0 h-200px'
                alt=''
              />
            </div>
          </Card>
        </div>
        <div className='col-xl-12 mb-5 mb-xl-10 mt-0'>
          <Employees data={managersStats} usersCount={usersCount} />
        </div>
        {isVerify && (
          <div className='col-xl-12 mb-5 mb-xl-10 mt-0'>
            <Activity type={'ltc'} />
          </div>
        )}
      </div>
    </>
  );
});

const TeamleaderLTC = () => {
  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      mode='sales'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Панель', path: '/dashboard/teamleader/ltc', isActive: true},
      ]}
      title={'Панель тимлидера LTC'}
    >
      <DashboardPage />
    </PageWrapper>
  );
};

export default TeamleaderLTC;
