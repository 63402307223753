/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {CreateAppModal} from '../../../../_metronic/partials'
import {Button} from '../../../shared/Buttons'

const AddProduct = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  return (
    <>
      <div className='card-toolbar'>
        <Button
          color='btn-light-primary'
          action={() => {
            setShowCreateAppModal(true)
          }}
        >
          Add Product
        </Button>
      </div>
      <CreateAppModal
        customWidth='mw-650px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title={'Add a Product'}
      >
        <form className='form' action='#' id='kt_modal_add_product_form'>
          <div className='modal-body py-10 px-lg-17'>
            <h3 className='mb-7'>
              <span className='fw-bold required'>Select Subscription</span>
              <span className='ms-1' data-bs-toggle='tooltip' title='Please select a subscription'>
                <i className='ki-duotone ki-information fs-7'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </span>
            </h3>

            <div className='scroll-y mh-300px me-n7 pe-7'>
              <div className='fv-row'>
                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      checked='checked'
                      data-kt-product-name='Basic'
                      data-kt-product-price='15.99'
                      data-kt-product-frequency='Month'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Basic</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Basic subscription</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $15.99 /<span className='text-gray-400 fs-6 fw-semibold'>Month</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Basic Bundle'
                      data-kt-product-price='149.99'
                      data-kt-product-frequency='Year'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Basic Bundle</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Basic yearly bundle</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $149.99 /<span className='text-gray-400 fs-6 fw-semibold'>Year</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Teams'
                      data-kt-product-price='20.99'
                      data-kt-product-frequency='Month'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Teams</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Teams subscription</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $20.99 /<span className='text-gray-400 fs-6 fw-semibold'>Month</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Teams Bundle'
                      data-kt-product-price='199.99'
                      data-kt-product-frequency='Year'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Teams Bundle</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Teams yearly bundle</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $199.99 /<span className='text-gray-400 fs-6 fw-semibold'>Year</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Corporate'
                      data-kt-product-price='224.99'
                      data-kt-product-frequency='Month'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Corporate</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Corporate subscription</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $224.99 /<span className='text-gray-400 fs-6 fw-semibold'>Month</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Corporate Bundle'
                      data-kt-product-price='1249.99'
                      data-kt-product-frequency='Year'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Corporate Bundle</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Corporate yearly bundle</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $1249.99 /<span className='text-gray-400 fs-6 fw-semibold'>Year</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Enterprise'
                      data-kt-product-price='224.99'
                      data-kt-product-frequency='Month'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Enterprise</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Enterprise subscription</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $224.99 /<span className='text-gray-400 fs-6 fw-semibold'>Month</span>
                  </span>
                </label>

                <label className='d-flex align-items-center mb-5'>
                  <span className='form-check form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='product'
                      data-kt-product-name='Enterprise Bundle'
                      data-kt-product-price='2249.99'
                      data-kt-product-frequency='Year'
                    />
                  </span>

                  <span className='d-flex flex-column me-3'>
                    <span className='fw-bold'>Enterprise Bundle</span>
                    <span className='text-gray-400 fw-semibold fs-6'>Enterprise yearly bundle</span>
                  </span>

                  <span className='fw-bold ms-auto'>
                    $2249.99 /<span className='text-gray-400 fs-6 fw-semibold'>Year</span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className='modal-footer flex-center'>
            <Button color='btn-light' className='me-3'>
              Discard
            </Button>
            <Button color='btn-primary' className='me-3'>
              Submit
            </Button>
          </div>
        </form>
      </CreateAppModal>
    </>
  )
}

export default AddProduct
