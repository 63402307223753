/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../_metronic/partials';
import ModeToolbar from '../../../modules/ModeToolbar';
import {PageTitle} from '../../../../_metronic/layout/core';
import Pagination from '../../../shared/Pagination';
import {Button} from '../../../shared/Buttons';
import AddDomain from './AddDomain';
import InputSearch from '../../../shared/InputSearch';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';

const DomainsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Домены', path: '/tech/domains', isActive: true},
      ]}
      title={'Домены'}
    >
      <Domains />
    </PageWrapper>
  );
};

const Domains = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [domains, setDomains] = useState([]);
  const [searchDomains, setSearchDomains] = useState([]);

  useEffect(() => {
    isVerify && getDomains();
  }, [isVerify]);

  const getDomains = async () => {
    const result = await store.getDomains();
    setSearchDomains([...result]);
    setDomains([...result]);
    setPreloader(false);
  };

  const sortStatus = (status) => {
    const newDomains = domains.filter((item) => item.status === status);

    setSearchDomains(newDomains);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
            <InputSearch
              title={'Поиск...'}
              array={domains}
              newArray={setSearchDomains}
              searchParameters={['id', 'ip', 'domain']}
            />
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <span>Статус</span> <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      sortStatus(true);
                    }}
                  >
                    Активный
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      sortStatus(false);
                    }}
                  >
                    Не активный
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSearchDomains(domains);
                    }}
                  >
                    Все статусы
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <ButtonAdd getDomains={getDomains} />
            </div>
          </div>
        </div>
        <Table domains={domains} getDomains={getDomains} searchDomains={searchDomains} />
      </Card>
    </>
  );
});

const Table = ({domains, getDomains, searchDomains}) => {
  // const [portionDomains, setPortionDomains] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [offset, setOffset] = useState(1);

  // useEffect(() => {
  //   const newDomains = [];
  //   for (let i = limit * offset - limit; i < limit * offset; i++) {
  //     if (domains.length > i) {
  //       newDomains.push(domains[i]);
  //     }
  //   }
  //   setPortionDomains(newDomains);
  // }, [offset, domains]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>Домен</th>
                <th className='min-w-125px'>IP сервера</th>
                <th className='min-w-125px'>Действителен до</th>
                <th className='min-w-125px'>Статус</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchDomains.map((el, i) => {
                return <Domain key={el.id} data={el} getDomains={getDomains} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {domains.length > limit && (
        <Pagination
          arrLength={domains.length}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )} */}
    </div>
  );
};

const Domain = ({data, getDomains}) => {
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <span className='text-gray-800 text-hover-primary mb-1' style={{cursor: 'pointer'}}>
            {data.domain}
          </span>
          <span>#{data.id}</span>
        </div>
      </td>
      <td>
        <div className=''>{data.server.ip}</div>
      </td>
      <td>
        <div className='badge badge-light fw-bold'>{data.valid}</div>
      </td>
      <td>
        <div className={`badge ${!data.status ? 'badge-light-danger' : 'badge-light-success'}`}>
          {!data.status ? 'Не активный' : 'Активный'}
        </div>
      </td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <Link to={`/tech/domains/${data.id}`}>
            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className='ki-duotone ki-switch fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </Link>
          <ButtonUpdate getDomains={getDomains} data={data} />
        </div>
      </td>
    </tr>
  );
};

export const ButtonAdd = ({getDomains}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление домена'}
      >
        <AddDomain
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getDomains={getDomains}
          isUpdate={false}
        />
      </CreateAppModal>
    </>
  );
};

const ButtonUpdate = ({getDomains, data}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование домена'}
      >
        <AddDomain
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getDomains={getDomains}
          isUpdate={true}
          data={data}
        />
      </CreateAppModal>
    </>
  );
};

export default observer(DomainsWrapper);
