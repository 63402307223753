import React, {useState} from 'react';
import css from '../../styles/RangeInput.module.scss';
import 'react-range-slider-input/dist/style.css';
import {Input} from './Inputs';

const InputRange = ({valueMin = 0, valueMax = 0, style, action}) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const handleChange = (val, field) => {
    if (val.includes('.')) {
      const parts = val.split('.');
      if (parts[1].length > 2) {
        return;
      }
    }

    const regex = /^\d*\.?\d*$/;

    if (regex.test(val) || val === '') {
      field === 'min' && setMin(val);
      field === 'max' && setMax(val);
    }
  };

  const activeFilter = () => {
    const arrD = [];
    arrD[0] = Number(min);
    arrD[1] = Number(max);
    if (!Number(min)) {
      arrD[0] = Number(valueMin);
    }
    if (!Number(max)) {
      arrD[1] = Number(valueMax);
    }

    action(arrD);
  };

  return (
    <div className={css.container}>
      <Input
        style={{
          minHeight: '30px',
          height: '41px',
        }}
        classText='inputRange-1min'
        text={'Сумма MIN'}
        value={min || ''}
        handleChange={(e) => handleChange(e.target.value, 'min')}
      />
      <Input
        style={{
          minHeight: '30px',
          height: '41px',
        }}
        classText='inputRange-1max'
        text={'Сумма MAX'}
        value={max || ''}
        handleChange={(e) => handleChange(e.target.value, 'max')}
      />
      <button
        className='btn btn-light d-flex justify-content-center align-items-center'
        onClick={activeFilter}
      >
        <i className='ki-duotone ki-filter p-0'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </button>
    </div>
  );
};

export default InputRange;
