/* eslint-disable no-unused-vars */
import React from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import Navigation from '../../../../../modules/NavigationBloggers';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import Card from '../../../../../shared/Card';
import {formatNumberToCash} from '../../../../../functions/formatNumber';

const BloggerHeader = ({page, setPage, data, links, actionComponents = []}) => {
  const store = useStore();

  return (
    <>
      <Card className='card mb-8'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug
                  }
                  alt=''
                />
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {data?.name}
                    </span>
                    <span className={`badge badge-light-${data?.indicator?.indicator} me-auto`}>
                      {!store.roles.includes(19) ? `${data?.romi}%` : data?.indicator?.name}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                    #{data?.id}
                  </div>
                </div>

                <div className='d-flex mb-4'>
                  {actionComponents.map((el, i) => {
                    return <div key={i}>{el}</div>;
                  })}
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bold'>
                        {moment(new Date(data?.created_at * 1000)).format('ll')}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumberToCash(data?.cost)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Расходы</div>
                  </div>

                  {!store.roles.includes(19) && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <div
                          className='fs-4 fw-bold'
                          data-kt-countup='true'
                          data-kt-countup-value='15000'
                          data-kt-countup-prefix='$'
                        >
                          {formatNumberToCash(data?.revenue)}
                        </div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>Доход</div>
                    </div>
                  )}
                  {!store.roles.includes(19) && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <div
                          className='fs-4 fw-bold'
                          data-kt-countup='true'
                          data-kt-countup-value='15000'
                          data-kt-countup-prefix='$'
                        >
                          {formatNumberToCash(data?.profit)}
                        </div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>Прибыль</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='separator'></div>
          <Navigation page={page} setPage={setPage} links={links} />
        </div>
      </Card>
    </>
  );
};

export default observer(BloggerHeader);
