/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import css from '../../../../styles/Header.module.scss';
import clsx from 'clsx';
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
} from '../../../partials';
// import {useLayout} from '../../core'
// import {Search} from '../../../partials'
import {CreateAppModal, useThemeMode} from '../../../partials';
import Calendar from '../../../../app/modules/calendar/Calendar';
import {Button} from '../../../../app/shared/Buttons';
import imagePlug from '../../../assets/media/user.png';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {useLocation} from 'react-router-dom';
import BirthdayModal from './BirthdayModal';

const itemClass = 'ms-1 ms-lg-3';
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'fs-1';

const Navbar = observer((isVerify) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [data, setData] = useState({});
  const location = useLocation();

  useEffect(() => {
    setData(store?.user);
  }, [store?.user]);

  useEffect(() => {
    showModal();
  }, [location]);

  useEffect(() => {
    showModal();
  }, [data]);

  const showModal = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const targetDate = new Date(data.date_birth);

    const cookieName = `modalShown-${currentYear}`;
    const hasShownModal = document.cookie.includes(cookieName);

    if (
      !hasShownModal &&
      currentDate.getMonth() === targetDate.getMonth() &&
      currentDate.getDate() === targetDate.getDate()
    ) {
      setShowCreateAppModal(true);

      document.cookie = `${cookieName}=true; expires=${new Date(
        currentYear + 1,
        0,
        1
      ).toUTCString()}; path=/`;
    }
  };

  // const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <div className='border border-gray-300 border-dashed rounded min-w-100px py-1 px-4 mb-0'>
          <div className='fw-semibold text-gray-500'>Баланс</div>
          <div className='fs-7 text-gray-800 fw-bold'>${data.balance}</div>
        </div>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol bg-secondary', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={!!data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug}
            alt='#'
          />
        </div>
        <HeaderUserMenu data={data} />
        <CreateAppModal
          customWidth='max-modal-width'
          show={showCreateAppModal}
          handleClose={() => {
            setShowCreateAppModal(false);
          }}
          showHeader={false}
          isMessage={true}
          padding='p-0'
          backdropClassName='backdrop-blur'
        >
          <BirthdayModal data={data} setShowCreateAppModal={setShowCreateAppModal} />
        </CreateAppModal>
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  );
});

export {Navbar};
