/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {useStore} from '../../../../store/store';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import AttachmentOffersList from './AttachmentOffers';
import Navigation from '../../../modules/NavigationBloggers';

const AdvertiserPage = observer(
  ({timeConfig, isVerify, setPreloader, id, page, setPage, links}) => {
    const store = useStore();

    const [data, setData] = useState({});

    useEffect(() => {
      isVerify && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const getTrafficStats = await store.advertisersGetAdvertiser(id);

      setData(getTrafficStats);
      setPreloader(false);
    };

    return (
      <>
        <Card className='card mb-8'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img
                    src={
                      data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug
                    }
                    alt=''
                  />
                </div>
              </div>
              <div className='flex-grow-1'>
                <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                        {data.name}
                      </span>
                    </div>

                    <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                      #{data?.id}
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-start'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4 fw-bold'>
                          {moment(new Date(data?.created_at * 1000)).format('ll')}
                        </div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Navigation page={page} setPage={setPage} links={links} />
          </div>
        </Card>
      </>
    );
  }
);

const AdvertiserPageWrapper = observer(({timeConfig, timeStart, timeEnd}) => {
  const store = useStore();
  const {id} = useParams();

  const [page, setPage] = useState('offers');
  const [data, setData] = useState({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [portionData, setPortionData] = useState([]);

  useEffect(() => {
    const newUsers = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (searchData.length > i) {
        newUsers.push(searchData[i]);
      }
    }
    setPortionData(newUsers);
  }, [offset, searchData]);

  useEffect(() => {
    setOffset(1);
  }, [searchData]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const offers = await store.advertisersGetOffers(id);
    setData(offers);
    setSearchData(offers);
  };
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: `/`},
        {isSeparator: true},
        {title: 'Рекламодатели', path: `/advertisers/list`},
        {isSeparator: true},
        {title: `Рекламодатель #${id}`, path: `/advertisers/list/${id}`, isActive: true},
      ]}
      title={`Рекламодатель #${id}`}
      firstTimeConfig='current_month'
    >
      <AdvertiserPage
        timeConfig={timeConfig}
        timeStart={timeStart}
        timeEnd={timeEnd}
        id={id}
        page={page}
        setPage={setPage}
        links={[{link: 'offers', title: 'Офферы'}]}
      />
      {page === 'offers' && (
        <AttachmentOffersList
          data={data}
          searchData={searchData}
          portionData={portionData}
          title={'Офферы'}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          setSearchData={setSearchData}
        />
      )}
    </PageWrapper>
  );
});

export default AdvertiserPageWrapper;
