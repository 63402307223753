/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Card from '../../../../shared/Card';
import Pagination from '../../../../shared/Pagination';
import InputSearch from '../../../../shared/InputSearch';
import { formatNumberToCash } from '../../../../functions/formatNumber';

const AttachmentBloggerList = ({
  data,
  searchData,
  portionData,
  title,
  limit,
  offset,
  setOffset,
  setSearchData,
}) => {
  const store = useStore();

  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5 justify-content-start'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
          <InputSearch
            title={'Поиск...'}
            array={data}
            newArray={setSearchData}
            searchParameters={['name', 'id']}
          />
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {!!data.length && (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-50px'>ID</th>
                    <th className='min-w-140px'>Название</th>
                    <th className='min-w-120px'>Крайняя публикация</th>
                    {!store.roles.includes(19) && <th className='min-w-120px'>Прибыль</th>}
                    {!store.roles.includes(19) && <th className='min-w-120px'>Доходы</th>}
                    {!store.roles.includes(19) && <th className='min-w-120px'>Расходы</th>}
                    <th className='min-w-120px'>ROMI</th>
                    <th className='text-end'></th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-semibold'>
                  {portionData.map((el, index) => {
                    return (
                      <tr key={el?.id}>
                        <td>
                          <span>#{el?.id}</span>
                        </td>
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {el?.name}
                          </span>
                        </td>

                        <td>
                          <span>{moment(el?.last_link_created_at * 1000).format('ll')}</span>
                        </td>
                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.profit)}
                            </span>
                          </td>
                        )}
                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.revenue)}
                            </span>
                          </td>
                        )}
                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.cost)}
                            </span>
                          </td>
                        )}
                        <td>
                          <span className={`badge badge-light-${el?.indicator?.indicator}`}>
                            {el?.romi}%
                          </span>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link to={`/traffics/influence/bloggers/${el.id}`}>
                              <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <i className='ki-duotone ki-switch fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          {searchData.length >= limit && (
            <Pagination
              arrLength={searchData.length}
              limit={limit}
              offset={offset}
              setOffset={setOffset}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default observer(AttachmentBloggerList);
