/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import Warning from '../../../../modules/Warning';
import Card from '../../../../shared/Card';
import {formatNumber, formatNumber2} from '../../../../functions/formatNumber';

const Employees = ({data = [], usersCount}) => {
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    setManagers(data);
  }, [data]);
  return (
    <Card className='card card-flush mt-6 mt-xl-9'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Сотрудники ({usersCount})</h3>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table
            id='kt_profile_overview_table'
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
          >
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th className='min-w-250px'>Сотрудник</th>
                <th className='min-w-90px text-center'>Трафик</th>
                <th className='min-w-100px text-center'>Выходной</th>
                <th className='min-w-90px text-center'>УД</th>
                <th className='min-w-90px text-center'>Кол-во депозитов</th>
                <th className='min-w-90px text-center'>Сумма депозитов</th>
                <th className='min-w-50px text-center'>RD</th>
                <th className='min-w-50px text-center'>Активность</th>
                <th className='min-w-90px text-center'>Квалификации</th>
                <th className='min-w-50px text-end'>Подробнее</th>
              </tr>
            </thead>
            <tbody className='fs-6'>
              {managers?.map((el, i) => {
                return (
                  <tr key={el.manager.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-5 position-relative'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img
                              src={
                                !!el.manager.avatar
                                  ? `${process.env.REACT_APP_API_HOST}/${el?.manager?.avatar}`
                                  : imagePlug
                              }
                              alt=''
                            />
                          </div>
                        </div>

                        <div className='d-flex flex-column justify-content-center'>
                          <span className='d-flex align-items-center fs-6 text-gray-800 text-hover-primary'>
                            {el.manager.first_name} {el.manager.last_name}
                            {!!el?.manager?.is_verified && (
                              <OverlayTrigger
                                delay={{hide: 450, show: 300}}
                                placement='right'
                                overlay={(props) => (
                                  <Tooltip className='fs-8' {...props}>
                                    {!!el?.manager?.verify_date
                                      ? `Верифицирован ${el?.manager?.verify_date}`
                                      : 'Time not set'}
                                  </Tooltip>
                                )}
                              >
                                <div className='symbol symbol-30px symbol-circle'>
                                  <span className='d-flex align-items-center'>
                                    <i className='ki-duotone ki-verify fs-1 text-primary'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}
                            {!!el?.manager?.is_ban && (
                              <OverlayTrigger
                                delay={{hide: 450, show: 300}}
                                placement='right'
                                overlay={(props) => (
                                  <Tooltip className='' {...props}>
                                    {!!el?.manager?.ban_date
                                      ? `Заблокирован ${el?.manager?.ban_date}`
                                      : 'Time not set'}
                                  </Tooltip>
                                )}
                              >
                                <div className='symbol symbol-30px symbol-circle'>
                                  <span className='d-flex align-items-center'>
                                    <i className='text-danger ki-duotone ki-lock-3 fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}
                          </span>
                          <div className='fw-semibold text-gray-400'>#{el.manager.id}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <ChangeTraffic
                          is_traffic={el?.manager?.is_traffic}
                          manager={`${el?.manager?.first_name} ${el?.manager?.last_name}`}
                          id={el?.manager?.id}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <ChangeWork
                          is_dayoff={el?.manager?.is_dayoff}
                          manager={`${el?.manager?.first_name} ${el?.manager?.last_name}`}
                          id={el?.manager?.id}
                          index={i}
                          setManagers={setManagers}
                          managers={managers}
                        />
                      </div>
                    </td>
                    <td className='text-center'>{formatNumber(el.count_leads)}</td>
                    <td className='text-center'>{formatNumber(el.count_deposits)}</td>
                    <td className='text-center'>${formatNumber(el.amounts_deposits)}</td>
                    <td className='text-center'>
                      <span
                        className={`badge badge-light-${el?.indicator?.indicator} fw-bold px-4 py-3`}
                      >
                        {el.rd}$
                      </span>
                    </td>
                    <td className='text-center'>{formatNumber2(el.activity)}%</td>
                    <td className='text-center'>{formatNumber(el.count_qualifications)}</td>
                    <td className='text-end'>
                      <Link to={`/sales/rd/managers/${el?.manager?.id}`}>
                        <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          <i className='ki-duotone ki-switch fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

const ChangeWork = observer(({is_dayoff = false, manager, id, index, setManagers, managers}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [dayoff, setDayoff] = useState(false);

  useEffect(() => {
    if (typeof is_dayoff === 'undefined') return setDayoff(false);

    setDayoff(is_dayoff);
  }, [is_dayoff]);

  const save = async (new_dayoff) => {
    setShowCreateAppModal(false);
    const result = await store.salesManagerUpdateDayoff(Number(new_dayoff), id);

    if (!!result) {
      setDayoff(new_dayoff);
      const newManagers = [...managers];
      newManagers[index].manager.is_traffic = result.is_traffic;
      setManagers(newManagers);
      return toast.success(`Успешно обновлено!`, {});
    }
    toast.error(`Произошла ошибка в обновлении!`, {
      theme: 'colored',
    });
  };

  return (
    <>
      <div className='form-check form-check-solid form-check-custom form-switch'>
        <input
          className='form-check-input w-45px h-30px'
          disabled={store.roles.includes(13) || store.roles.includes(32) ? true : false}
          style={{cursor: 'pointer'}}
          type='checkbox'
          name='is_payment'
          checked={dayoff}
          onChange={() => {
            setShowCreateAppModal(true);
          }}
        />
        <label className='form-check-label' htmlFor='githubswitch'></label>
      </div>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <>
              {dayoff && (
                <span>
                  Вы уверены что хотите отключить статус "Выходной" для{' '}
                  <span className='text-primary'>{manager}</span>
                </span>
              )}
              {!dayoff && (
                <span>
                  Вы уверены что хотите включить статус "Выходной" для{' '}
                  <span className='text-primary'>{manager}</span>
                </span>
              )}
            </>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                save(!dayoff);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

const ChangeTraffic = observer(({is_traffic, manager, id}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [traffic, setTraffic] = useState(false);

  useEffect(() => {
    if (typeof is_traffic === 'undefined') return setTraffic(false);
    setTraffic(is_traffic);
  }, [is_traffic]);

  const save = async (new_traffic) => {
    setShowCreateAppModal(false);
    const result = await store.salesManagerUpdateTraffic(new_traffic, id);

    if (!!result) {
      setTraffic(new_traffic);
      return toast.success(`Успешно обновлено!`, {});
    }
    toast.error(`Произошла ошибка в обновлении!`, {
      theme: 'colored',
    });
  };

  return (
    <>
      <div className='form-check form-check-solid form-check-custom form-switch'>
        <input
          className='form-check-input w-45px h-30px'
          style={{cursor: 'pointer'}}
          disabled={store.roles.includes(13) || store.roles.includes(32) ? true : false}
          type='checkbox'
          name='is_payment'
          checked={traffic}
          onChange={() => {
            setShowCreateAppModal(true);
          }}
        />
        <label className='form-check-label' htmlFor='githubswitch'></label>
      </div>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <>
              {traffic && (
                <span>
                  Вы уверены что хотите отключить трафик для{' '}
                  <span className='text-primary'>{manager}</span>
                </span>
              )}
              {!traffic && (
                <span>
                  Вы уверены что хотите включить трафик для{' '}
                  <span className='text-primary'>{manager}</span>
                </span>
              )}
            </>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                save(!traffic);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default Employees;
