import React, {useState} from 'react';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import iconPlug from '../../../../../../_metronic/assets/media/user.png';

const GenerationLink = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <Button
        className='me-3'
        // color='btn'
        action={() => setShowCreateAppModal(true)}
      >
        Генирация сылки
      </Button>
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y mx-5 mx-xl-18 pt-0 pb-15'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
      >
        {/*begin::Modal header*/}
        {/*begin::Modal body*/}

        <div className='text-center mb-13'>
          <h1 className='mb-3'>Search Users</h1>
          <div className='text-muted fw-semibold fs-5'>Invite Collaborators To Your Project</div>
        </div>
        {/*end::Content*/}
        {/*begin::Search*/}
        <div
          id='kt_modal_users_search_handler'
          data-kt-search-keypress='true'
          data-kt-search-min-length='2'
          data-kt-search-enter='enter'
          data-kt-search-layout='inline'
        >
          {/*begin::Form*/}
          <form
            data-kt-search-element='form'
            className='w-100 position-relative mb-5'
            autocomplete='off'
          >
            {/*begin::Hidden input(Added to disable form autocomplete)*/}
            <input type='hidden' />
            {/*end::Hidden input*/}
            {/*begin::Icon*/}
            <i className='ki-duotone ki-magnifier fs-2 fs-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            {/*end::Icon*/}
            {/*begin::Input*/}
            <input
              type='text'
              className='form-control form-control-lg form-control-solid px-15'
              name='search'
              value=''
              placeholder='Search by username, full name or email...'
              data-kt-search-element='input'
            />
            {/*end::Input*/}
            {/*begin::Spinner*/}
            <span
              className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'
              data-kt-search-element='spinner'
            >
              <span className='spinner-border h-15px w-15px align-middle text-muted'></span>
            </span>
            {/*end::Spinner*/}
            {/*begin::Reset*/}
            <span
              className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none'
              data-kt-search-element='clear'
            >
              <i className='ki-duotone ki-cross fs-2 fs-lg-1 me-0'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
            {/*end::Reset*/}
          </form>
          {/*end::Form*/}
          {/*begin::Wrapper*/}
          <div className='py-5'>
            {/*begin::Suggestions*/}
            <div data-kt-search-element='suggestions'>
              {/*begin::Heading*/}
              <h3 className='fw-semibold mb-5'>Recently searched:</h3>
              {/*end::Heading*/}
              {/*begin::Users*/}
              <div className='mh-375px scroll-y me-n7 pe-7'>
                {/*begin::User*/}
                <span className='d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-35px symbol-circle me-5'>
                    <img alt='Pic' src={iconPlug} />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Info*/}
                  <div className='fw-semibold'>
                    <span className='fs-6 text-gray-800 me-2'>Emma Smith</span>
                    <span className='badge badge-light'>Art Director</span>
                  </div>
                  {/*end::Info*/}
                </span>
                {/*end::User*/}
                {/*begin::User*/}
                <span className='d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-35px symbol-circle me-5'>
                    <span className='symbol-label bg-light-danger text-danger fw-semibold'>M</span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Info*/}
                  <div className='fw-semibold'>
                    <span className='fs-6 text-gray-800 me-2'>Melody Macy</span>
                    <span className='badge badge-light'>Marketing Analytic</span>
                  </div>
                  {/*end::Info*/}
                </span>
                {/*end::User*/}
                {/*begin::User*/}
                <span className='d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-35px symbol-circle me-5'>
                    <img alt='Pic' src={iconPlug} />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Info*/}
                  <div className='fw-semibold'>
                    <span className='fs-6 text-gray-800 me-2'>Max Smith</span>
                    <span className='badge badge-light'>Software Enginer</span>
                  </div>
                  {/*end::Info*/}
                </span>
                {/*end::User*/}
                {/*begin::User*/}
                <span className='d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-35px symbol-circle me-5'>
                    <img alt='Pic' src={iconPlug} />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Info*/}
                  <div className='fw-semibold'>
                    <span className='fs-6 text-gray-800 me-2'>Sean Bean</span>
                    <span className='badge badge-light'>Web Developer</span>
                  </div>
                  {/*end::Info*/}
                </span>
                {/*end::User*/}
                {/*begin::User*/}
                <span className='d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-35px symbol-circle me-5'>
                    <img alt='Pic' src={iconPlug} />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Info*/}
                  <div className='fw-semibold'>
                    <span className='fs-6 text-gray-800 me-2'>Brian Cox</span>
                    <span className='badge badge-light'>UI/UX Designer</span>
                  </div>
                  {/*end::Info*/}
                </span>
                {/*end::User*/}
              </div>
              {/*end::Users*/}
            </div>
            {/*end::Suggestions*/}
            {/*begin::Results(add d-none to below element to hide the users list by default)*/}
            <div data-kt-search-element='results' className='d-none'>
              {/*begin::Users*/}
              <div className='mh-375px scroll-y me-n7 pe-7'>
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='0'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Emma Smith
                      </span>
                      <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                      value={'1'}
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='1'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='1']"
                        value='1'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-danger text-danger fw-semibold'>
                        M
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Melody Macy
                      </span>
                      <div className='fw-semibold text-muted'>melody@altbox.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                      value={'1'}
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='2'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='2']"
                        value='2'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Max Smith
                      </span>
                      <div className='fw-semibold text-muted'>max@kt.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='3'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='3']"
                        value='3'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Sean Bean
                      </span>
                      <div className='fw-semibold text-muted'>sean@dellito.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='4'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='4']"
                        value='4'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Brian Cox
                      </span>
                      <div className='fw-semibold text-muted'>brian@exchange.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='5'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='5']"
                        value='5'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-warning text-warning fw-semibold'>
                        C
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Mikaela Collins
                      </span>
                      <div className='fw-semibold text-muted'>mik@pex.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='6'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='6']"
                        value='6'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Francis Mitcham
                      </span>
                      <div className='fw-semibold text-muted'>f.mit@kpmg.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='7'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='7']"
                        value='7'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-danger text-danger fw-semibold'>
                        O
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Olivia Wild
                      </span>
                      <div className='fw-semibold text-muted'>olivia@corpmail.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='8'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='8']"
                        value='8'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-primary text-primary fw-semibold'>
                        N
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Neil Owen
                      </span>
                      <div className='fw-semibold text-muted'>owen.neil@gmail.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='9'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='9']"
                        value='9'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Dan Wilson
                      </span>
                      <div className='fw-semibold text-muted'>dam@consilting.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='10'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='10']"
                        value='10'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-danger text-danger fw-semibold'>
                        E
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Emma Bold
                      </span>
                      <div className='fw-semibold text-muted'>emma@intenso.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='11'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='11']"
                        value='11'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Ana Crown
                      </span>
                      <div className='fw-semibold text-muted'>ana.cf@limtel.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='12'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='12']"
                        value='12'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-info text-info fw-semibold'>A</span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Robert Doe
                      </span>
                      <div className='fw-semibold text-muted'>robert@benko.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='13'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='13']"
                        value='13'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        John Miller
                      </span>
                      <div className='fw-semibold text-muted'>miller@mapple.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='14'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='14']"
                        value='14'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-success text-success fw-semibold'>
                        L
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Lucy Kunic
                      </span>
                      <div className='fw-semibold text-muted'>lucy.m@fentech.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='15'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='15']"
                        value='15'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={iconPlug} />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Ethan Wilder
                      </span>
                      <div className='fw-semibold text-muted'>ethan@loop.com.au</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
                {/*begin::Separator*/}
                <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                {/*end::Separator*/}
                {/*begin::User*/}
                <div className='rounded d-flex flex-stack bg-active-lighten p-4' data-user-id='16'>
                  {/*begin::Details*/}
                  <div className='d-flex align-items-center'>
                    {/*begin::Checkbox*/}
                    <label className='form-check form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='users'
                        data-kt-check='true'
                        data-kt-check-target="[data-user-id='16']"
                        value='16'
                      />
                    </label>
                    {/*end::Checkbox*/}
                    {/*begin::Avatar*/}
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-success text-success fw-semibold'>
                        L
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Details*/}
                    <div className='ms-5'>
                      <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                        Lucy Kunic
                      </span>
                      <div className='fw-semibold text-muted'>lucy.m@fentech.com</div>
                    </div>
                    {/*end::Details*/}
                  </div>
                  {/*end::Details*/}
                  {/*begin::Access menu*/}
                  <div className='ms-2 w-100px'>
                    <select
                      className='form-select form-select-solid form-select-sm'
                      data-control='select2'
                      data-hide-search='true'
                    >
                      <option value='1'>Guest</option>
                      <option value='2'>Owner</option>
                      <option value='3'>Can Edit</option>
                    </select>
                  </div>
                  {/*end::Access menu*/}
                </div>
                {/*end::User*/}
              </div>
              {/*end::Users*/}
              {/*begin::Actions*/}
              <div className='d-flex flex-center mt-15'>
                <button
                  type='reset'
                  id='kt_modal_users_search_reset'
                  data-bs-dismiss='modal'
                  className='btn btn-active-light me-3'
                >
                  Cancel
                </button>
                <button type='submit' id='kt_modal_users_search_submit' className='btn btn-primary'>
                  Add Selected Users
                </button>
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Results*/}
            {/*begin::Empty*/}
            <div data-kt-search-element='empty' className='text-center d-none'>
              {/*begin::Message*/}
              <div className='fw-semibold py-10'>
                <div className='text-gray-600 fs-3 mb-2'>No users found</div>
                <div className='text-muted fs-6'>
                  Try to search by username, full name or email...
                </div>
              </div>
              {/*end::Message*/}
              {/*begin::Illustration*/}
              <div className='text-center px-5'>
                <img
                  src='/demo1/dist/assets/media/illustrations/sketchy-1/1.png'
                  alt=''
                  className='w-100 h-200px h-sm-325px'
                />
              </div>
              {/*end::Illustration*/}
            </div>
            {/*end::Empty*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Search*/}
      </CreateAppModal>
    </>
  );
};

export default GenerationLink;
