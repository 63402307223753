/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router';
import List from './users/List';
import PageWrapper from '../../modules/PageWrapper';

const EmployeesWrapper = () => {
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/users/list': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: ''},
            {isSeparator: true},
            {title: 'Список сотрудников', path: '/users/list', isActive: true},
          ],
          title: 'Сотрудники',
        });
        break;
      }
    }
  }, [location.pathname]);

  return (
    <PageWrapper breadcrumbs={breadcrumbs.br} title={breadcrumbs.title}>
      <List />
    </PageWrapper>
  );
};

export default EmployeesWrapper;
