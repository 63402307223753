/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';

const EditRole = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <Button
        color='btn-light'
        className='btn-active-light-primary my-1'
        action={() => setShowCreateAppModal(true)}
      >
        Edit Role
      </Button>
      <CreateAppModal
        customWidth='mw-750px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title={'Edit Role'}
      >
        <Form />
      </CreateAppModal>
    </>
  );
};

export const Form = () => {
  return (
    <form
      id='kt_modal_update_role_form'
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      action='#'
    >
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7'
        id='kt_modal_update_role_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_update_role_header'
        data-kt-scroll-wrappers='#kt_modal_update_role_scroll'
        data-kt-scroll-offset='300px'
        style={{maxHeight: '594px'}}
      >
        <div className='fv-row mb-10 fv-plugins-icon-container'>
          <label className='fs-5 fw-bold form-label mb-2'>
            <span className='required'>Role name</span>
          </label>

          <input
            className='form-control form-control-solid'
            placeholder='Enter a role name'
            name='role_name'
            value='Developer'
          />

          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
        </div>

        <div className='fv-row'>
          <label className='fs-5 fw-bold form-label mb-2'>Role Permissions</label>

          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <tbody className='text-gray-600 fw-semibold'>
                <tr>
                  <td className='text-gray-800'>
                    Administrator Access
                    <span
                      className='ms-1'
                      data-bs-toggle='tooltip'
                      aria-label='Allows a full access to the system'
                      data-bs-original-title='Allows a full access to the system'
                      data-kt-initialized='1'
                    >
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>{' '}
                  </td>
                  <td>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-9'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='kt_roles_select_all'
                      />
                      <span className='form-check-label' for='kt_roles_select_all'>
                        Select all
                      </span>
                    </label>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>User Management</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='user_management_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='user_management_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='user_management_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Content Management</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='content_management_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='content_management_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='content_management_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Financial Management</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='financial_management_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='financial_management_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='financial_management_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Reporting</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='reporting_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='reporting_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='reporting_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Payroll</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='payroll_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='payroll_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='payroll_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Disputes Management</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='disputes_management_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='disputes_management_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='disputes_management_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>API Controls</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='api_controls_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='api_controls_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='api_controls_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Database Management</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='database_management_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='database_management_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='database_management_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className='text-gray-800'>Repository Management</td>

                  <td>
                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='repository_management_read'
                        />
                        <span className='form-check-label'>Read</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='repository_management_write'
                        />
                        <span className='form-check-label'>Write</span>
                      </label>

                      <label className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='repository_management_create'
                        />
                        <span className='form-check-label'>Create</span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='text-center pt-15'>
        <button type='reset' className='btn btn-light me-3' data-kt-roles-modal-action='cancel'>
          Discard
        </button>

        <button type='submit' className='btn btn-primary' data-kt-roles-modal-action='submit'>
          <span className='indicator-label'>Submit</span>
          <span className='indicator-progress'>
            Please wait...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </div>
    </form>
  );
};

export default EditRole;
