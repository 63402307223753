/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import BloggerCard from './BloggerCard';
import Pagination from '../../../../../shared/Pagination';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import AddServers from '../../../../dev/servers/AddServers';
import AddBlogger from './AddBlogger';
import InputSearch from '../../../../../shared/InputSearch';

const CompaniesList = observer(({bloggers = [], getBloggersList, type, title, bayers}) => {
  const store = useStore();

  const [portionBloggers, setPortionBloggers] = useState([]);
  const [bloggersList, setBloggersList] = useState([]);
  const [searchBloggers, setSearchBloggers] = useState([]);
  const [limit, setLimit] = useState(9);
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    setBloggersList(bloggers);
    setSearchBloggers(bloggers);
  }, [bloggers]);

  const sortStatus = (status) => {
    const newBloggers = bloggersList.filter((item) => item.indicator.indicator === status);

    setSearchBloggers(newBloggers);
  };

  const sortByBayer = (id) => {
    const newBloggers = bloggersList.filter((item) => item.bayer_id === id);
    setSearchBloggers(newBloggers);
  };

  useEffect(() => {
    const newBloggers = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (searchBloggers.length > i) {
        newBloggers.push(searchBloggers[i]);
      }
    }
    setPortionBloggers(newBloggers);
  }, [offset, searchBloggers]);

  useEffect(() => {
    setOffset(1);
  }, [searchBloggers]);

  return (
    <>
      <div className='d-flex flex-wrap flex-stack my-5'>
        <h2 className='fs-2 fw-semibold my-2'>
          {title}
          <span className='fs-6 text-gray-400 ms-1'></span>
        </h2>

        <div className='d-flex flex-wrap my-1'>
          <InputSearch
            title={'Поиск...'}
            array={bloggersList}
            newArray={setSearchBloggers}
            searchParameters={['id', 'name']}
          />
          <div className='mx-5 d-flex'>
            <button
              type='button'
              className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Окупаемость <i className='ki-duotone ki-down fs-5 ms-1'></i>
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  onClick={() => {
                    sortStatus('danger');
                  }}
                >
                  Плохая окупаемость
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    sortStatus('warning');
                  }}
                >
                  Средняя окупаемость
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    sortStatus('success');
                  }}
                >
                  Высокая окупаемость
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    setBloggersList(bloggers);
                    setSearchBloggers(bloggers);
                  }}
                >
                  Все типы
                </span>
              </div>
            </div>
          </div>
          {title !== 'Компании' && (
            <div className='mx-5 d-flex'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Байер <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
                data-kt-menu='true'
              >
                {bayers.map((bayer) => {
                  return (
                    <div className='menu-item px-3' key={bayer.id}>
                      <span
                        className='menu-link px-3'
                        onClick={() => {
                          sortByBayer(bayer.id);
                        }}
                      >
                        {`${bayer.first_name} ${bayer.last_name}`}
                      </span>
                    </div>
                  );
                })}
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSearchBloggers(bloggers);
                    }}
                  >
                    Все
                  </span>
                </div>
              </div>
            </div>
          )}
          {store.roles.includes(6) ||
          store.roles.includes(19) ||
          store.roles.includes(13) ||
          store.roles.includes(32) ? (
            <></>
          ) : (
            <ButtonAdd getBloggersList={getBloggersList} type={type} />
          )}
        </div>
      </div>
      <div className='row g-6 g-xl-9'>
        {portionBloggers.map((el, i) => {
          return <BloggerCard data={el} key={el.id} />;
        })}
        {searchBloggers.length >= limit && (
          <Pagination
            arrLength={searchBloggers.length}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        )}
      </div>
    </>
  );
});

export const ButtonAdd = ({getBloggersList, type}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={type === 'facebook' ? 'Добавление компании' : 'Добавление блогера'}
      >
        <AddBlogger
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getBloggersList={getBloggersList}
          isUpdate={false}
          type={type}
        />
      </CreateAppModal>
    </>
  );
};

export default CompaniesList;
