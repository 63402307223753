/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef} from 'react';
import {createPortal} from 'react-dom';
import {Modal} from 'react-bootstrap';
import {StepperComponent} from '../../../assets/ts/components';
import {KTIcon} from '../../../helpers';

type Props = {
  show: boolean;
  handleClose: () => void;
  backdropClassName?: string;
  children?: React.ReactChild | React.ReactChild[];
  title?: string;
  customWidth?: string;
  padding?: string;
  showHeader?: boolean;
  isMessage?: boolean;
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const CreateAppModal = ({
  show,
  handleClose,
  backdropClassName,
  title,
  customWidth = 'mw-950px',
  padding = 'py-lg-10 px-lg-10',
  showHeader = true,
  isMessage = false,
  children,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered ${customWidth}`}
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdropClassName={backdropClassName}
    >
      {!isMessage && (
        <>
          {showHeader && (
            <div className='modal-header'>
              <h2>{title}</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTIcon className='fs-1' iconName='cross' />
              </div>
            </div>
          )}
          {!showHeader && (
            <div className='w-100 d-flex flex-end ps-10 pe-10 pt-10'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTIcon className='fs-1' iconName='cross' />
              </div>
            </div>
          )}
        </>
      )}

      <div className={`modal-body ${padding}`}>{children}</div>
    </Modal>,
    modalsRoot
  );
};

export {CreateAppModal};
