/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import {useThemeMode} from '../../../../_metronic/partials';

const Deveror = () => {
  const theme = useThemeMode();

  const [mode, setMode] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <div
      style={{
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: mode === 'dark' ? 'rgb(13, 14, 16)' : 'white',
        zIndex: '100000',
      }}
    >
      <div className='d-flex flex-column flex-center text-center p-10'>
        <div className='card card-flush  w-lg-650px py-5'>
          <div className='card-body py-15 py-lg-20'>
            <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Внимание!</h1>
            <div className='fw-semibold fs-6 text-gray-500 mb-7'>
              Данная страница находится на стадии разработки, совсем скоро она будет доступна к
              использованию!
            </div>
            <div className='mb-3'>
              <img
                src='/media/auth/404-error.png'
                className='mw-100 mh-300px theme-light-show'
                alt=''
              />
              <img
                src='/media/auth/404-error-dark.png'
                className='mw-100 mh-300px theme-dark-show'
                alt=''
              />
            </div>
            <div className='mb-0'>
              <Link to='/dashboard/main'>
                <Button>На главную</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deveror;
