import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import {useThemeMode} from '../../_metronic/partials';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  const store = useStore()
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  useEffect(() => {
    store.errorsMessages.forEach((el) => {
      return toast.error(el, {theme: 'colored'});
    });
  }, [store.errorsMessages]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <ToastContainer
        style={{top: '80px'}}
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={themeMode}
      />
      <div
        className='d-flex flex-column flex-root app-root'
        id='kt_app_root'
        style={{'--themModeCustom': themeMode}}
      >
        {children}
      </div>
    </>
  );
};

export default observer(Layout);
