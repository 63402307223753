/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import css from '../../../../styles/HRPanel.module.scss';
import classNames from 'classnames';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import PageWrapper from '../../../modules/PageWrapper';
import Chart3 from '../../../widgets/Chart3';
import Departments from '../../../widgets/Departments';

const HRPanel = () => {
  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Панель HR', path: '/dashboard/hr', isActive: true},
      ]}
      title={'Панель HR'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = observer(({isVerify, timeConfig, setPreloader}) => {
  const store = useStore();

  const [departments, setDepartments] = useState([]);
  const [employeeGeo, setEmployeeGeo] = useState([]);
  const [employeeStats, setEmployeeStats] = useState({});

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const getDepartments = await store.departamentsGetList();
    const getEmployeesStats = await store.hrGetEmployeesStats(timeConfig);
    const getEmployeesGeo = await store.hrGetEmployeesGeo(timeConfig);

    setEmployeeGeo(
      getEmployeesGeo.map((item) => {
        const newObj = {...item, count: item.count_users};
        delete newObj.count_users;
        return newObj;
      })
    );
    setEmployeeStats(getEmployeesStats);
    setDepartments(getDepartments);
    setPreloader(false);
  };

  return (
    <>
      <div className={classNames(css.row, 'row gx-5 gx-xl-10')}>
        <div className='col-xl-3'>
          <div className='card mb-5 mb-xl-8'>
            <div className='card-body d-flex'>
              <div className='w-100'>
                <span className='fw-bold text-gray-900 fs-4'>Всего работает</span>

                <div className='d-flex align-items-center mb-4 mt-4'>
                  <div className='fw-bold fs-1 text-gray-800 pe-1'>
                    {employeeStats?.work?.count}
                  </div>
                  {employeeStats?.work?.indicator === 'up' ? (
                    <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  ) : (
                    <i className='ki-duotone ki-arrow-down fs-3 text-danger ms-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  )}
                </div>

                <div className='d-flex align-items-center'>
                  <span className='fs-6 text-gray-600'>Прошлый период</span>
                  {employeeStats?.work?.indicator === 'up' ? (
                    <span className='fs-4 text-success fw-bold ms-3'>
                      +{employeeStats?.work?.difference}
                    </span>
                  ) : (
                    <span className='fs-4 text-danger fw-bold ms-3'>
                      -{employeeStats?.work?.difference}
                    </span>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <i className='ki-duotone ki-profile-user fs-3x'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card mb-5 mb-xl-8'>
            <div className='card-body d-flex'>
              <div className='w-100'>
                <span className='fw-bold text-gray-900 fs-4'>Уволено</span>

                <div className='d-flex align-items-center mb-4 mt-4'>
                  <div className='fw-bold fs-1 text-gray-800 pe-1'>
                    {employeeStats?.fired?.count}
                  </div>

                  {employeeStats?.fired?.indicator === 'up' ? (
                    <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  ) : (
                    <i className='ki-duotone ki-arrow-down fs-3 text-danger ms-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  )}
                </div>

                <div className='d-flex align-items-center'>
                  <span className='fs-6 text-gray-600'>Прошлый период</span>
                  {employeeStats?.fired?.indicator === 'up' ? (
                    <span className='fs-4 text-success fw-bold ms-3'>
                      +{employeeStats?.fired?.difference}
                    </span>
                  ) : (
                    <span className='fs-4 text-danger fw-bold ms-3'>
                      -{employeeStats?.fired?.difference}
                    </span>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <i className='ki-duotone ki-cross-circle fs-3x'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card mb-5 mb-xl-8'>
            <div className='card-body d-flex'>
              <div className='w-100'>
                <span className='fw-bold text-gray-900 fs-4 text-nowrap'>Испытательный период</span>

                <div className='d-flex align-items-center mb-4 mt-4'>
                  <div className='fw-bold fs-1 text-gray-800 pe-1'>
                    {employeeStats?.verify?.count}
                  </div>

                  {employeeStats?.verify?.indicator === 'up' ? (
                    <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  ) : (
                    <i className='ki-duotone ki-arrow-down fs-3 text-danger ms-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  )}
                </div>

                <div className='d-flex align-items-center'>
                  <span className='fs-6 text-gray-600'>Прошлый период</span>
                  {employeeStats?.verify?.indicator === 'up' ? (
                    <span className='fs-4 text-success fw-bold ms-3'>
                      +{employeeStats?.verify?.difference}
                    </span>
                  ) : (
                    <span className='fs-4 text-danger fw-bold ms-3'>
                      -{employeeStats?.verify?.difference}
                    </span>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <i className='ki-duotone ki-verify fs-3x'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card mb-0 mb-xl-8'>
            <div className='card-body d-flex'>
              <div className='w-100'>
                <span className='fw-bold text-gray-900 fs-4'>Новых</span>

                <div className='d-flex align-items-center mb-4 mt-4'>
                  <div className='fw-bold fs-1 text-gray-800 pe-1'>{employeeStats?.new?.count}</div>

                  {employeeStats?.new?.indicator === 'up' ? (
                    <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  ) : (
                    <i className='ki-duotone ki-arrow-down fs-3 text-danger ms-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  )}
                </div>

                <div className='d-flex align-items-center'>
                  <span className='fs-6 text-gray-600'>Прошлый период</span>
                  {employeeStats?.new?.indicator === 'up' ? (
                    <span className='fs-4 text-success fw-bold ms-3'>
                      +{employeeStats?.new?.difference}
                    </span>
                  ) : (
                    <span className='fs-4 text-danger fw-bold ms-3'>
                      -{employeeStats?.new?.difference}
                    </span>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <i className='ki-duotone ki-people fs-3x'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                  <span className='path5'></span>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row gx-5 gx-xl-10'>
        <div className='col-xl-5 mb-5 mb-xl-10 mt-5 mt-xl-0'>
          <Chart3 countries={employeeGeo} title={'ГЕО сотрудников'} />
        </div>
        <div className='col-xl-7 mb-5 mb-xl-10'>
          <Departments data={departments} />
        </div>
      </div>
    </>
  );
});

export default HRPanel;
