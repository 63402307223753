/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import PageWrapper from '../../../modules/PageWrapper';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {formatNumberToCash, formatNumber, formatNumber2} from '../../../functions/formatNumber';

const Pwas = () => {
  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'PWA', path: '/resources/pwa', isActive: true},
      ]}
      title={'PWA'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = ({isVerify, timeConfig, setPreloader}) => {
  const store = useStore();
  const navigate = useNavigate();
  const [pwas, setPwas] = useState([]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const getPwas = await store.resourcePWAgetApps(timeConfig);
    setPwas(getPwas);
    setPreloader(false);
  };

  const viewPwa = (id) => {
    navigate(`${id}/`);
  };

  return (
    <div className='d-flex flex-wrap flex-stack my-5'>
      <div className='row g-6 g-xl-9'>
        {pwas.map((item) => {
          return (
            <div
              className='col-md-6 col-xl-4'
              style={{cursor: 'pointer'}}
              onClick={() => viewPwa(item.id)}
              key={`${item.id}`}
            >
              <div className='card border-hover-primary'>
                <div className='card-header border-0 pt-5'>
                  <div className='card-title m-0'>
                    <div className='symbol symbol-50px w-50px bg-light'>
                      <img
                        style={{borderRadius: '5px'}}
                        src={
                          item.avatar
                            ? `${process.env.REACT_APP_API_HOST}/${item?.avatar}`
                            : imagePlug
                        }
                        alt='#'
                      />
                    </div>
                  </div>

                  <div className='card-toolbar'>
                    <span className={`badge badge-light-${!!item.active ? 'success' : 'danger'}`}>
                      {!!item?.active ? 'Активна' : 'Не активна'}
                    </span>

                    {item?.is_fb && <span className={`badge badge-light-primary ms-2`}>FB</span>}
                  </div>
                </div>

                <div className='card-body p-9'>
                  <div className='fs-3 fw-bold text-dark'>{item?.name}</div>

                  <p className='text-gray-400 fw-semibold fs-5 mt-1 mb-7'>#{item?.id}</p>

                  <div className='d-flex flex-wrap mb-5'>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                      <div className='fs-6 text-gray-800 fw-bold'>{formatNumber2(item?.cti)}%</div>
                      <div className='fw-semibold text-gray-400'>Click2Inst</div>
                    </div> */}

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                      <div className='fs-6 text-gray-800 fw-bold'>
                        {formatNumber2(item?.install2registration)}%
                      </div>
                      <div className='fw-semibold text-gray-400'>Inst2Reg</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                      <div className='fs-6 text-gray-800 fw-bold'>
                        {formatNumber2(item?.registration2deposit)}%
                      </div>
                      <div className='fw-semibold text-gray-400'>Reg2Dep</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                      <div className='fs-6 text-gray-800 fw-bold'>
                        {formatNumberToCash(item?.revenue_amount)}
                      </div>
                      <div className='fw-semibold text-gray-400'>Выручка</div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap justify-content-between mb-5'>
                    <div className='col-md-3 text-center'>
                      <div className='text-gray-800 fw-bold fs-7'>
                        <span className='m-0 counted'>{formatNumber(item?.install_count)}</span>
                      </div>
                      <span className='text-gray-500 fs-8 d-block fw-bold'>Install</span>
                    </div>

                    <div className='col-md-3 text-center'>
                      <div className='text-gray-800 fw-bold fs-7'>
                        <span className='m-0 counted'>
                          {formatNumber(item?.registration_count)}
                        </span>
                      </div>
                      <span className='text-gray-500 fs-8 d-block fw-bold'>REG</span>
                    </div>

                    <div className='col-md-3 text-center'>
                      <div className='text-gray-800 fw-bold fs-7'>
                        <span className='m-0 counted'>
                          {formatNumber(item?.qualifications_count)}
                        </span>
                      </div>
                      <span className='text-gray-500 fs-8 d-block fw-bold'>CPA</span>
                    </div>
                  </div>

                  <div
                    className='h-4px w-100 bg-light mb-5'
                    data-bs-toggle='tooltip'
                    title='This project 50% completed'
                  >
                    <div
                      className={`bg-${!!item.active ? 'success' : 'danger'} rounded h-4px`}
                      role='progressbar'
                      style={{width: '100%'}}
                      aria-valuenow='100'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Pwas);
