/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts';
import {getCSS, getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import Card from '../shared/Card';

const Chart3 = ({countries = [], title = ''}) => {
  const chartRef = useRef(null);
  const {mode} = useThemeMode();

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(mode, height, countries));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, countries]);

  return (
    <Card className='card card-flush h-xl-100'>
      <div className='card-header py-7'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>{title}</span>
          <span className='text-gray-400 pt-2 fw-semibold fs-6'>Цифры указаны в кол-ве</span>
        </h3>
      </div>

      <div className='card-body pt-0 pb-1'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
      </div>
    </Card>
  );
};

export default Chart3;

function getChartOptions(mode, height, countries) {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-success');
  const lightColor = getCSSVariableValue('--bs-success-light');
  let maxValue = 18;

  const data = countries?.map((el) => {
    return el.count;
  });

  return {
    series: [
      {
        name: 'Квалификации',
        data: data || [],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        barHeight: '30',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },

    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: function (val, opt) {
        return val;
      },
      offsetX: 15,
      style: {
        colors: mode === 'light' ? ['#333'] : ['#fff'],
      },
    },

    legend: {
      show: false,
    },
    colors: ['#3E97FF', '#F1416C', '#50CD89', '#FFC700', '#7239EA'],
    xaxis: {
      categories: countries?.map((el) => {
        return el.name;
      }),
      labels: {
        formatter: function (val) {
          return val;
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: '600',
          align: 'left',
        },
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: '600',
        },
      },
    },
    grid: {
      borderColor: borderColor,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      strokeDashArray: 4,
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
}
