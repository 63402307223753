import {useStore} from '../../../store/store';
import PageWrapper from '../../modules/PageWrapper';
import React, {useEffect} from 'react';
import {observer} from 'mobx-react';

const CRMWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'CRM', path: '/crm', isActive: true},
      ]}
      title={'CRM'}
    >
      <CRM />
    </PageWrapper>
  );
};

const CRM = observer(({isVerify}) => {
  const store = useStore();

  useEffect(() => {
    if (isVerify && store.user.main_token) {
      window.location.href = `https://crmhunt.io/verify/${store.user.main_token}`;
    }
  }, [isVerify, store.user.main_token]);

  return <></>;
});

export default CRMWrapper;
