import {api} from './api';

const telegramAPI = {
  async resourceTelegramGetAccounts(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Resource/Telegram/getAccounts${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async tgAccountsGetTgAccounts(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`TgAccounts/getTgAccounts${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default telegramAPI;
