import {api} from './api';

const countriesAPI = {
  async getCountries(token) {
    try {
      const response = await api.get(`Countries/getList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default countriesAPI;
