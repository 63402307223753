/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Dropdown1} from '../../../shared/dropdown/Dropdown1';
import Filter from '../../../shared/Filter';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import moment from 'moment';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import {Button} from '../../../shared/Buttons';
import numeral from 'numeral';
import {formatNumberToCash} from '../../../functions/formatNumber';

const flows = [
  {
    id: '1',
    date: new Date(),
    startDate: new Date(),
    endDate: '',
    account: 'Tether',
    project: 'Aviator India',
    department: {
      id: 1,
      name: 'C-LEVEL',
      count_users: 3,
      avatar: 'storage/avatars/7YwknH1LYCNKmay23EXh0p1gi1SyfQeIW2ytOuvc.png',
      head_id: 2,
    },
    category: 'test category',
    description: 'Test. Test description. Bla bla bla.',
    amount: '100500',
    checked: false,
  },
  {
    id: '2',
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    account: 'Tether',
    project: 'Aviator India',
    department: {
      id: 1,
      name: 'C-LEVEL',
      count_users: 3,
      avatar: 'storage/avatars/7YwknH1LYCNKmay23EXh0p1gi1SyfQeIW2ytOuvc.png',
      head_id: 2,
    },
    category: 'test category',
    description: 'Test. Test description. Bla bla bla.',
    amount_transfer: '100500',
    checked: false,
  },
  {
    id: '3',
    date: new Date(),
    startDate: new Date(),
    endDate: '',
    account: 'Tether',
    project: 'Aviator India',
    department: {
      id: 1,
      name: 'C-LEVEL',
      count_users: 3,
      avatar: 'storage/avatars/7YwknH1LYCNKmay23EXh0p1gi1SyfQeIW2ytOuvc.png',
      head_id: 2,
    },
    category: 'test category',
    description: 'Test. Test description. Bla bla bla.',
    amount_cost: '100500',
    checked: false,
  },
];

const CasFlow = ({setPreloader}) => {
  const [currentFlows, setCurrentFlows] = useState();
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = currentFlows?.map((checkbox) =>
      checkbox.id === id ? {...checkbox, checked: !checkbox.checked} : checkbox
    );
    setCurrentFlows(updatedCheckboxes);
  };

  const handleSelectAll = () => {
    const updatedCheckboxes = currentFlows?.map((checkbox) => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setCurrentFlows(updatedCheckboxes);
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setCurrentFlows(flows);
    setPreloader(false);
  }, []);

  return (
    <>
      <Card className='card'>
        <Table
          flows={currentFlows}
          handleSelectAll={handleSelectAll}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Card>
    </>
  );
};

const Table = ({flows, handleSelectAll, handleCheckboxChange}) => {
  const [allChecked, setAllChecked] = useState(false);
  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 table-hover'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0 howe-row'>
                <th>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      type='checkbox'
                      className='cursor-pointer form-check-input'
                      style={{marginLeft: '10px'}}
                      checked={allChecked}
                      onChange={() => {
                        handleSelectAll();
                        setAllChecked(!allChecked);
                      }}
                    />
                  </div>
                </th>
                <th className='min-w-125px'>дата</th>
                <th className='min-w-125px'>сума</th>
                <th className='min-w-125px'>счет</th>
                <th className='min-w-125px'>отдел</th>
                <th className='min-w-125px'>категория</th>
                <th className='min-w-125px'>период</th>
                <th className='min-w-125px'>описание</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {flows?.map((el, i) => {
                return <Flow key={i} data={el} handleCheckboxChange={handleCheckboxChange} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Flow = ({data, handleCheckboxChange}) => {
  const cutDescription = (description) => {
    return description.slice(0, 30) + '...';
  };
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='position-relative'>
            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
              <input
                type='checkbox'
                className='cursor-pointer form-check-input'
                style={{marginLeft: '10px'}}
                checked={data.checked}
                onChange={() => handleCheckboxChange(data.id)}
              />
            </div>
          </div>
        </div>
      </td>
      <td>
        <div>{moment(data.date).format('YYYY.MM.DD')}</div>
      </td>
      <td>
        {data.amount && (
          <div className='badge badge-light-success fw-bold text-success'>{`${formatNumberToCash(
            data.amount
          )}`}</div>
        )}
        {data.amount_transfer && (
          <div className='badge badge-light-primary fw-bold text-primary'>{`${formatNumberToCash(
            data.amount_transfer
          )}`}</div>
        )}
        {data.amount_cost && (
          <div className='badge badge-light-primary-danger fw-bold text-danger'>{`${formatNumberToCash(
            data.amount_cost
          )}`}</div>
        )}
      </td>
      <td>
        <div className='badge badge-primary fw-bold'>{data.account}</div>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={data.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug}
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.department.name}</span>
          </div>
        </div>
      </td>
      <td>
        <div className='badge badge-primary fw-bold'>{data.category}</div>
      </td>
      <td>
        <div>
          {data.endDate
            ? `${moment(data.startDate).format('YYYY.MM.DD')} - ${moment(data.endDate).format(
                'YYYY.MM.DD'
              )}`
            : moment(data.startDate).format('YYYY.MM.DD')}
        </div>
      </td>
      <td className='justify-content-center' style={{wordWrap: 'break-word', maxWidth: '150px'}}>
        <div>{cutDescription(data.description)}</div>
      </td>
    </tr>
  );
};

const CashFlowWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'CashFlow', path: '/finances/cashflow', isActive: true},
      ]}
      title={'CashFlow'}
      button={
        <>
          <Button className='m-2' color={'btn-light-success'}>
            + Доход
          </Button>
          <Button className='m-2' color={'btn-light-danger'}>
            - Расход
          </Button>
          <Button className='m-2' color={'btn-light-primary'}>
            Перевод
          </Button>
        </>
      }
    >
      <CasFlow />
    </PageWrapper>
  );
};

export default CashFlowWrapper;
