/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import css from '../../../../styles/PagePwa.module.scss';
import classNames from 'classnames';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../_metronic/partials';
import Preloader from '../../../modules/Preloader';
import {formatNumber} from '../../../functions/formatNumber';
import {Link} from 'react-router-dom';

const Facebook = ({id, timeConfig}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [preloader, setPreloader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const getData = async () => {
    setPreloader(true);
    const res = await store.PWAGetBayers({id, timeConfig});
    setData(res);
    setPreloader(false);
  };

  useEffect(() => {
    timeConfig && getData();
  }, [timeConfig]);

  return (
    <div
      className={classNames(css.pushes, mode === 'dark' && css._dark)}
      style={{position: 'relative'}}
    >
      <section className={css.header}>
        <div className={css.titleContainer}>
          <span className={css.title}>Facebook Байера</span>
        </div>
      </section>
      <section className={css.table} style={{position: 'relative'}}>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-350px'>Байер</th>
                <th className='text-center w-100px'>Клики</th>
                <th className='text-center min-w-300'></th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {data.map((item, index) => {
                return (
                  <tr>
                    <td className='d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                        <div className='me-5 position-relative'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img
                              src={
                                item.avatar
                                  ? `${process.env.REACT_APP_API_HOST}/${item.avatar}`
                                  : imagePlug
                              }
                              alt=''
                            />
                          </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                          <Link to={`/traffics/facebook/bayers/${item.id}`}>
                            <span className='fs-6 text-gray-800 text-hover-primary cursor-pointer'>
                              {item.first_name} {item.last_name}
                            </span>
                          </Link>
                          <div className='fw-semibold text-gray-400'>#{item.id}</div>
                        </div>
                      </div>
                    </td>
                    <td className='text-center p-0'>
                      <span className='badge badge-light-primary'>
                        {formatNumber(item?.count_click)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {preloader && (
          <Preloader style={{border: 'none', width: '101%', height: '101%', boxShadow: 'none'}} />
        )}
      </section>
    </div>
  );
};

export default Facebook;
