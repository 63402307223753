/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, setState, useState} from 'react';
import {Chart} from './ChartForPage';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import Preloader from '../../../modules/Preloader';

const Activity = ({id, timeConfig}) => {
  const store = useStore();
  const [prel, setPrel] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    timeConfig && getData();
  }, [timeConfig]);

  const getData = async () => {
    setPrel(true)
    const res = await store.resourcePWAGetAppPushActivity({ id, timeConfig });
    setData(res.schedule);
    setPrel(false);
  };

  return (
    <div style={{position: "relative"}}>
      <Chart data={data} title={'Активность'} subTitle={''}/>
      {prel && <Preloader/>}
    </div>
  );
};

export default observer(Activity);
