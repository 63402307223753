/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {findKPIRd} from '../../../../functions/findKPI';
import moment from 'moment';
import CardRD from './Card';
import PageWrapper from '../../../../modules/PageWrapper';
import ButtonExel from '../../../../modules/ButtonExel';

const ManagersListRDWrapper = () => {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [time, setTime] = useState({});

  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'Менеджера RD', path: '/sales/rd/managers', isActive: true},
      ]}
      buttonKPI={
        roles.includes(4) ||
        roles.includes(8) ||
        roles.includes(30) ||
        roles.includes(13) ||
        roles.includes(32) ? (
          <ButtonExel
            data={data}
            fieldsToKeep={[
              'id',
              'first_name',
              'last_name',
              'count_leads',
              'count_qualifications',
              'count_deposits',
              'amounts_deposits',
              'rd',
              'is_ban',
              'ban_date',
              'is_verified',
              'verify_date',
              'created_at',
            ]}
            columnFormats={{amounts_deposits: '$#,##0.00'}}
            fileName={'lts_managers'}
            time={time}
          />
        ) : (
          <></>
        )
      }
      title={'Менеджера RD'}
    >
      <ManagersListRD setObjForExel={setData} setRoles={setRoles} setTimeForExel={setTime} />
    </PageWrapper>
  );
};

const ManagersListRD = observer(
  ({timeConfig, isVerify, setPreloader, setObjForExel, setTimeForExel, setRoles}) => {
    const store = useStore();

    const [data, setData] = useState([]);

    useEffect(() => {
      setObjForExel(null);
      setRoles(store.roles);
      isVerify && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const tc = timeConfig;
      const getManagersStats = await store.salesRdGetManagersStats(tc);
      const rdList = await store.salesGetKpiRdList();

      const newManagersStats = getManagersStats.data.managers.map((item) => {
        return {
          ...item,
          indicator: findKPIRd(
            rdList,
            item?.count_qualifications,
            item?.activity,
            item?.rd,
            'manager_kpi'
          ),
        };
      });

      const dataForExel = newManagersStats.map((item) => {
        return {
          ...item,
          ...item.manager,
          ban_date: !!item?.manager?.ban_date ? item?.manager?.ban_date : '',
          verify_date: !!item?.manager?.verify_date ? item?.manager?.verify_date : '',
          is_verified: `${item?.manager?.is_verified}`,
          is_ban: `${item?.manager?.is_ban}`,
          created_at: moment(item?.manager?.created_at * 1000).format('DD.MM.YYYY HH:mm '),
          amounts_deposits: Number(item.amounts_deposits),
        };
      });
      setTimeForExel(getManagersStats.time);
      setObjForExel(dataForExel);

      setData(newManagersStats);
      setPreloader(false);
    };

    return (
      <>
        <div className='row g-6 g-xl-9'>
          {data.map((el) => {
            return <CardRD data={el} key={el.manager.id} />;
          })}
        </div>
      </>
    );
  }
);

export default ManagersListRDWrapper;
