/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, forwardRef} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import css from '../../../../styles/CustomPicker.module.scss';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import DatePicker from 'react-datepicker';
import {useForm, Controller} from 'react-hook-form';
import {CreateAppModal} from '../../../../_metronic/partials';
import {useThemeMode} from '../../../../_metronic/partials';
import {toast} from 'react-toastify';
import Warning from '../../../modules/Warning';
import {Selector} from '../../../shared/Selectors';
import MaskedTextInput from 'react-text-mask';

const AddDomain = ({setWarning, warning, setSave, save, show, getDomains, isUpdate, data}) => {
  const store = useStore();
  const theme = useThemeMode();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm();

  const [mode, setMode] = useState('');

  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    if (isUpdate) {
      setValue('id', data.id);
      setValue('domain', data.domain);
      setValue('server_id', data.server.id);
      setValue('config', data.config);
      setValue('status', data.status);
      !!data.valid && setValue('valid', new Date(data?.valid?.split('-').join('.')));
    }
  }, []);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    const result = await store.getServers();
    const createOptions = result.map((el) => {
      return {
        value: el.id,
        label: el.name,
      };
    });
    setSelectOptions(createOptions);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      getDomains();
      toast.success(`${message}`, {});
    }
  };

  const onSubmit = async (data) => {
    const newData = {...data, valid: moment(data.valid).format('YYYY.MM.DD')};
    let result;

    if (isUpdate) {
      result = await store.updateDomain(newData);
    } else {
      result = await store.addDomain(newData);
    }

    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

    setSave(true);
    show(false);
  };

  const CustomInput = forwardRef(({value, onClick, onChange}, ref) => (
    <MaskedTextInput
      className='form-control form-control-solid mb-3 mb-lg-0 mw-100'
      onClick={onClick}
      onChange={onChange}
      value={value}
      ref={ref}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  ));

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Домен</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('domain', {required: true})}
        />

        {errors?.domain?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>

      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Выберите сервер</label>

        <Controller
          name='server_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={selectOptions} value={value} onChange={onChange} />
          )}
        />

        {errors?.server_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>

      {!isUpdate && (
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Выберите конфигурацию</label>

          <Controller
            name='config'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector
                options={[
                  {value: 'main', label: 'Основной'},
                  {value: 'sub', label: 'Субдомен'},
                ]}
                value={value}
                onChange={onChange}
              />
            )}
          />

          {errors?.config?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      )}
      <div className='fv-row mb-7 fv-plugins-icon-container d-flex flex-column'>
        <label className='required fw-semibold fs-6 mb-2'>Дата до которой валиден домен</label>
        <Controller
          control={control}
          name='valid'
          render={({field: {value, ...fieldProps}}) => {
            return (
              <DatePicker
                {...fieldProps}
                selected={value || new Date()}
                calendarClassName={classNames(
                  css.container,
                  css[`_${mode}`],
                  mode === 'dark' ? css.dark : css.light,
                  'month-year-select'
                )}
                showYearDropdown
                showMonthDropdown
                dateFormat='dd/MM/yyyy'
                customInput={<CustomInput {...fieldProps} />}
              />
            );
          }}
        />
        <div className='fs-6 fw-semibold form-label mb-2 text-danger'></div>
      </div>
      <div className='d-flex flex-end pt-10'>
        <input
          type='submit'
          className={`btn fw-bold  btn-primary cursor-pointer `}
          value={'Сохранить'}
        />
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </form>
  );
};

export default observer(AddDomain);
