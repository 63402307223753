/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import Card from '../../../../../shared/Card';
import numeral from 'numeral';
import InputSearch from '../../../../../shared/InputSearch';
import {formatNumber} from '../../../../../functions/formatNumber';

const Treker = ({data = [], timeConfig}) => {
  const [trekers, setTrekers] = useState([]);
  const [searchTrekers, setSearchTrekers] = useState([]);
  const [sortType, setSortType] = useState({type: 'default', column: ''});

  useEffect(() => {
    setSearchTrekers(data);
    setTrekers(data);
  }, [data]);

  const sortList = (column) => {
    if (column !== sortType.column) {
      return setSortType({type: 'up', column});
    }

    if (sortType.type === 'default') {
      setSortType({type: 'up', column});
    } else if (sortType.type === 'up') {
      setSortType({type: 'down', column});
    } else if (sortType.type === 'down') {
      setSortType({type: 'default', column});
    }
  };

  useEffect(() => {
    if (sortType.type === 'default' && sortType.column === 'default') return;

    if (sortType.type === 'default') {
      return setSearchTrekers([...trekers]);
    } else if (sortType.type === 'up') {
      const newSearchTrekers = [...trekers].sort(
        (a, b) => Number(b[sortType.column]) - Number(a[sortType.column])
      );
      setSearchTrekers(newSearchTrekers);
    } else if (sortType.type === 'down') {
      const newSearchTrekers = [...trekers].sort(
        (a, b) => Number(a[sortType.column]) - Number(b[sortType.column])
      );
      setSearchTrekers(newSearchTrekers);
    }
  }, [sortType]);

  return (
    <div className='col-xl-12 mb-5 mb-xl-10'>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-header pt-7 border-0'>
          <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
            <InputSearch
              title={'Поиск...'}
              array={trekers}
              newArray={setSearchTrekers}
              searchParameters={['campaign_name', 'campaign_id']}
              actions={() => setSortType({type: 'default', column: 'default'})}
            />
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase'>
                    <td className='w-200px'>РК/АДСЕТ</td>
                    <td className='text-end w-150px'>
                      <span
                        className='d-flex justify-content-end align-items-center cursor-pointer'
                        onClick={() => sortList('count_clicks')}
                      >
                        КЛИКИ
                        <i
                          className={`ki-duotone ki-down fs-1 ${
                            sortType.column === 'count_clicks' &&
                            sortType.type === 'up' &&
                            'text-primary'
                          } ${
                            sortType.column === 'count_clicks' &&
                            sortType.type === 'down' &&
                            'text-warning'
                          }`}
                        />
                      </span>
                    </td>
                    <td className='text-end w-150px'>
                      <span
                        className='d-flex justify-content-end align-items-center cursor-pointer'
                        onClick={() => sortList('count_leads')}
                      >
                        ЛИДЫ
                        <i
                          className={`ki-duotone ki-down fs-1 ${
                            sortType.column === 'count_leads' &&
                            sortType.type === 'up' &&
                            'text-primary'
                          } ${
                            sortType.column === 'count_leads' &&
                            sortType.type === 'down' &&
                            'text-warning'
                          }`}
                        />
                      </span>
                    </td>
                    <td className='text-end w-150px'>
                      <span
                        className='d-flex justify-content-end align-items-center cursor-pointer'
                        onClick={() => sortList('count_appeals')}
                      >
                        ОБРАЩЕНИЯ
                        <i
                          className={`ki-duotone ki-down fs-1 ${
                            sortType.column === 'count_appeals' &&
                            sortType.type === 'up' &&
                            'text-primary'
                          } ${
                            sortType.column === 'count_appeals' &&
                            sortType.type === 'down' &&
                            'text-warning'
                          }`}
                        />
                      </span>
                    </td>
                    <td className='text-end w-150px'>
                      <span
                        className='d-flex justify-content-end align-items-center cursor-pointer'
                        onClick={() => sortList('count_registrations')}
                      >
                        РЕГИСТРАЦИИ
                        <i
                          className={`ki-duotone ki-down fs-1 ${
                            sortType.column === 'count_registrations' &&
                            sortType.type === 'up' &&
                            'text-primary'
                          } ${
                            sortType.column === 'count_registrations' &&
                            sortType.type === 'down' &&
                            'text-warning'
                          }`}
                        />
                      </span>
                    </td>
                    <td className='text-end w-150px'>
                      <span
                        className='d-flex justify-content-end align-items-center cursor-pointer'
                        onClick={() => sortList('revenue')}
                      >
                        ДОХОД
                        <i
                          className={`ki-duotone ki-down fs-1 ${
                            sortType.column === 'revenue' &&
                            sortType.type === 'up' &&
                            'text-primary'
                          } ${
                            sortType.column === 'revenue' &&
                            sortType.type === 'down' &&
                            'text-warning'
                          }`}
                        />
                      </span>
                    </td>
                    <td className='text-end w-190px'>
                      <span
                        className='d-flex justify-content-end align-items-center cursor-pointer'
                        onClick={() => sortList('count_qualifications')}
                      >
                        КВАЛИФИКАЦИИ
                        <i
                          className={`ki-duotone ki-down fs-1 ${
                            sortType.column === 'count_qualifications' &&
                            sortType.type === 'up' &&
                            'text-primary'
                          } ${
                            sortType.column === 'count_qualifications' &&
                            sortType.type === 'down' &&
                            'text-warning'
                          }`}
                        />
                      </span>
                    </td>
                    <td className='text-end'></td>
                  </tr>
                </thead>

                <tbody className='fw-bold text-gray-600'>
                  {searchTrekers.map((item, index) => {
                    return (
                      <Item
                        item={item}
                        index={index}
                        timeConfig={timeConfig}
                        key={`${item.campaign_id}-${index}`}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

const Item = observer(({item, index, timeConfig}) => {
  const store = useStore();
  const [openSub, setOpenSub] = useState(false);
  const [subMenu, setSubMenu] = useState([]);

  const getAdsets = async () => {
    if (!!subMenu?.length) return setOpenSub(!openSub);
    const adsets = await store.trafficsFacebookLinksTrackerGetAdsets(timeConfig, item?.campaign_id);
    setSubMenu(adsets?.adsets);
    setOpenSub(!openSub);
  };

  useEffect(() => {
    setOpenSub(false);
    setSubMenu([]);
  }, [timeConfig?.time_config]);

  return (
    <React.Fragment>
      <tr>
        <td className='text-start'>
          <span className='text-gray-900 text-hover-primary'>{item?.campaign_name}</span>
          <br />
          {item?.campaign_id}
        </td>

        <td className='text-end'>{formatNumber(item?.count_clicks)}</td>

        <td className='text-end'>{formatNumber(item?.count_leads)}</td>

        <td className='text-end'>
          <span className='text-gray-900 fw-bold'>{formatNumber(item?.count_appeals)}</span>
        </td>

        <td className='text-end'>{formatNumber(item?.count_registrations)}</td>
        <td className='text-end'>
          <span className='text-gray-900 fw-bold'>{numeral(+item?.revenue).format('$0,0.00')}</span>
        </td>

        <td className='text-end text-nowrap'>
          <span className='text-gray-900 fw-bold'>{formatNumber(item?.count_qualifications)}</span>
          {/* <span className='badge py-3 px-4 fs-7 badge-light-warning'>12,00%</span> */}
        </td>

        <td className='text-end'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px'
            onClick={getAdsets}
          >
            {!openSub && <i className='ki-duotone ki-plus fs-3 m-0'></i>}
            {!!openSub && <i className='ki-duotone ki-minus fs-3 m-0'></i>}
          </button>
        </td>
      </tr>
      {openSub && !subMenu?.length && (
        <tr>
          <td className='text-end' colSpan={1}>
            <div className='text-gray-500 fs-7'>Информация отсутствуєт...</div>
            {/* <div className='text-muted fs-7 fw-bold'>{formatNumber(el?.count_clicks)}</div> */}
          </td>
        </tr>
      )}
      {openSub &&
        !!subMenu?.length &&
        subMenu?.map((el, i) => {
          return (
            <tr className='bg-secondary' key={`${item.campaign_id}-${index}-${el?.adset_id}-${i}`}>
              <td className='rounded-start rounded-bottom-0 ps-5' colSpan={2}>
                <div className='d-flex align-items-center gap-3'>
                  <div className='d-flex flex-column text-muted'>
                    <span className='text-gray-900 text-hover-primary fw-bold'>
                      {el?.adset_name}
                    </span>
                    <div className='fs-7'>{el?.adset_id}</div>
                  </div>
                </div>
              </td>
              <td className='text-end'>
                <div className='text-gray-900 fs-7'>Клики</div>
                <div className='text-muted fs-7 fw-bold'>{formatNumber(el?.count_clicks)}</div>
              </td>
              <td className='text-end'>
                <div className='text-gray-900 fs-7'>Лиды</div>
                <div className='text-muted fs-7 fw-bold'>{formatNumber(el?.count_leads)}</div>
              </td>
              <td className='text-end'>
                <div className='text-gray-900 fs-7'>Обращения</div>
                <div className='text-muted fs-7 fw-bold'>{formatNumber(el?.count_appeals)}</div>
              </td>
              <td className='text-end'>
                <div className='text-gray-900 fs-7'>Регистрации</div>
                <div className='text-muted fs-7 fw-bold'>
                  {formatNumber(el?.count_registrations)}
                </div>
              </td>
              <td className='text-end rounded-end rounded-bottom-0 text-nowrap pe-5' colSpan={2}>
                <div className='text-gray-900 fs-7'>Квалификации</div>
                <div className='text-muted fs-7 fw-bold'>
                  {formatNumber(el?.count_qualifications)}
                  {/* <span className='badge py-3 px-4 fs-7 badge-light-warning'>12,00%</span> */}
                </div>
              </td>
            </tr>
          );
        })}
    </React.Fragment>
  );
});

export default Treker;
