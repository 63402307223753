/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {toast} from 'react-toastify';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {KTIcon} from '../../../../_metronic/helpers';
import {CreateAppModal} from '../../../../_metronic/partials';
import {Button} from '../../../shared/Buttons';
import {ButtonAdd} from '../domains/Domains';
import Card from '../../../shared/Card';
import AddServers from './AddServers';
import PageWrapper from '../../../modules/PageWrapper';

const ServerWrapper = () => {
  const {id} = useParams();

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: `/dashboard/main`},
        {isSeparator: true},
        {title: 'Сервера', path: `/servers/`},
        {isSeparator: true},
        {
          title: `Сервер #${id}`,
          path: `/servers/${id}`,
          isActive: true,
        },
      ]}
      title={'Сервер'}
    >
      <Server />
    </PageWrapper>
  );
};

const Server = observer(({isVerify, setPreloader}) => {
  const store = useStore();
  const {id} = useParams();

  const [data, setData] = useState({});
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const getServer = await store.resourceGetServer(id);
      const getDomains = await store.getDomains('', getServer?.id);

      setData(getServer);
      setDomains(getDomains);
      setPreloader(false);
    })();
  }, [isVerify]);

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-5 mb-xl-10'>
          <Card className='card mb-5 mb-xl-10'>
            <div className='card-header py-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>{data?.name}</span>
                <span className='text-gray-400 pt-2 fw-semibold fs-6'>Информация о сервере</span>
              </h3>
              <div className='card-toolbar'>
                <ButtonUpdate data={data} />
              </div>
            </div>
            <div className='card-body p-9'>
              <div style={{height: '365px'}}>
                <div className='row mb-7'>
                  <label className='col-4 fw-semibold text-muted'>Статус</label>

                  <div className='col-8 d-flex align-items-center'>
                    <span
                      className={`badge ${
                        !data?.status ? 'badge-light-danger' : 'badge-light-success'
                      }`}
                    >
                      {!!data?.status ? 'Активный' : 'Не активный'}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-4 fw-semibold text-muted'>ID севера</label>

                  <div className='col-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 text-gray-800 me-2'>#{data?.id}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-4 fw-semibold text-muted'>IP сервера</label>

                  <div className='col-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.ip}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-4 fw-semibold text-muted'>Дата создания</label>

                  <div className='col-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 text-gray-800 me-2'>
                      {moment(data?.created_at * 1000).format('DD.MM.YYYY')}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-4 fw-semibold text-muted'>Действителен до</label>

                  <div className='col-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 text-gray-800 me-2'>
                      {moment(data?.valid).format('DD.MM.YYYY')}
                    </span>
                  </div>
                </div>

                <div className='row mb-7 d-flex align-items-center'>
                  <label className='col-4 fw-semibold text-muted'>JWT токен</label>

                  <div
                    className='col-7 d-flex align-items-center border border-end-0 border-2 rounded-1'
                    style={{padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)'}}
                  >
                    <span
                      className='fw-bold fs-6 text-gray-800 me-2 cursor-pointer'
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        minHeight: '20px',
                      }}
                      id=''
                    >
                      {data?.jwt_token}
                    </span>
                  </div>
                  <div className='col-1 d-flex flex-end p-0'>
                    <Button
                      className=''
                      style={{borderRadius: '0px 6px 6px 0px'}}
                      color='btn-dark'
                      action={() => copyText(data?.jwt_token)}
                    >
                      <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-xl-8 mb-5 mb-xl-10'>
          <Card className='card'>
            <div className='card-header py-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>Подключенные домены</span>
              </h3>
              <div className='card-toolbar'>
                <ButtonAdd serverId={data?.id} />
              </div>
            </div>
            <div className='card-body py-9'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive' style={{height: '365px'}}>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='kt_table_users'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <th className='min-w-50px'>ID</th>
                        <th className='min-w-150px'>Домен</th>
                        <th className='min-w-100px'>Конфиг</th>
                        <th className='min-w-100px text-center'>Статус</th>
                        <th className='min-w-100px text-end'>Создан</th>
                        <th className='min-w-145px text-center'>Срок действия</th>
                        <th className='text-end min-w-100px'></th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      {domains.map((el, i) => {
                        return (
                          <tr key={el.id}>
                            <td className=''>
                              <span>#{el.id}</span>
                            </td>
                            <td className=''>
                              <span className='text-gray-800 text-hover-primary mb-1'>
                                {el.domain}
                              </span>
                            </td>
                            <td>
                              <div className=''>
                                {data?.config === 'main' ? 'Основной' : 'Субдомен'}
                              </div>
                            </td>
                            <td className='text-center'>
                              <div
                                className={`badge ${
                                  !el.status ? 'badge-light-danger' : 'badge-light-success'
                                }`}
                              >
                                {!el.status ? 'Не активный' : 'Активный'}
                              </div>
                            </td>

                            <td className='text-end'>
                              <div className='badge badge-light fw-bold'>
                                {moment(el.created_at * 1000).format('DD.MM.YYYY')}
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='badge badge-light fw-bold'>
                                {moment(el.valid).format('DD.MM.YYYY')}
                              </div>
                            </td>

                            <td className='text-end'>
                              <Link to={`/tech/domains/${el.id}`}>
                                <Button color='btn-light btn-active-light-primary'>
                                  Подробнее
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
});

const ButtonUpdate = ({getServers, data}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование сервера'}
      >
        <AddServers
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          isUpdate={true}
          data={data}
        />
      </CreateAppModal>
    </>
  );
};

export default ServerWrapper;
