/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';

import {observer} from 'mobx-react';
import {useStore} from '../../../store/store';
import {useLocation} from 'react-router';
import { formatNumberToCash } from '../../functions/formatNumber';
import PageWrapper from '../../modules/PageWrapper';

import ProfileHeader from './ProfileHeader';
import Details from './pages/Details';
import Settings from './pages/Settings';
import Billing from './pages/Billing';
import Security from './pages/Security';
import ProfileHeaderStats from './ProfileHeaderStats';

const Profile = observer(({isVerify, setPreloader}) => {
  const location = useLocation();
  const store = useStore();

  const [data, setData] = useState();

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const result = await store.usersGetMainData();
      const roles = await store.usersGetAllAccess();
      const countries = await store.getCountries();

      const country = countries.find((el) => el.id === result.countries_id);
      const role = roles.roles.find((el) => el.id === result.role_id);

      setData({...result, geo: country?.name, role_name: role?.name});
      setPreloader(false);
    })();
  }, [isVerify]);


  return (
    <>
      <ProfileHeader
        data={data}
        navigation={[
          {link: '/account/info', title: 'Данные'},
          {link: '/account/settings', title: 'Настройки'},
          {link: '/account/billing', title: 'Платежная информация'},
          {link: '/account/security', title: 'Безопасность'},
        ]}
      >
        <ProfileHeaderStats
          title={'Выплачено'}
          StatsUpDown={false}
          stats={formatNumberToCash(data?.account_payments)}
        />
        <ProfileHeaderStats
          title={'Текущий баланс'}
          StatsUpDown={true}
          stats={formatNumberToCash(data?.balance)}
        />
      </ProfileHeader>
      {location.pathname === '/account/info' && <Details data={data} />}
      {location.pathname === '/account/settings' && (
        <Settings getSettings={store.getSettings} updateSettings={store.updateSettings} />
      )}
      {location.pathname === '/account/billing' && (
        <Billing
          getWallets={store.getWallets}
          deleteWallet={store.deleteWallet}
          getTransactions={store.getTransactions}
        />
      )}
      {location.pathname === '/account/security' && (
        <Security
          token={store.user.main_token}
          getAuthData={store.getAuthData}
          revokeToken={store.revokeToken}
        />
      )}
    </>
  );
});

const ProfileWrapper = () => {
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});
  const location = useLocation();
  const store = useStore();

  useEffect(() => {
    store.setToolbarType('');

    switch (location.pathname) {
      case '/account/info': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Мой Аккаунт', path: '/account/info', isActive: true},
          ],
          title: 'Мой аккаунт',
        });
        break;
      }
      case '/account/settings': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Мой Аккаунт', path: '/account/settings', isActive: true},
          ],
          title: 'Мой аккаунт',
        });
        break;
      }
      case '/account/billing': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Мой Аккаунт', path: '/account/billing', isActive: true},
          ],
          title: 'Мой аккаунт',
        });
        break;
      }
      case '/account/security': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Мой Аккаунт', path: '/account/security', isActive: true},
          ],
          title: 'Мой аккаунт',
        });
        break;
      }
    }
  }, [location]);

  return (
    <PageWrapper breadcrumbs={breadcrumbs.br} title={breadcrumbs.title}>
      <Profile />
    </PageWrapper>
  );
};

export default observer(ProfileWrapper);
