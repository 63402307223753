/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {useLocation, useParams} from 'react-router-dom';
import {findKPIRd} from '../../../../functions/findKPI';
import TeamHeader from './TeamHeader';
import Employees from './Employees';
import Leads from './Leads';
import PageWrapper from '../../../../modules/PageWrapper';
import Traffic from './Traffic';
import Activity from '../LTC/Activity';
import Stages from './Stages';

const Component = observer(({timeConfig, isVerify, setPreloader, page, setPage}) => {
  const {id} = useParams();
  const store = useStore();

  const [mainStats, setMainStats] = useState({});
  const [managersStats, setManagerStats] = useState([]);

  const [usersCount, setUsersCount] = useState('');

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const [getMainStats, rdList, RDList, getManagersStats] = await Promise.all([
      store.salesTeamsRdMainStats(tc, id),
      store.financeCategoryGetRdList(),
      store.salesGetKpiRdList(),
      store.salesTeamsRdGetManagersStats(tc, id),
    ]);

    const rd = getMainStats?.statistics?.rd;
    let indicator = rdList.find((item) => rd >= +item.rd_min && rd <= +item.rd_max);

    const newManagersStats = getManagersStats.managers.map((item) => {
      return {
        ...item,
        indicator: findKPIRd(
          rdList,
          item?.count_qualifications,
          item?.activity,
          item?.rd,
          'manager_kpi'
        ),
      };
    });

    setMainStats({...getMainStats, indicator: !indicator ? {indicator: 'success'} : indicator});
    setManagerStats(newManagersStats);
    setUsersCount(getManagersStats.users_count);
    setPreloader(false);
  };

  return (
    <>
      <TeamHeader page={page} setPage={setPage} data={mainStats} getData={getData} />
      {page === 'employees' && <Employees data={managersStats} usersCount={usersCount} />}
      {page === 'leads' && <Leads timeConfig={timeConfig} id={id} isVerify={isVerify} />}
      {page === 'traffic' && <Traffic id={id} />}
      {page === 'activity' && <Activity id={id} type={'rd'} />}
      {page === 'stages' && <Stages id={id} />}
    </>
  );
});

const Team = ({timeConfig, timeStart, timeEnd}) => {
  const [page, setPage] = useState('employees');
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    setBreadcrumbs({
      br: [
        {title: 'PanelHunt', path: `/dashboard/main`},
        {isSeparator: true},
        {
          title: `Команда RD #${params.id}`,
          path: `/sales/rd/teams/${params.id}`,
          isActive: true,
        },
      ],
      title: `Команда RD #${params.id}`,
    });
  }, [page]);

  return (
    <>
      <PageWrapper
        breadcrumbs={breadcrumbs.br}
        title={breadcrumbs.title}
        firstTimeConfig='week_salles'
      >
        <Component page={page} setPage={setPage} />
      </PageWrapper>
    </>
  );
};

export default observer(Team);
