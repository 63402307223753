/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../_metronic/partials';
import css from '../../../../styles/CustomPicker.module.scss';
import styles from '../../../../styles/AnalysisRd.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import PageWrapper from '../../../modules/PageWrapper';
import {Button} from '../../../shared/Buttons';
import {Select} from '../../../shared/Inputs';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import {formatNumber, formatNumber2, formatNumberToCash} from '../../../functions/formatNumber';
import {SelectorWithSubtitle} from '../../../shared/Selectors';

const Analysis = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'Анализ RD', path: '/sales/rd/analysis', isActive: true},
      ]}
      title={'Анализ RD'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = ({isVerify, setPreloader}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [qualificationsStart, setQualificationsStart] = useState(new Date().setUTCHours(0, 0, 0));
  const [qualificationsEnd, setQualificationsEnd] = useState(new Date().setUTCHours(0, 0, 0));
  const [depositsStart, setDepositsStart] = useState(new Date().setUTCHours(0, 0, 0));
  const [depositsEnd, setDepositsEnd] = useState(new Date().setUTCHours(0, 0, 0));

  const [offersOptions, setOffersOptions] = useState([]);
  const [offer, setOffer] = useState('');

  const [teamsOptions, setTeamsOptions] = useState([]);
  const [team, setTeam] = useState('');

  const [managersOptions, setManagersOptions] = useState([]);
  const [manager, setManager] = useState('');

  const [sourcesOptions, setSourcesOptions] = useState([
    {value: 'facebook', label: 'Facebook'},
    {value: 'influence', label: 'Influence'},
  ]);
  const [source, setSource] = useState('');

  const [botsOptions, setBotsOptions] = useState([]);
  const [bot, setBot] = useState('');

  const [channelsOptions, setChannelsOptions] = useState([]);
  const [channel, setChannel] = useState('');

  const [accountsOptions, setAccountsOptions] = useState([]);
  const [account, setAccount] = useState('');

  const [stats, setStats] = useState(null);
  const [statsSoft, setStatsSoft] = useState(null);

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const [advertisersList, teamsList, managersList, botsList, accountsList, channelsList] =
      await Promise.all([
        store.getAdvertisersList(),
        store.salesTeamsGetList('rd'),
        store.getRdManagersList(),
        store.botsGetListBots(),
        store.tgAccountsGetTgAccounts(),
        store.channelsGetChannelsList('main'),
      ]);

    setChannelsOptions(
      channelsList.map((item) => {
        return {value: item.id, label: `#${item.id} ${item.name}`};
      })
    );

    setAccountsOptions(
      accountsList.map((item) => {
        return {value: item.id, label: `#${item.id} ${item.username}`};
      })
    );

    setBotsOptions(
      botsList.map((item) => {
        return {value: item.id, label: `#${item.id} ${item.name}`};
      })
    );

    setOffersOptions(
      advertisersList.map((item) => {
        return {value: item.id, label: `#${item.id} ${item.name}`};
      })
    );

    setTeamsOptions(
      teamsList.map((item) => {
        return {
          value: item.id,
          label: `#${item.id} ${item?.teamleader?.first_name} ${item?.teamleader?.last_name}`,
        };
      })
    );

    setManagersOptions(
      managersList.map((item) => {
        return {value: item.id, label: `#${item.id} ${item?.first_name} ${item?.last_name}`};
      })
    );

    setPreloader(false);
  };

  const clearFilters = () => {
    setOffer('');
    setTeam('');
    setManager('');
    setSource('');
    setBot('');
    setChannel('');
    setAccount('');
    setQualificationsStart(new Date().setUTCHours(0, 0, 0));
    setQualificationsEnd(new Date().setUTCHours(0, 0, 0));
    setDepositsStart(new Date().setUTCHours(0, 0, 0));
    setDepositsEnd(new Date().setUTCHours(0, 0, 0));
  };

  const search = async () => {
    if (depositsStart < qualificationsStart) {
      return toast.error(
        'Начальная дата депозитов не может быть меньше, начальной даты квалификаций!',
        {theme: 'colored'}
      );
    }
    if (qualificationsStart > depositsEnd) {
      return toast.error(
        'Начальная дата квалификаций не может быть больше, конечной даты депозитов!',
        {theme: 'colored'}
      );
    }

    const formObj = {
      time_start_qualifications: Math.floor(
        new Date(qualificationsStart).setUTCHours(0, 0, 0) / 1000
      ),
      time_end_qualifications: Math.floor(
        new Date(qualificationsEnd).setUTCHours(23, 59, 59) / 1000
      ),
      time_start_deposits: Math.floor(new Date(depositsStart).setUTCHours(0, 0, 0) / 1000),
      time_end_deposits: Math.floor(new Date(depositsEnd).setUTCHours(23, 59, 59) / 1000),
    };
    if (!!source) formObj.source = source?.value;
    if (!!bot) formObj.bot_id = bot?.value;
    if (!!offer) formObj.advertiser_id = offer?.value;
    if (!!account) formObj.tg_account_id = account?.value;
    if (!!channel) formObj.channel_id = channel?.value;
    if (!!team) formObj.rd_team_id = team?.value;
    if (!!manager) formObj.rd_manager_id = manager?.value;

    const [res, res2] = await Promise.all([
      store.salesRdAnalysisGetMainStats(formObj),
      store.salesRdAnalysisGetSoft(formObj),
    ]);

    setStats(res);
    setStatsSoft(res2);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const CustomInput = forwardRef(({value, onClick}, ref) => (
    <div
      className='form-control form-control-solid mb-lg-0 w-100 h-100'
      onClick={onClick}
      ref={ref}
      value={value}
    >
      {value}
    </div>
  ));

  return (
    <>
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-header border-0 py-5 pb-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Анализ RD</span>
            <span className='text-muted fw-semibold fs-7'>
              Анализируйте средний чек о периодам кагорта
            </span>
          </h3>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-between gap-5'>
              {(!!offer ||
                !!team ||
                !!manager ||
                !!source ||
                !!bot ||
                !!channel ||
                !!account ||
                moment(qualificationsStart).format('DD/MM/YYYY') !==
                  moment(new Date().setUTCHours(0, 0, 0)).format('DD/MM/YYYY') ||
                moment(qualificationsEnd).format('DD/MM/YYYY') !==
                  moment(new Date().setUTCHours(0, 0, 0)).format('DD/MM/YYYY') ||
                moment(depositsStart).format('DD/MM/YYYY') !==
                  moment(new Date().setUTCHours(0, 0, 0)).format('DD/MM/YYYY') ||
                moment(depositsEnd).format('DD/MM/YYYY') !==
                  moment(new Date().setUTCHours(0, 0, 0)).format('DD/MM/YYYY')) && (
                <Button
                  className='w-100 d-flex flex-nowrap align-items-center justify-content-center text-nowrap'
                  color={'btn-light'}
                  action={() => {
                    clearFilters();
                  }}
                >
                  <i className='ki-duotone ki-cross fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  Сбросить фильтры
                </Button>
              )}
              <Button
                className='w-100 d-flex flex-nowrap align-items-center justify-content-center'
                action={() => {
                  search();
                }}
              >
                <i className='bi bi-search'></i>
                Поиск
              </Button>
            </div>
          </div>
        </div>

        <div className='card-body d-flex flex-column'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className={`col-lg-6 d-flex flex-column`}>
                <span className='fw-bold fs-5 mb-1'>Квалификации</span>
                <div className={`col-lg-12 d-flex`}>
                  <div className={`col-6 fv-row mb-7 px-2`}>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>От</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={qualificationsStart}
                        onChange={(date) => setQualificationsStart(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                  <div className='col-6 fv-row mb-7 px-2'>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>До</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={qualificationsEnd}
                        onChange={(date) => setQualificationsEnd(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className={`col-lg-6 d-flex flex-column`}>
                <span className='fw-bold fs-5 mb-1'>Депозиты</span>
                <div className={`col-lg-12 d-flex`}>
                  <div className={`col-6 fv-row mb-7 px-2`}>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>От</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={depositsStart}
                        onChange={(date) => setDepositsStart(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                  <div className='col-6 fv-row mb-7 px-2'>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>До</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={depositsEnd}
                        onChange={(date) => setDepositsEnd(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {showFilters && (
              <React.Fragment>
                <div className='row'>
                  <div className={`col-lg-3 fv-row mb-7`}>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Рекламодатель'}
                      classText={'analysis-select-1'}
                      placeholder='Не выбрано'
                      options={offersOptions}
                      actions={(e) => {
                        setOffer(e);
                      }}
                      value={offer}
                      clearable={true}
                    />
                  </div>

                  <div className='col-lg-3 fv-row mb-7'>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Команда RD'}
                      classText={'analysis-select-2'}
                      placeholder='Не выбрано'
                      options={teamsOptions}
                      actions={(e) => {
                        setTeam(e);
                      }}
                      value={team}
                      clearable={true}
                    />
                  </div>

                  <div className={`col-lg-3 fv-row mb-7`}>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Менеджер RD'}
                      classText={'analysis-select-3'}
                      placeholder='Не выбрано'
                      options={managersOptions}
                      actions={(e) => {
                        setManager(e);
                      }}
                      value={manager}
                      clearable={true}
                    />
                  </div>

                  <div className={`col-lg-3 fv-row mb-7`}>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Источник'}
                      classText={'analysis-select-4'}
                      placeholder='Не выбрано'
                      options={sourcesOptions}
                      actions={(e) => {
                        setSource(e);
                      }}
                      value={source}
                      clearable={true}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className={`col-lg-4 fv-row mb-7`}>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Бот'}
                      classText={'analysis-select-5'}
                      placeholder='Не выбрано'
                      options={botsOptions}
                      actions={(e) => {
                        setBot(e);
                      }}
                      value={bot}
                      clearable={true}
                    />
                  </div>

                  <div className='col-lg-4 fv-row mb-7'>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Аккаунт'}
                      classText={'analysis-select-6'}
                      placeholder='Не выбрано'
                      options={accountsOptions}
                      actions={(e) => {
                        setAccount(e);
                      }}
                      value={account}
                      clearable={true}
                    />
                  </div>

                  <div className={`col-lg-4 fv-row mb-7`}>
                    <SelectorWithSubtitle
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '42px',
                          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                          backgroundColor:
                            mode === 'dark' ? '#15171C !important' : '#ffffff !important',
                        }),
                      }}
                      text={'Канал'}
                      classText={'analysis-select-7'}
                      placeholder='Не выбрано'
                      options={channelsOptions}
                      actions={(e) => {
                        setChannel(e);
                      }}
                      value={channel}
                      clearable={true}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className='row'>
              <div className='d-flex justify-content-center'>
                {!showFilters && (
                  <span
                    className='text-primary text-bold border-bottom border-primary cursor-pointer fw-bold'
                    onClick={() => setShowFilters(true)}
                  >
                    Все фильтры
                  </span>
                )}
                {showFilters && (
                  <span
                    className='text-gray-500 text-bold border-bottom border-gray-500 cursor-pointer fw-bold'
                    onClick={() => setShowFilters(false)}
                  >
                    Свернуть фильтры
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!stats && !!statsSoft && (
        <>
          <div className='card card-flush mt-6 mt-xl-9'>
            <div className='card-header mt-5'>
              <div className='card-title flex-column'>
                <h3 className='fw-bold mb-1'>Общая статистика</h3>
              </div>
            </div>

            <div className='card-body pt-0'>
              <div className={styles.container}>
                <div className={styles.stats}>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>Квалификации</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='28'
                          height='20'
                          fill='none'
                          viewBox='0 0 28 20'
                        >
                          <path
                            fill='#9A71F7'
                            d='M3.462 4.922c0 2.806 2.083 4.922 4.846 4.922 2.762 0 4.846-2.116 4.846-4.922C13.154 2.117 11.07 0 8.308 0 5.545 0 3.462 2.117 3.462 4.922Zm20.076.224a1 1 0 0 0-1-1h-.769a1 1 0 0 0-1 1v3.219h-3.154a1 1 0 0 0-1 1v.813a1 1 0 0 0 1 1h3.154v3.218a1 1 0 0 0 1 1h.77a1 1 0 0 0 1-1v-3.219h3.153a1 1 0 0 0 1-1v-.812a1 1 0 0 0-1-1h-3.154v-3.22ZM2.77 19.616h12.846a1 1 0 0 0 1-1v-.407c0-3.877-3.105-7.031-6.923-7.031h-2.77C3.106 11.178 0 14.332 0 18.209v.406a1 1 0 0 0 1 1h1.77Z'
                          />
                        </svg>
                      </span>
                      <span className={classNames(styles.param)}>
                        {formatNumber(stats?.count_qualifications)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Уникальные депозиты
                    </span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='20'
                          fill='none'
                          viewBox='0 0 25 20'
                        >
                          <path
                            fill='#F5C51D'
                            d='M22.5 0h-20A2.503 2.503 0 0 0 0 2.5v15C0 18.879 1.121 20 2.5 20h20c1.379 0 2.5-1.121 2.5-2.5v-15C25 1.121 23.879 0 22.5 0ZM8.394 5c1.438 0 2.5 1.061 2.5 2.5S9.832 10 8.394 10c-1.439 0-2.5-1.061-2.5-2.5S6.954 5 8.394 5Zm4.643 10H3.75v-.581c0-1.716 2.095-3.482 4.644-3.482s4.643 1.766 4.643 3.482V15Zm8.213-1.25h-5v-2.5h5v2.5Zm0-5H15v-2.5h6.25v2.5Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumber(stats?.count_deposits_unique)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Сумма депозитов
                    </span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='20'
                          fill='none'
                          viewBox='0 0 26 20'
                        >
                          <path
                            fill='#63D48A'
                            d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumberToCash(stats?.amounts_deposits)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Кол-во депозитов
                    </span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='18'
                          fill='none'
                          viewBox='0 0 25 18'
                        >
                          <path
                            fill='#58A0F5'
                            d='M16.25 7.5H25V10h-8.75V7.5Zm1.25 5H25V15h-7.5v-2.5ZM15 2.5h10V5H15V2.5Zm-12.5 15H15v-1.25A6.257 6.257 0 0 0 8.75 10h-2.5A6.257 6.257 0 0 0 0 16.25v1.25h2.5Zm5-8.75c2.494 0 4.375-1.881 4.375-4.375C11.875 1.881 9.994 0 7.5 0 5.006 0 3.125 1.881 3.125 4.375c0 2.494 1.881 4.375 4.375 4.375Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>{formatNumber(stats?.count_deposits)}</span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>Средний чек</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='23'
                          fill='none'
                          viewBox='0 0 24 23'
                        >
                          <path
                            fill='#FF6D04'
                            d='M21 4.667h-3.5V2.333A2.336 2.336 0 0 0 15.167 0h-7a2.336 2.336 0 0 0-2.334 2.333v2.334h-3.5A2.336 2.336 0 0 0 0 7v3.5h23.333V7A2.336 2.336 0 0 0 21 4.667ZM8.167 2.333h7v2.334h-7V2.333ZM14 14H9.333v-2.333H0v8.166a2.336 2.336 0 0 0 2.333 2.334H21a2.336 2.336 0 0 0 2.333-2.334v-8.166H14V14Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumberToCash(stats?.average_bill)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>High rollers</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='26'
                          fill='none'
                          viewBox='0 0 26 26'
                        >
                          <g fill='#3CF9DB'>
                            <path d='M13 2.167C7.027 2.167 2.167 7.027 2.167 13c0 5.974 4.86 10.833 10.833 10.833 5.974 0 10.833-4.86 10.833-10.833S18.973 2.167 13 2.167Zm0 18.416c-4.18 0-7.583-3.401-7.583-7.583S8.819 5.417 13 5.417c4.18 0 7.583 3.401 7.583 7.583S17.181 20.583 13 20.583Z' />
                            <path d='M13 7.583A5.423 5.423 0 0 0 7.583 13 5.423 5.423 0 0 0 13 18.417 5.423 5.423 0 0 0 18.417 13 5.423 5.423 0 0 0 13 7.583Zm0 7.584A2.169 2.169 0 0 1 10.833 13 2.17 2.17 0 0 1 13 10.833 2.17 2.17 0 0 1 15.167 13 2.169 2.169 0 0 1 13 15.167Z' />
                          </g>
                        </svg>
                      </span>
                      <span className={styles.param}>{formatNumber(stats?.high_rollers)}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(styles.circleContainer, mode === 'dark' && styles._dark)}
                >
                  <span className={styles.title}>Активность</span>
                  <svg width='200' height='100' viewBox='-10 -10 220 120'>
                    {/* Основний напівкруг */}
                    <path
                      d='M 0,100 A 100,100 0 0,1 200,100'
                      stroke='#E7E7E7'
                      strokeWidth='20'
                      fill='none'
                      strokeLinecap='round'
                    />

                    {/* Заповнений напівкруг */}
                    <path
                      d='M 0,100 A 100,100 0 0,1 200,100'
                      stroke='rgba(53, 202, 106, 1)'
                      strokeWidth='20'
                      fill='none'
                      strokeDasharray={(200 / 2) * 3.14}
                      strokeDashoffset={(200 / 2) * 3.14 * (1 - (stats?.activity || 0) / 100)}
                      strokeLinecap='round'
                      transform='rotate(0, 150, 150)'
                    />
                  </svg>
                  <div className={styles.statContainer}>
                    <span className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                      {formatNumber2(stats?.activity)}%
                    </span>
                  </div>
                  <p className={styles.text}>
                    Процент квалификаций, которые <br /> сделали депозит
                  </p>
                  <div className={styles.markers}>
                    <span>
                      <span className={styles.marker} style={{backgroundColor: '#E7E7E7'}} />
                      Квалификации
                    </span>
                    <span>
                      <span
                        className={styles.marker}
                        style={{backgroundColor: 'rgba(53, 202, 106, 1)'}}
                      />
                      Уникальные депозиты
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush mt-6 mt-xl-9'>
            <div className='card-header mt-5'>
              <div className='card-title flex-column'>
                <h3 className='fw-bold mb-1'>SOFT</h3>
              </div>
            </div>

            <div className='card-body pt-0'>
              <div className={styles.container}>
                <div className={styles.stats}>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>Активации</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='28'
                          height='20'
                          fill='none'
                          viewBox='0 0 28 20'
                        >
                          <path
                            fill='#9A71F7'
                            d='M3.462 4.922c0 2.806 2.083 4.922 4.846 4.922 2.762 0 4.846-2.116 4.846-4.922C13.154 2.117 11.07 0 8.308 0 5.545 0 3.462 2.117 3.462 4.922Zm20.076.224a1 1 0 0 0-1-1h-.769a1 1 0 0 0-1 1v3.219h-3.154a1 1 0 0 0-1 1v.813a1 1 0 0 0 1 1h3.154v3.218a1 1 0 0 0 1 1h.77a1 1 0 0 0 1-1v-3.219h3.153a1 1 0 0 0 1-1v-.812a1 1 0 0 0-1-1h-3.154v-3.22ZM2.77 19.616h12.846a1 1 0 0 0 1-1v-.407c0-3.877-3.105-7.031-6.923-7.031h-2.77C3.106 11.178 0 14.332 0 18.209v.406a1 1 0 0 0 1 1h1.77Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumber(statsSoft?.count_activations)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>Сигналы</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='28'
                          height='28'
                          fill='none'
                          viewBox='0 0 28 28'
                        >
                          <path
                            fill='#F5C51D'
                            d='M7.895 14.659c2.625 0 4.605-2.023 4.605-4.705 0-2.683-1.98-4.706-4.605-4.706-2.625 0-4.606 2.023-4.606 4.706 0 2.682 1.98 4.705 4.606 4.705Zm1.316 1.274H6.579C2.95 15.934 0 18.95 0 22.657V23a1 1 0 0 0 1 1h13.79a1 1 0 0 0 1-1v-.344c0-3.707-2.952-6.722-6.58-6.722ZM22.215 2.76c-.357-.433-1.006-.43-1.398-.03l-.462.472c-.38.389-.375 1.007-.037 1.432a9.475 9.475 0 0 1 2.05 5.922c0 2.181-.723 4.25-2.05 5.921-.338.426-.343 1.044.037 1.433l.462.471c.392.401 1.04.403 1.398-.029C24.018 16.172 25 13.441 25 10.556c0-2.885-.982-5.616-2.785-7.796Zm-3.446 4.378c-.32-.45-.961-.446-1.352-.055l-.235.235c-.39.391-.373 1.021-.108 1.506.278.507.426 1.08.426 1.676 0 .596-.148 1.168-.426 1.676-.265.485-.283 1.115.108 1.506l.235.235c.39.391 1.033.396 1.352-.055a5.79 5.79 0 0 0 1.064-3.362 5.79 5.79 0 0 0-1.064-3.362Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumber(statsSoft?.count_activations_signals)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Сумма депозитов
                    </span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='20'
                          fill='none'
                          viewBox='0 0 26 20'
                        >
                          <path
                            fill='#63D48A'
                            d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumberToCash(statsSoft?.amounts_deposits)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Кол-во депозитов
                    </span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='18'
                          fill='none'
                          viewBox='0 0 25 18'
                        >
                          <path
                            fill='#58A0F5'
                            d='M16.25 7.5H25V10h-8.75V7.5Zm1.25 5H25V15h-7.5v-2.5ZM15 2.5h10V5H15V2.5Zm-12.5 15H15v-1.25A6.257 6.257 0 0 0 8.75 10h-2.5A6.257 6.257 0 0 0 0 16.25v1.25h2.5Zm5-8.75c2.494 0 4.375-1.881 4.375-4.375C11.875 1.881 9.994 0 7.5 0 5.006 0 3.125 1.881 3.125 4.375c0 2.494 1.881 4.375 4.375 4.375Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumber(statsSoft?.count_deposits)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>Средний чек</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='23'
                          fill='none'
                          viewBox='0 0 24 23'
                        >
                          <path
                            fill='#FF6D04'
                            d='M21 4.667h-3.5V2.333A2.336 2.336 0 0 0 15.167 0h-7a2.336 2.336 0 0 0-2.334 2.333v2.334h-3.5A2.336 2.336 0 0 0 0 7v3.5h23.333V7A2.336 2.336 0 0 0 21 4.667ZM8.167 2.333h7v2.334h-7V2.333ZM14 14H9.333v-2.333H0v8.166a2.336 2.336 0 0 0 2.333 2.334H21a2.336 2.336 0 0 0 2.333-2.334v-8.166H14V14Z'
                          />
                        </svg>
                      </span>
                      <span className={styles.param}>
                        {formatNumberToCash(statsSoft?.average_bill)}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>High rollers</span>
                    <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='26'
                          fill='none'
                          viewBox='0 0 26 26'
                        >
                          <g fill='#3CF9DB'>
                            <path d='M13 2.167C7.027 2.167 2.167 7.027 2.167 13c0 5.974 4.86 10.833 10.833 10.833 5.974 0 10.833-4.86 10.833-10.833S18.973 2.167 13 2.167Zm0 18.416c-4.18 0-7.583-3.401-7.583-7.583S8.819 5.417 13 5.417c4.18 0 7.583 3.401 7.583 7.583S17.181 20.583 13 20.583Z' />
                            <path d='M13 7.583A5.423 5.423 0 0 0 7.583 13 5.423 5.423 0 0 0 13 18.417 5.423 5.423 0 0 0 18.417 13 5.423 5.423 0 0 0 13 7.583Zm0 7.584A2.169 2.169 0 0 1 10.833 13 2.17 2.17 0 0 1 13 10.833 2.17 2.17 0 0 1 15.167 13 2.169 2.169 0 0 1 13 15.167Z' />
                          </g>
                        </svg>
                      </span>
                      <span className={styles.param}>{formatNumber(statsSoft?.high_rollers)}</span>
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.circleContainer, mode === 'dark' && styles._dark)}>
                  <span className={styles.title}>Активации</span>
                  <svg width='200' height='100' viewBox='-10 -10 220 120'>
                    {/* Основний напівкруг */}
                    <path
                      d='M 0,100 A 100,100 0 0,1 200,100'
                      stroke='#E7E7E7'
                      strokeWidth='20'
                      fill='none'
                      strokeLinecap='round'
                    />

                    {/* Заповнений напівкруг */}
                    <path
                      d='M 0,100 A 100,100 0 0,1 200,100'
                      stroke='rgba(243, 69, 112, 1)'
                      strokeWidth='20'
                      fill='none'
                      strokeDasharray={(200 / 2) * 3.14}
                      strokeDashoffset={(200 / 2) * 3.14 * (1 - (statsSoft?.activity || 0) / 100)}
                      strokeLinecap='round'
                      transform='rotate(0, 150, 150)'
                    />
                  </svg>
                  <div className={styles.statContainer}>
                    <span className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                      {formatNumber2(statsSoft?.activity)}%
                    </span>
                  </div>
                  <p className={styles.text}>
                    Анализируйте средний чек <br /> о периодам кагорта
                  </p>
                  <div className={styles.markers}>
                    <span>
                      <span className={styles.marker} style={{backgroundColor: '#E7E7E7'}} />
                      Квалификации
                    </span>
                    <span>
                      <span
                        className={styles.marker}
                        style={{backgroundColor: 'rgba(243, 69, 112, 1)'}}
                      />
                      Активации SOFT
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(Analysis);
