import {api} from './api';

const myAccountAPI = {
  async getMainData(token) {
    try {
      const response = await api.get(`/myAccount/getMainData?token=${token}`);
      
      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getData(token) {
    try {
      const response = await api.get(`/myAccount/getData?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getAuthData(token) {
    try {
      const response = await api.get(`/myAccount/getAuthData?token=${token}&type=1`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getSettings(token) {
    try {
      const response = await api.get(`/myAccount/getSettings?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async updateSettings(token, data) {
    try {
      const response = await api.post(`/myAccount/updateSettings`, {
        token,
        is_notification: data.is_notification,
        is_payment: data.is_payment,
      });

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getWallets(token) {
    try {
      const response = await api.get(`/myAccount/getWallets?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async addWallet(data) {
    try {
      const response = await api.post(`/myAccount/addWallet`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async updateWallet(data) {
    try {
      const response = await api.post(`/myAccount/updateWallet`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getTransactions(token) {
    try {
      const response = await api.get(`/myAccount/getTransactions?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getWallet(token) {
    try {
      const response = await api.get(`/myAccount/getWallet?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async deleteWallet(token, id) {
    try {
      const response = await api.post(`/myAccount/deleteWallet`, { token, id });
      
      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default myAccountAPI;
