/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import {CreateAppModal} from '../../../../_metronic/partials';
import {Button} from '../../../shared/Buttons';
import Warning from '../../../modules/Warning';

const users = [
  {name: 'Елена', position: 'PM'},
  {name: 'Роман', position: 'PM'},
  {name: 'Дмитрий', position: 'PM'},
  {name: 'Общие', position: 'PM'},
  {name: 'TruffHunt', position: ''},
];
const categories = [
  {category: 'Зарплатный фонд'},
  {category: 'Блогеры'},
  {category: 'Отдел продаж'},
  {category: 'Facebook'},
  {category: 'Другое'},
];

const AddCosts = ({setWarning, warning, setSave, save, show}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState(true);

  return (
    <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
      <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
        <div className='stepper-nav ps-lg-10'>
          <div className={`stepper-item ${step === 1 && 'current'}`}>
            <div className='stepper-wrapper'>
              <div className='stepper-icon w-40px h-40px'>
                <i className='ki-duotone ki-check stepper-check fs-2'></i>
                <span className='stepper-number'>1</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>Куда</h3>
                <div className='stepper-desc'>Кому идут расходы</div>
              </div>
            </div>

            <div className='stepper-line h-40px'></div>
          </div>

          <div className={`stepper-item ${step === 2 && 'current'}`}>
            <div className='stepper-wrapper'>
              <div className='stepper-icon w-40px h-40px'>
                <i className='ki-duotone ki-check stepper-check fs-2'></i>
                <span className='stepper-number'>2</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>Финансы</h3>
                <div className='stepper-desc'>Сума, категория</div>
              </div>
            </div>

            <div className='stepper-line h-40px'></div>
          </div>

          <div className={`stepper-item ${step === 3 && 'current'}`}>
            <div className='stepper-wrapper'>
              <div className='stepper-icon w-40px h-40px'>
                <i className='ki-duotone ki-check stepper-check fs-2'></i>
                <span className='stepper-number'>3</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>Сверка</h3>
                <div className='stepper-desc'>Подтвердите данные</div>
              </div>
            </div>

            {/* <div className='stepper-line h-40px'></div> */}
          </div>
        </div>
      </div>

      <div className='flex-row-fluid py-lg-5 px-lg-15'>
        <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
          <div className='current' data-kt-stepper-element='content'>
            {step === 1 && (
              <div className='w-100'>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                    <span className='required'>Кому идут расходы</span>
                  </label>

                  <div className='fv-row'>
                    {users.map((el, i) => {
                      return (
                        <label className='d-flex flex-stack mb-5 cursor-pointer'>
                          <span className='d-flex align-items-center me-2'>
                            <span className='symbol symbol-50px me-6'>
                              <div className='symbol-label'>
                                <img src={imagePlug} alt='Emma Smith' className='w-100' />
                              </div>
                            </span>

                            <span className='d-flex flex-column'>
                              <span className='fw-bold fs-6'>
                                {el.name} {el.position}
                              </span>
                            </span>
                          </span>

                          <span className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='radio'
                              checked={el.name === data?.user?.name && true}
                              onChange={() => {
                                const newData = {...data};
                                newData.user = el;
                                setData(newData);
                                setError(false);
                              }}
                            />
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                {!data.category && error && (
                  <div className='fv-plugins-message-container invalid-feedback'>
                    Выберете кому идут расходы
                  </div>
                )}
              </div>
            )}
            {step === 2 && (
              <div className='w-100'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Финансы (Укажите сумму выделенную)</span>
                    <span
                      className='ms-1'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    >
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </label>

                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    placeholder='Сумма'
                    value={data?.sum || ''}
                    onChange={(e) => {
                      const newData = {...data, sum: e.target.value};
                      setData(newData);
                      if (data.sum.length > 0) {
                        setError(false);
                      }
                    }}
                  />
                  {!data.sum && error && (
                    <div className='fv-plugins-message-container invalid-feedback'>
                      Укажите суму
                    </div>
                  )}
                </div>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                    <span className='required'>Выберите категорию трат</span>
                    <span className='ms-1' data-bs-toggle='tooltip' title='Выберете категорию'>
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </label>

                  <div className='fv-row'>
                    {categories.map((el, i) => {
                      return (
                        <label className='d-flex flex-stack mb-5 cursor-pointer'>
                          <span className='d-flex align-items-center me-2'>
                            <span className='symbol symbol-50px me-6'>
                              {el.category === 'Зарплатный фонд' && (
                                <span className='symbol-label bg-light-success'>
                                  <i className='ki-duotone ki-bill fs-1 text-success'>
                                    <i className='path1'></i>
                                    <i className='path2'></i>
                                    <i className='path3'></i>
                                    <i className='path4'></i>
                                    <i className='path5'></i>
                                    <i className='path6'></i>
                                  </i>
                                </span>
                              )}
                              {el.category === 'Блогеры' && (
                                <span className='symbol-label bg-light-warning'>
                                  <i className='ki-duotone ki-profile-user fs-1 text-warning'>
                                    <i className='path1'></i>
                                    <i className='path2'></i>
                                    <i className='path3'></i>
                                    <i className='path4'></i>
                                  </i>
                                </span>
                              )}
                              {el.category === 'Отдел продаж' && (
                                <span className='symbol-label bg-light-info'>
                                  <i className='ki-duotone ki-abstract-26 fs-1 text-info'>
                                    <i className='path1'></i>
                                    <i className='path2'></i>
                                  </i>
                                </span>
                              )}
                              {el.category === 'Facebook' && (
                                <span className='symbol-label bg-light-primary'>
                                  <i className='ki-duotone ki-facebook fs-1 text-primary'>
                                    <i className='path1'></i>
                                    <i className='path2'></i>
                                  </i>
                                </span>
                              )}
                              {el.category === 'Другое' && (
                                <span className='symbol-label bg-light-danger'>
                                  <i className='ki-duotone ki-dots-circle-vertical fs-1 text-danger'>
                                    <i className='path1'></i>
                                    <i className='path2'></i>
                                    <i className='path3'></i>
                                    <i className='path4'></i>
                                  </i>
                                </span>
                              )}
                            </span>

                            <span className='d-flex flex-column'>
                              {el.category === 'Зарплатный фонд' && (
                                <>
                                  <span className='fw-bold fs-6'>{el.category}</span>
                                  <span className='fs-7 text-muted'>
                                    Траты связанные с зарпланым фондом
                                  </span>
                                </>
                              )}
                              {el.category === 'Блогеры' && (
                                <>
                                  <span className='fw-bold fs-6'>{el.category}</span>
                                  <span className='fs-7 text-muted'>
                                    Траты связанные с блогерами
                                  </span>
                                </>
                              )}
                              {el.category === 'Отдел продаж' && (
                                <>
                                  <span className='fw-bold fs-6'>{el.category}</span>
                                  <span className='fs-7 text-muted'>
                                    Траты связанные с отделом продаж
                                  </span>
                                </>
                              )}
                              {el.category === 'Facebook' && (
                                <>
                                  <span className='fw-bold fs-6'>{el.category}</span>
                                  <span className='fs-7 text-muted'>
                                    Траты связанные с Facebook
                                  </span>
                                </>
                              )}
                              {el.category === 'Другое' && (
                                <>
                                  <span className='fw-bold fs-6'>{el.category}</span>
                                  <span className='fs-7 text-muted'>
                                    Траты связанные с другими расходами
                                  </span>
                                </>
                              )}
                            </span>
                          </span>

                          <span className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='radio'
                              checked={el.category === data?.category && true}
                              onChange={() => {
                                const newData = {...data, category: el.category};
                                setData(newData);
                                setError(false);
                              }}
                            />
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                {!data.category && error && (
                  <div className='fv-plugins-message-container invalid-feedback'>
                    Выберете категорию трат
                  </div>
                )}
              </div>
            )}
            {step === 3 && (
              <div className='w-100'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Ссылка на транзакцию</span>
                    <span className='ms-1' data-bs-toggle='tooltip' title='Ссылка'>
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    placeholder='Ссылка'
                    onChange={(e) => {
                      const newData = {...data, link: e.target.value};
                      setData(newData);
                    }}
                  />
                </div>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                    <span className='required'>Выбраные данные</span>
                    <span className='ms-1' data-bs-toggle='tooltip' title='Выберете категорию'>
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </label>

                  <div className='fv-row'>
                    <label className='d-flex flex-stack mb-5 cursor-pointer'>
                      <span className='d-flex align-items-center me-2'>
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-warning'>
                            <i className='ki-duotone ki-profile-user fs-1 text-warning'>
                              <i className='path1'></i>
                              <i className='path2'></i>
                              <i className='path3'></i>
                              <i className='path4'></i>
                            </i>
                          </span>
                        </span>
                        <span className='d-flex flex-column'>
                          <span className='fw-bold fs-6'>Кому идут расходы</span>
                          <span className='fs-7 text-muted'>
                            {data?.user?.name} {data?.user?.position}
                          </span>
                        </span>
                      </span>
                    </label>
                    <label className='d-flex flex-stack mb-5 cursor-pointer'>
                      <span className='d-flex align-items-center me-2'>
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-success'>
                            <i className='ki-duotone ki-bill fs-1 text-success'>
                              <i className='path1'></i>
                              <i className='path2'></i>
                              <i className='path3'></i>
                              <i className='path4'></i>
                              <i className='path5'></i>
                              <i className='path6'></i>
                            </i>
                          </span>
                        </span>
                        <span className='d-flex flex-column'>
                          <span className='fw-bold fs-6'>Cумма</span>
                          <span className='fs-7 text-muted'>${data.sum}</span>
                        </span>
                      </span>
                    </label>

                    <label className='d-flex flex-stack mb-5 cursor-pointer'>
                      <span className='d-flex align-items-center me-2'>
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-info'>
                            <i className='ki-duotone ki-abstract-26 fs-1 text-info'>
                              <i className='path1'></i>
                              <i className='path2'></i>
                            </i>
                          </span>
                        </span>
                        <span className='d-flex flex-column'>
                          <span className='fw-bold fs-6'>Категория трат</span>
                          <span className='fs-7 text-muted'>{data.category}</span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-stack pt-10'>
            <div className='me-2'>
              {step > 1 && (
                <Button
                  action={() => {
                    if (step > 1) {
                      setStep(step - 1);
                    }
                  }}
                >
                  <>
                    <i className='ki-duotone ki-arrow-left fs-3 me-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    Назад
                  </>
                </Button>
              )}
            </div>
            <div>
              {step !== 3 && (
                <Button
                  action={(e) => {
                    e.preventDefault();
                    if (step < 3) {
                      if (!data.user && step == 1) {
                        setError(true);
                      } else if ((!data.category || !data.sum) && step == 2) {
                        setError(true);
                      } else {
                        setError(false);
                        setStep(step + 1);
                      }
                    }
                  }}
                >
                  <>
                    Далее
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </>
                </Button>
              )}
              {step === 3 && (
                <Button
                  action={() => {
                    setSave(true);
                    show(false);
                  }}
                >
                  Подтвертить
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </div>
  );
};

export default AddCosts;
