/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers';
import {useLayout} from '../../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  componentIcon?: any;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  componentIcon,
}) => {
  const {pathname} = useLocation();
  const isActive = checkIsActive(pathname, to);
  const {config} = useLayout();
  const {app} = config;

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}
        {componentIcon && <span className='menu-icon'>{componentIcon}</span>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
};
const SidebarMenuLink: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation();
  const isActive = checkIsActive(pathname, to);
  const {config} = useLayout();
  const {app} = config;

  return (
    <div className='menu-item'>
      <a className={clsx('menu-link without-sub', {active: isActive})} href={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}
        <span className='menu-title'>{title}</span>
      </a>
      {children}
    </div>
  );
};

export {SidebarMenuItem, SidebarMenuLink};
