/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';

const Warning = ({
  buttons,
  text = 'Вы уверены что хотите выйти не сохранив?',
  icon = (
    <span className='symbol-label'>
      <i className='bi bi-exclamation-circle fs-5x text-warning'></i>
    </span>
  ),
}) => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {icon}
      <div
        style={{
          maxHeight: '200px',
          overflow: 'auto',
          fontWeight: 'normal',
          fontSize: '1.1rem',
          color: '#3F4254',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          textAlign: 'center',
        }}
      >
        {text}
      </div>
      <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
        {buttons.map((el, i) => {
          return (
            <button className={`btn ${el.class}`} onClick={el.action} key={i}>
              {el.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Warning;
