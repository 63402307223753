/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useThemeMode} from '../../../../../../_metronic/partials';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import css from '../../../../../../styles/TOPManagers.module.scss';
import InputSearch from '../../../../../shared/InputSearch';
import Item from './Item';

const List = ({data, withoutOutsiders}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [list, setList] = useState();
  const [searchUser, setSearchUser] = useState([]);
  const [top, setTop] = useState([]);
  const [bad, setBad] = useState([]);
  const [outsidersIds, setOutsidersIds] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    const arr = [...data];

    if (arr.length <= 3) {
      setList(data);
      setSearchUser(data);
      return setTop(arr.map((obj) => obj?.id));
    }

    const cut = withoutOutsiders?.length === 4 ? -1 : withoutOutsiders?.length === 5 ? -2 : -3;

    const minObjects = withoutOutsiders.slice(0, 3);
    const maxObjects = withoutOutsiders.slice(cut);

    const minIds = minObjects.map((obj) => obj?.id);
    const maxIds = maxObjects.map((obj) => obj?.id);

    setTop(minIds);
    setBad(maxIds);

    setList(data);
    setSearchUser(data);
  }, [data]);

  return (
    <div className={css.container}>
      <section className={css.header}>
        <InputSearch
          title={'Поиск...'}
          array={list}
          newArray={setSearchUser}
          searchParameters={['id', 'full_name']}
        />
        <div className='text-end mb-5 mb-sm-5 mb-md-0' style={{marginLeft: '20px'}}>
          <button
            type='button'
            className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <span>Рейтинг</span> <i className='ki-duotone ki-down fs-5 ms-1'></i>
          </button>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              <span
                className='menu-link px-3'
                onClick={() => {
                  const sort = list.sort((a, b) => a.place - b.place);
                  setSearchUser([...sort]);
                }}
              >
                Сначала лучшие
              </span>
            </div>
            <div className='menu-item px-3'>
              <span
                className='menu-link px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => {
                  const sort = list.sort((a, b) => b.place - a.place);
                  setSearchUser([...sort]);
                }}
              >
                Сначала худшие
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className={css.listContainer}>
        <ul className={css.list}>
          {searchUser?.map((el, i) => {
            return (
              <React.Fragment key={`${el.id}`}>
                <Item data={el} top={top} bad={bad} />
              </React.Fragment>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

export default observer(List);
