/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef} from 'react';
import ApexCharts from 'apexcharts';
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../../../_metronic/partials';
import {formatNumber2} from '../../../functions/formatNumber';

const Chart = ({className, title, subTitle, data = []}) => {
  const chartRef = useRef(null);
  const {mode} = useThemeMode();
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subTitle}</span>
        </h3>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_6_chart' style={{height: '500px'}}></div>
      </div>
    </div>
  );
};

export {Chart};

function getChartOptions(height, data) {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');

  const baseColor = getCSSVariableValue('--bs-primary');
  const baseLightColor = getCSSVariableValue('--bs-primary-light');
  const secondaryColor = getCSSVariableValue('--bs-info');

  return {
    series: [
      // {
      //   name: 'Кликов',
      //   type: 'bar',
      //   data: data?.map((item) => item.hour_count_click),
      // },
      {
        name: 'СTR',
        type: 'bar',
        data: data?.map((item) => formatNumber2(item.ctr)),
      },
      // {
      //   name: 'Доставлено',
      //   type: 'area',
      //   data: data?.map((item) => item.hour_count_delivered),
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      stacked: true,
      height: 500,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '40%',
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.map((item) => item.hour),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      // max: () => {
      //   let max = 0;
      //   data?.forEach((item) => {
      //     let numMax = Math.max(
      //       Number(item.hour_count_delivered),
      //       Number(item.hour_count_click),
      //       Number(item.hour_count_show)
      //     );

      //     if (numMax >= max) {
      //       max = numMax;
      //     }
      //     console.log(max)
      //     return max;
      //   });
      // },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
    },
    colors: [baseColor, secondaryColor, baseLightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
}
