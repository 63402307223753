/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import classNames from 'classnames';
import moment from 'moment';
import {Button} from '../../../../shared/Buttons';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {toast} from 'react-toastify';

const AddMethod = ({
  setArrLength,
  setCryptos,
  arrLength,
  setCards,
  cards,
  setEditCard,
  editCard,
}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [errorCardNumber, setErrorCardNumber] = useState(false);
  const [data, setData] = useState({
    currency: 'UAH',
    bank_name: '',
    receiver_name: '',
    details: '',
    type: 'bank',
    is_active: arrLength === 0 ? true : false,
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!!editCard) {
      setShowCreateAppModal(true);
      setData(editCard);
    }
  }, [editCard]);

  useEffect(() => {
    const month = [];
    const years = [];
    const currentYear = moment().year();

    for (let i = 1; i <= 12; i++) {
      month.push(i);
    }

    for (let year = 2000; year <= currentYear; year++) {
      years.push(year);
    }

    setYearOptions(years);
    setMonthOptions(month);
  }, []);

  const handleCardNumberChange = (value) => {
    if (errorCardNumber) {
      setErrorCardNumber(false);
    }
    const input = value.replace(/\D/g, ''); // Видаляємо всі нецифрові символи
    const formattedInput = input.replace(/(\d{4})(?=\d)/g, '$1 '); // Додаємо пробіли кожні 4 цифри
    if (input.length <= 16) {
      return formattedInput;
    }
  };

  const verifyErrorCurdNumber = () => {
    if (data.details.length !== 0 && data.details.length !== 19) {
      setErrorCardNumber(true);
    }
  };

  const changeData = (e) => {
    let {name, value, checked} = e.target;
    const newData = {...data};

    if (name === 'is_active') {
      newData[name] = checked;
      return setData(newData);
    }

    if (name === 'details') {
      value = handleCardNumberChange(value);
    }

    newData[name] = value;
    setData(newData);
  };

  const save = async () => {
    for (const key in data) {
      if (key === 'delete_date') {
        continue;
      }

      if (data[key] === undefined || data[key] === null || data[key] === '') {
        return setError(true);
      }
    }

    if (errorCardNumber) {
      return setError(true);
    }

    setError(false);

    if (!!editCard) {
      if (data.is_active === editCard.is_active) {
        return;
      }

      const result = await store.updateWallet(data);

      notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

      return await updatedArray();
    }

    const result = await store.addWallet(data);

    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

    return await updatedArray();
  };

  const updatedArray = async () => {
    const wallets = await store.getWallets();

    setArrLength(wallets.length);

    const newCards = [];
    const newCryptos = [];

    wallets.forEach((el) => {
      el.type === 'bank' ? newCards.push(el) : newCryptos.push(el);
    });
    setCards(newCards);
    setCryptos(newCryptos);

    setData({
      currency: 'UAH',
      bank_name: '',
      receiver_name: '',
      details: '',
      type: 'bank',
      is_active: false,
    });
    setEditCard(null);

    return setShowCreateAppModal(false);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
        }}
      >
        Добавить
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          setEditCard(null);
          setData({
            currency: 'UAH',
            bank_name: '',
            receiver_name: '',
            details: '',
            type: 'bank',
            is_active: arrLength === 0 ? true : false,
          });
          setShowCreateAppModal(false);
        }}
        title={'Добавление карты'}
      >
        <div data-kt-stepper-element='content'>
          <div className='w-100'>
            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>ФИО карты</span>
                <span className='ms-1' data-bs-toggle='tooltip' title='Укажите имя владельца карты'>
                  <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </span>
              </label>

              <input
                type='text'
                className={classNames(
                  'form-control form-control-solid',
                  error && !data?.receiver_name && 'border border-danger'
                )}
                placeholder='Иван Иваненко'
                disabled={!!editCard ? true : false}
                name='receiver_name'
                value={data?.receiver_name || ''}
                onChange={changeData}
              />
              {error && !data?.receiver_name && (
                <label className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Заполните поле
                </label>
              )}
            </div>

            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='required fs-6 fw-semibold form-label mb-2'>Номер карты</label>

              <div className='position-relative'>
                <input
                  className={classNames(
                    'form-control form-control-solid',
                    errorCardNumber && 'border border-danger',
                    error && !data.details && 'border border-danger'
                  )}
                  name='details'
                  type='text'
                  disabled={!!editCard ? true : false}
                  maxLength='19' // 16 цифр + 3 пробіли
                  value={data?.details || ''}
                  onChange={changeData}
                  onBlur={verifyErrorCurdNumber}
                  placeholder='Введите номер карты'
                />
                <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                  <img src='assets/media/svg/card-logos/visa.svg' alt='' className='h-25px' />
                  <img src='assets/media/svg/card-logos/mastercard.svg' alt='' className='h-25px' />
                  <img
                    src='assets/media/svg/card-logos/american-express.svg'
                    alt=''
                    className='h-25px'
                  />
                </div>
                {errorCardNumber && (
                  <label className='fs-6 fw-semibold form-label mb-2 text-danger'>
                    Не верный формат номера карты
                  </label>
                )}
                {error && !data?.details && (
                  <label className='fs-6 fw-semibold form-label mb-2 text-danger'>
                    Заполните поле
                  </label>
                )}
              </div>
            </div>

            {/* <div className='row mb-10'>
              <div className='col-md-8 fv-row'>
                <label className='required fs-6 fw-semibold form-label mb-2'>
                  Срок действия карты
                </label>

                <div className='row fv-row'>
                  <div className='col-6'>
                    <select
                      name='card_month'
                      className={classNames('form-select form-select-solid')}
                      value={data.card_month}
                      onChange={changeData}
                    >
                      <option value='' disabled hidden>
                        Месяц
                      </option>
                      {monthOptions.map((el) => {
                        return (
                          <option value={el} key={el}>
                            {el}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className='col-6'>
                    <select
                      name='card_year'
                      className={classNames('form-select form-select-solid')}
                      value={data.card_year}
                      onChange={changeData}
                    >
                      <option value='' disabled hidden>
                        Год
                      </option>
                      {yearOptions.map((el) => {
                        return (
                          <option value={el} key={el}>
                            {el}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-md-4 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>CVV</span>
                  <span
                    className='ms-1'
                    data-bs-toggle='tooltip'
                    title='Укажите CVV-код вашей карты'
                  >
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </label>

                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    minLength='3'
                    maxLength='4'
                    placeholder='CVV'
                    name='card_cvv'
                    value={data.card_cvv || ''}
                    onChange={changeData}
                  />

                  <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                    <i className='ki-duotone ki-credit-cart fs-2hx'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='row mb-10'>
              <div className='col-md-8 fv-row'>
                <label className='required fs-6 fw-semibold form-label mb-2'>
                  Укажите вашь банк
                </label>

                <div className='row fv-row'>
                  <div className='col-12'>
                    <input
                      className={classNames(
                        'form-control form-control-solid',
                        error && !data?.bank_name && 'border border-danger'
                      )}
                      name='bank_name'
                      type='text'
                      disabled={!!editCard ? true : false}
                      maxLength='19' // 16 цифр + 3 пробіли
                      value={data?.bank_name || ''}
                      onChange={changeData}
                      placeholder='Название вашего банка'
                    />
                    {error && !data?.bank_name && (
                      <label className='fs-6 fw-semibold form-label mb-2 text-danger'>
                        Заполните поле
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-md-4 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Валюта</span>
                  <span className='ms-1' data-bs-toggle='tooltip' title='Укажите валюту'>
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </label>

                <div className='position-relative'>
                  <select
                    name='currency'
                    className={classNames('form-select form-select-solid')}
                    disabled={!!editCard ? true : false}
                    value={data?.currency}
                    onChange={changeData}
                  >
                    <option value='UAH'>UAH</option>
                    <option value='USD'>USD</option>
                    <option value='RUB'>RUB</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack mb-7'>
              <div className='me-5'>
                <label className='fs-6 fw-semibold form-label'>Сделайте эту карту активной</label>
                <div className='fs-7 fw-semibold text-muted'>
                  Эта карта будет использоватся по умолчанию
                </div>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='is_active'
                  disabled={arrLength === 0 || editCard?.is_active ? true : false}
                  checked={data?.is_active}
                  onChange={changeData}
                />
                <span className='form-check-label fw-semibold text-muted'></span>
              </label>
            </div>
            <div className='card-footer d-flex justify-content-end'>
              <Button action={() => save()}>Сохранить</Button>
            </div>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
};

export default observer(AddMethod);
