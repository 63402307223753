import Card from '../../../shared/Card';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';

const RoleCard = ({el, isView = false, customClass = ''}) => {
  return (
    <Card className={`card card-flush ${customClass}`}>
      <div className='card-header'>
        <div className='card-title'>
          <h2>{el?.name}</h2>
        </div>
      </div>

      <div className='card-body pt-1'>
        <div className='fw-bold text-gray-600 mb-5'>ID #{el?.id}</div>

        <div className='d-flex flex-column text-gray-600'>
          {!!el?.department && (
            <div className='d-flex align-items-center py-2'>
              <span className='bullet bg-primary me-3'></span> {el?.department?.name}
            </div>
          )}
          <div className='d-flex align-items-center py-2'>
            <span className='bullet bg-primary me-3'></span> Уровень {el?.level}
          </div>
        </div>
      </div>

      {!isView && (
        <div className='card-footer flex-wrap pt-0'>
          <Link to={`/users/roles/${el?.id}`}>
            <Button color='btn-light' className='btn-light btn-active-primary my-1 me-2'>
              Подробнее
            </Button>
          </Link>

          {/* <EditRole /> */}
        </div>
      )}
    </Card>
  );
};

export default RoleCard;
