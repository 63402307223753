/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import numeral from 'numeral';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import {useNavigate} from 'react-router-dom';
import Card from '../../../../../shared/Card';
import { formatNumberToCash, formatNumber } from '../../../../../functions/formatNumber';

const BloggerCard = ({data, romiList}) => {
  const store = useStore();
  const navigate = useNavigate();

  const viewBlogger = (id) => {
    navigate(`${id}/`);
  };
  return (
    <>
      <div
        className='col-md-6 col-xl-4'
        style={{cursor: 'pointer'}}
        onClick={() => viewBlogger(data.id)}
      >
        <Card className='card border-hover-primary'>
          <div className='card-header border-0 pt-5'>
            <div className='card-title m-0'>
              <div className='symbol symbol-50px w-50px bg-light'>
                <img
                  style={{borderRadius: '5px'}}
                  src={
                    data.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug
                  }
                  alt='#'
                />
              </div>
            </div>

            <div className='card-toolbar'>
              <span className={romiList ? `badge badge-light-${romiList.find((romi) => romi.id === data.romi_category_id).indicator}` : `badge badge-light-${data?.indicator?.indicator}`}>
                {romiList && store.roles.includes(19) ? romiList.find((romi) => romi.id === data.romi_category_id).name : data?.indicator?.name}
              </span>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='fs-3 fw-bold text-dark'>{data?.name}</div>

            <p className='text-gray-400 fw-semibold fs-5 mt-1 mb-7'>#{data?.id}</p>

            <div className='d-flex flex-wrap mb-5'>
              {!store.roles.includes(19) && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bold'>{data?.romi}%</div>
                  <div className='fw-semibold text-gray-400'>ROMI</div>
                </div>
              )}

              {!store.roles.includes(19) && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bold'>{formatNumberToCash(data?.profit)}</div>
                  <div className='fw-semibold text-gray-400'>Прибыль</div>
                </div>
              )}

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <div className='fs-6 text-gray-800 fw-bold'>{formatNumberToCash(data?.cost)}</div>
                <div className='fw-semibold text-gray-400'>Расходы</div>
              </div>

              {!store.roles.includes(19) && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bold'>{formatNumberToCash(data?.revenue)}</div>
                  <div className='fw-semibold text-gray-400'>Выручка</div>
                </div>
              )}
            </div>
            <div className='d-flex flex-wrap justify-content-between mb-5'>
              <div className='col-md-3 text-center'>
                <div className='text-gray-800 fw-bold fs-7'>
                  <span className='m-0 counted'>{formatNumber(data?.count_leads)}</span>
                </div>
                <span className='text-gray-500 fs-8 d-block fw-bold'>LEAD</span>
              </div>

              <div className='col-md-3 text-center'>
                <div className='text-gray-800 fw-bold fs-7'>
                  <span className='m-0 counted'>{formatNumber(data?.count_registrations)}</span>
                </div>
                <span className='text-gray-500 fs-8 d-block fw-bold'>REG</span>
              </div>

              <div className='col-md-3 text-center'>
                <div className='text-gray-800 fw-bold fs-7'>
                  <span className='m-0 counted'>{formatNumber(data?.count_qualifications)}</span>
                </div>
                <span className='text-gray-500 fs-8 d-block fw-bold'>CPA</span>
              </div>
            </div>

            <div
              className='h-4px w-100 bg-light mb-5'
              data-bs-toggle='tooltip'
              title='This project 50% completed'
            >
              <div
                className={romiList ? `bg-${romiList.find((romi) => romi.id === data.romi_category_id).indicator} rounded h-4px` : `bg-${data?.indicator?.indicator} rounded h-4px`}
                role='progressbar'
                style={{width: '100%'}}
                aria-valuenow='100'
                aria-valuemin='0'
                aria-valuemax='100'
              ></div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default observer(BloggerCard);
