import React, {useEffect, useState} from 'react';
import imagePlug from '../../_metronic/assets/media/user.png';
import {Link} from 'react-router-dom';
import Card from '../shared/Card';

const Departments = ({data = []}) => {
  const [countUsers, setCountUsers] = useState(0);

  useEffect(() => {
    const totalUsers = data.reduce((accumulator, currentUser) => {
      return accumulator + currentUser.count_users;
    }, 0);

    return setCountUsers(totalUsers);
  }, [data]);

  return (
    <>
      <Card className='card card-flush h-xl-100'>
        <div className='card-header py-7'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Департаменты</span>

            <span className='text-muted mt-1 fw-semibold fs-7'>
              Кол-во сотрудников: {countUsers || 0}
            </span>
          </h3>

          <div className='card-toolbar'>
            <Link to='/users/list'>
              <span className='btn btn-sm btn-light btn-active-primary'>Все сотрудники</span>
            </Link>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive' style={{height: '365px'}}>
            <table className='table table-row-gray-300 text-uppercase align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-200px'>Департамент</th>
                  <th className='min-w-100px text-end'>Сотрудники</th>
                  <th className='min-w-100px text-end'>Подробнее</th>
                </tr>
              </thead>

              <tbody>
                {data.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              src={
                                el.avatar
                                  ? `${process.env.REACT_APP_API_HOST}/${el.avatar}`
                                  : imagePlug
                              }
                              alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                              {el.name}
                            </span>

                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Департамент
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block fs-6 text-end'>
                          {el.count_users}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link to='/users/list'>
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                              <i className='ki-duotone ki-arrow-right fs-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Departments;
