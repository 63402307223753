/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import MaskedTextInput from 'react-text-mask';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import Preloader from '../../../../../modules/Preloader';
import PaginationBe from '../../../../../shared/PaginationBe';
import {SelectorWithSearch} from '../../../../../shared/Selectors';
import Calendar from '../../../../../modules/calendar/Calendar';

const Traffic = ({id}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const [prel, setPrel] = useState(false);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [action, setAction] = useState({label: 'Все', value: 2});
  const [calendarTime, setCalendarTime] = useState({
    time_config: '',
    time_start: '',
    time_end: '',
  });
  const [tc, setTc] = useState({
    time_config: '',
    time_start: '',
    time_end: '',
  });
  const [label, setLabel] = useState('');

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getTraffic();
  }, []);

  const getTraffic = async (currentPage = 1) => {
    setPrel(true);
    const getLogs = await store.trafficsGetLogs({
      manager_id: id,
      page: currentPage,
      perPage,
      is_traffic: action.value,
      time_config: tc.time_config,
      time_start: !!tc.time_start ? Math.floor(tc.time_start) : '',
      time_end: !!tc.time_end ? Math.floor(tc.time_end) : '',
    });

    setLogs(getLogs.rows);
    setTotal(getLogs.total);
    setPrel(false);
  };

  const filterActions = async (e) => {
    setAction(e);
    setPrel(true);
    const getLogs = await store.trafficsGetLogs({
      manager_id: id,
      page: 1,
      perPage,
      is_traffic: e.value,
      time_config: tc.time_config,
      time_start: !!tc.time_start ? Math.floor(tc.time_start) : '',
      time_end: !!tc.time_end ? Math.floor(tc.time_end) : '',
    });

    setLogs(getLogs.rows);
    setTotal(getLogs.total);
    setPrel(false);
  };

  const handleCustom = async (obj, forCalendar) => {
    setPrel(true);
    const {time_start, time_end} = obj;

    let start = moment.utc(time_start).format('DD.MM.YYYY');
    let end = !!time_end ? moment.utc(time_end).format('DD.MM.YYYY') : '';

    if (start !== end) {
      start = moment.utc(time_start).format('DD.MM.YYYY');
      setLabel(`${start} - ${end}`);
    } else {
      setLabel(start);
    }

    setCalendarTime({
      time_start: forCalendar.startDate,
      time_end: forCalendar.endDate,
      sendStart: obj.time_start,
      sendEnd: obj.time_end,
    });

    setTc({
      time_config: 'custom',
      time_start: Math.floor(obj.time_start / 1000) || '',
      time_end: Math.floor(obj.time_end / 1000),
    });

    const getLogs = await store.trafficsGetLogs({
      manager_id: id,
      page: 1,
      perPage,
      is_traffic: action.value,
      time_config: 'custom',
      time_start: Math.floor(obj.time_start / 1000) || '',
      time_end: Math.floor(obj.time_end / 1000),
    });

    setLogs(getLogs.rows);
    setTotal(getLogs.total);
    setPrel(false);
  };

  const CustomInput = forwardRef(({value, onClick, onChange}, ref) => (
    <MaskedTextInput
      className='form-control form-control-solid mb-lg-0 mw-100'
      onClick={onClick}
      onChange={onChange}
      value={value}
      ref={ref}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  ));

  return (
    <div className='card card-flush'>
      <div className='card-header mt-5'>
        {/* <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Сотрудники (20)</h3>
        </div> */}
        <div className='d-flex flex-wrap align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
          <div className='me-4 mt-2 min-w-20'>
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '42px',
                }),
              }}
              placeholder='Оффер...'
              options={[
                {label: 'Все', value: 2},
                {label: 'Включен', value: 1},
                {label: 'Отключен', value: 0},
              ]}
              actions={(e) => filterActions(e)}
              value={action}
            />
          </div>
          <div
            className='menu-item d-flex align-items-center mt-2'
            onClick={() => {
              setShowCreateAppModal(!showCreateAppModal);
            }}
          >
            <button className='btn btn-light fw-bold text-nowrap text-gray-500 me-1 '>
              {label || 'Поиск по дате...'}
            </button>
          </div>
          {showCreateAppModal && (
            <Calendar
              showCreateAppModal={showCreateAppModal}
              setShowCreateAppModal={setShowCreateAppModal}
              handleCustom={handleCustom}
              timeConfig={calendarTime}
            />
          )}
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table
            id='kt_profile_overview_table'
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
          >
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th className='min-w-150px'>Менеджер</th>
                <th className='min-w-200px text-center'>Действие</th>
                <th className='min-w-250px'>Ответственный</th>
                <th className='min-w-90px text-center'>Дата</th>
              </tr>
            </thead>
            <tbody className='fs-6'>
              {logs.map((item) => {
                return (
                  <tr key={`${item.id}`}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-5 position-relative'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img
                              src={
                                !!item?.manager_avatar
                                  ? `${process.env.REACT_APP_API_HOST}/${item?.manager_avatar}`
                                  : imagePlug
                              }
                              alt=''
                            />
                          </div>
                        </div>

                        <div className='d-flex flex-column justify-content-center'>
                          <Link to={`/sales/rd/managers/${item.manager_id}`}>
                            <span className='d-flex align-items-center fs-6 text-gray-800 text-hover-primary cursor-pointer'>
                              {item.manager_fname} {item.manager_lname}
                            </span>
                          </Link>
                          <div className='fw-semibold text-gray-400'>#{item.manager_id}</div>
                        </div>
                      </div>
                    </td>
                    <td className='text-center'>
                      <span
                        className={`badge badge-light-${
                          item.is_traffic ? 'success' : 'danger'
                        } fw-bold px-4 py-3`}
                      >
                        {item.is_traffic ? 'Включен' : 'Отключен'}
                      </span>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-5 position-relative'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <img
                              src={
                                !!item?.user_avatar
                                  ? `${process.env.REACT_APP_API_HOST}/${item?.user_avatar}`
                                  : imagePlug
                              }
                              alt=''
                            />
                          </div>
                        </div>

                        <div className='d-flex flex-column justify-content-center'>
                          <span className='d-flex align-items-center fs-6 text-gray-800 text-hover-primary'>
                            {item.user_fname} {item.user_lname}
                          </span>
                          <div className='fw-semibold text-gray-400'>#{item.user_id}</div>
                        </div>
                      </div>
                    </td>
                    <td className='fw-semibold text-gray-700'>
                      {moment(item.created * 1000).format('HH:mm DD/MM/YYYY')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {prel && <Preloader style={{border: '0'}} />}
        </div>
        <PaginationBe
          arrLength={total}
          limit={perPage}
          page={page}
          setPage={setPage}
          action={(currentPage) => getTraffic(currentPage)}
        />
      </div>
    </div>
  );
};

export default observer(Traffic);
