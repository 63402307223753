/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Echo from 'laravel-echo';
import socketio from 'socket.io-client';

function Login() {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [temporaryToken, setTemporaryToken] = useState('');
  const [ourAuthToken, setOurAuthToken] = useState('');

  // const [focusBrowser, setFocusBrowser] = useState(true);

  // useEffect(() => {
  //   window.addEventListener('blur', function () {
  //     setFocusBrowser(false);
  //   });

  //   window.addEventListener('focus', function () {
  //     setFocusBrowser(true);
  //   });
  // }, []);

  // useEffect(() => {
  //   console.log(focusBrowser);
  // }, [focusBrowser]);

  const submit = async (event) => {
    event.preventDefault();
    if (!loading && !!temporaryToken && !!ourAuthToken) {
      openSocket(temporaryToken, ourAuthToken);

      window.open(`${process.env.REACT_APP_TELEGRAM}?start=${temporaryToken}`, '_blank');
    }
  };

  useEffect(() => {
    if (!store.tokens) {
      getTemporaryToken();
    } else {
      const authToken = store.tokens?.authToken;
      const tempToken = store.tokens?.tempToken;
      const createdAt = store.tokens?.createdAt;
      const currentTime = new Date().getTime();

      if (createdAt > currentTime) {
        setTemporaryToken(tempToken);
        setOurAuthToken(authToken);
      } else {
        revokeTempToken(tempToken);
      }
    }
  }, []);

  const revokeTempToken = async (tempToken) => {
    await store.revokeTempToken(tempToken);
    getTemporaryToken();
  };

  const getTemporaryToken = async () => {
    setLoading(true);
    try {
      const {ip, locale, userAgent} = await getUserData();

      const token = await store.getTemporaryToken(ip, locale, userAgent);

      if (token?.success) {
        const tempToken = token.data.temp_token;
        const authToken = token.data.auth_token;

        setTemporaryToken(tempToken);
        setOurAuthToken(authToken);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error getting temporary token:', error);
    }
  };

  const getUserData = async () => {
    try {
      const locale = Intl.DateTimeFormat('en-US', 'long').resolvedOptions().timeZone;

      const response = await store.getIp();
      const ip = response.ip;
      return {ip, locale, userAgent: navigator.userAgent};
    } catch (error) {
      console.error('Error fetching IP information:', error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  const openSocket = async (temptoken, authtoken) => {
    const channel = `user.${temptoken}`;

    const echo = new Echo({
      broadcaster: 'socket.io',
      host: `${process.env.REACT_APP_API_HOST}:6001`,
      auth: {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      },
      transports: ['websocket'],
      client: socketio,
    });

    echo.private(channel).listen('UserAuthTokens', (e) => {
      store.setMainToken(e.main_token);
    });

    return () => {
      echo.leave(channel); // Прибирання підписки при виході з компоненту
    };
  };

  return (
    <div className='form w-100'>
      <div className='d-grid mb-10'>
        <button type='submit' className='btn btn-primary' onClick={submit}>
          {!loading && (
            <>
              <i className='bi bi-door-open-fill'></i>
              <span className='indicator-label'>Войти в систему</span>
            </>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}

export default observer(Login);
