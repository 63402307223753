import React, {useState, useEffect} from 'react';
import { useStore } from "../../../../../store/store";
import {observer} from 'mobx-react';
import { SelectorWithSearch } from "../../../../shared/Selectors";
import Preloader from "../../../../modules/Preloader";

const Stages = ({id}) => {
  const store = useStore();
  const [prel, setPrel] = useState(false);
  const [workspaces, setWorkspaces] = useState(null);
  const [currentWorkspaces, setCurrentWorkspaces] = useState(null);

  const [stages, setStages] = useState([]);
  const [countLeads, setCountLeads] = useState(0);

  useEffect(() => {
    getWorkspaces();
  }, []);

  const getWorkspaces = async () => {
    setPrel(true);
    const res = await store.CRMWorkspaces('sales');
    setWorkspaces(
      res.map((item) => {
        return {label: item.name, value: item.id};
      })
    );

    setCurrentWorkspaces({value: res[0].id, label: res[0].name});

    getStages(res[0].id);
    setPrel(false);
  };

  const getStages = async (ws_id = '') => {
    const res = await store.salesTeamsLtcGetStages(id, ws_id);
    setStages(res?.stages);
    setCountLeads(res?.count_leads);
  };

  const getContrastYIQ = (hexcolor) => {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  };

  return (
    <div className='card card-flush'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Лиды ({countLeads})</h3>
        </div>
        <div className='d-flex flex-wrap align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
          <div className='me-4 mt-2 min-w-20'>
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '42px',
                }),
              }}
              placeholder='Workspaces...'
              clearable={true}
              options={workspaces}
              actions={(e) => {
                setCurrentWorkspaces(e);
                if (!e) {
                  return getStages(workspaces[0].value);
                }
                getStages(e.value);
              }}
              value={currentWorkspaces}
            />
          </div>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table
            id='kt_profile_overview_table'
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
          >
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th className='min-w-150px'>Этап</th>
                <th className='w-100 text-center'></th>
              </tr>
            </thead>
            <tbody className='fs-6'>
              {stages
                .sort((a, b) => a.id - b.id)
                .map((item) => {
                  return (
                    <tr key={`${item.id}`}>
                      <td>{item.name}</td>
                      <td style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div
                          style={{
                            width: 'fit-content',
                            minWidth: `${item.percent}%`,
                            height: '40px',
                            backgroundColor: `${item.color}`,
                            borderRadius: '0.625rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                          }}
                        >
                          <div style={{color: `${getContrastYIQ(item.color)}`}}>
                            {item.percent.toFixed(2)}%
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {prel && <Preloader style={{border: '0'}} />}
        </div>
      </div>
    </div>
  );
};

export default observer(Stages);
