/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../store/store'

const ModeToolbar = ({type = 'calendar'}) => {
  const store = useStore()

  useEffect(() => {
    store.setToolbarType(type)
  }, [])

  return <></>
}

export default observer(ModeToolbar)
