import React from 'react';
import {Button} from '../../../../../app/shared/Buttons';
import {Link} from 'react-router-dom';

export const ToolbarButton = () => {
  return (
    <Link to={'/account/billing'}>
      <Button>Платежные методы</Button>
    </Link>
  );
};
