/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../_metronic/partials';
import {Button} from '../../../shared/Buttons';
import InputSearch from '../../../shared/InputSearch';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import Pagination from '../../../shared/Pagination';
import Card from '../../../shared/Card';
import AddPixel from './AddPixel';
import UpdatePixel from './UpdatePixel';
import PageWrapper from '../../../modules/PageWrapper';

const PixelsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Пиксели', path: '/resources/pixels', isActive: true},
      ]}
      title={'Пиксели'}
    >
      <Pixels />
    </PageWrapper>
  );
};

const Pixels = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [pixels, setPixels] = useState([]);
  const [searchPixel, setSearchPixel] = useState([]);

  useEffect(() => {
    isVerify && getPixels();
  }, [isVerify]);

  const getPixels = async () => {
    const result = await store.getPixels();
    const sortPixels = [...result].sort((a, b) => b.created_at - a.created_at);
    setSearchPixel([...sortPixels]);
    setPixels([...sortPixels]);
    setPreloader(false);
  };

  const sortStatus = (status) => {
    let newPixels;
    if (status) {
      newPixels = pixels.filter((item) => !!item.status);
    } else {
      newPixels = pixels.filter((item) => !item.status);
    }

    setSearchPixel(newPixels);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
            <InputSearch
              title={'Поиск...'}
              array={pixels}
              newArray={setSearchPixel}
              searchParameters={['pixel_id', 'id', 'name']}
            />
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Статус <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      sortStatus(true);
                    }}
                  >
                    Активный
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      sortStatus(false);
                    }}
                  >
                    Не активный
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSearchPixel(pixels);
                    }}
                  >
                    Все статусы
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            {store.roles.includes(10) ? (
              <></>
            ) : (
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <ButtonAdd getPixels={getPixels} />
              </div>
            )}
          </div>
        </div>
        <Table
          pixels={pixels}
          getPixels={getPixels}
          searchPixel={searchPixel}
          roles={store.roles}
        />
      </Card>
    </>
  );
});

const Table = ({pixels, getPixels, searchPixel, roles}) => {
  const [portionPixels, setPortionPixels] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    const newPixels = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (searchPixel.length > i) {
        newPixels.push(searchPixel[i]);
      }
    }
    setPortionPixels(newPixels);
  }, [offset, searchPixel]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>пиксель</th>
                <th className='min-w-125px'>название</th>
                <th className='min-w-125px'>статус</th>
                <th className='min-w-125px'>создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {portionPixels.map((el, i) => {
                return <Channel key={el.id} data={el} getPixels={getPixels} roles={roles} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {searchPixel.length > limit && (
        <Pagination
          arrLength={searchPixel.length}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )}
    </div>
  );
};

const Channel = ({data, getPixels, roles}) => {
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.pixel_id}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td>
        <div className=''>{data.name}</div>
      </td>
      <td>
        {!data.status && <div className='badge badge-light-danger fw-bold'>Не активный</div>}
        {data.status && <div className='badge badge-light-success fw-bold'>Активный</div>}
      </td>
      <td>{moment(data.created_at * 1000).format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <Link to={`/resources/pixels/${data.id}`}>
            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className='ki-duotone ki-switch fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </Link>
          {roles.includes(10) ? (
            <></>
          ) : (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <ButtonUpdate getPixels={getPixels} updateData={data} />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export const ButtonAdd = ({getPixels}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление пикселя'}
      >
        <AddPixel
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getPixels={getPixels}
          isUpdate={false}
        />
      </CreateAppModal>
    </>
  );
};

const ButtonUpdate = ({getPixels, updateData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование пикселя'}
      >
        <UpdatePixel
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getPixels={getPixels}
          isUpdate={true}
          updateData={updateData}
        />
      </CreateAppModal>
    </>
  );
};

export default PixelsWrapper;
