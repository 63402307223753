/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {useLocation, useParams} from 'react-router-dom';
import Header from './Header';
import Statistics from '../Board';
import Settings from './Settings';
import ChangeStatus from './ChangeStatus';
import Source from './Source';
import Info from './Info';
import Managers from './Managers';
import PageWrapper from '../../../../../modules/PageWrapper';

export const Advertising = observer(
  ({page, setPage, timeConfig, isVerify, setPreloader, id, setBloggerID}) => {
    const store = useStore();

    const [data, setData] = useState({});
    const [traffic, setTraffic] = useState({});

    const [firstRendering, setFirstRendering] = useState(false);
    const [link, setLink] = useState({});
    const [channel, setChannel] = useState({});
    const [bot, setBot] = useState({});
    const [bayer, setBayer] = useState({});

    useEffect(() => {
      isVerify && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const tc = timeConfig;
      const getMainStats = await store.trafficsInfluenceLinksGetMainStats(tc, id);
      const traffic_id = getMainStats?.traffic_id;
      setBloggerID(traffic_id);

      const getInfo = !!traffic_id ? await store.trafficsGetInfo(traffic_id) : {};

      const getBayer = !!getMainStats.bayer_id
        ? await store.trafficsBayerGetInfo(getMainStats.bayer_id)
        : {};

      const getRomiList = await store.financeCategoryGetRomiList();
      const romi = Math.round(+getMainStats.romi);

      const indicator = getRomiList.find((el) => el.romi_min <= romi && el.romi_max >= romi);

      setBayer(getBayer);
      setTraffic(getInfo);
      setData({...getMainStats, indicator});
      setPreloader(false);
    };

    useEffect(() => {
      if (page === 'info' && !firstRendering) {
        setFirstRendering(true);
        getInfoStats();
      }
    }, [page]);

    const getInfoStats = async () => {
      const getInfoLink = await store.trafficsInfluenceLinksGetInfo(id);
      const getChannelInfo = !!getInfoLink.channel_id
        ? await store.channelsGetChannel(getInfoLink.channel_id)
        : {};
      const getStatuses = await store.trafficsInfluenceLinksGetStatuses();
      const getBot = !!getInfoLink.bot_id ? await store.botsGetBotInfo(getInfoLink.bot_id) : {};

      const status = getStatuses.find((el) => el.id === getInfoLink.status);

      setLink({...getInfoLink, status});
      setChannel(getChannelInfo);
      setBot(getBot);
    };

    return (
      <>
        <Header
          page={page}
          setPage={setPage}
          data={data}
          traffic={traffic}
          links={
            store.roles.includes(13) || store.roles.includes(32)
              ? [
                  {link: 'statistics', title: 'Общая статистика'},
                  {link: 'info', title: 'Общая информация'},
                  {link: 'managers', title: 'Менеджера'},
                ]
              : store.roles.includes(19)
              ? [
                  {link: 'statistics', title: 'Общая статистика'},
                  {link: 'info', title: 'Общая информация'},
                ]
              : [
                  {link: 'statistics', title: 'Общая статистика'},
                  {link: 'info', title: 'Общая информация'},
                  {link: 'managers', title: 'Менеджера'},
                  {link: 'settings', title: 'Настройки'},
                ]
          }
          actionComponents={[
            <Source sourceLink={data?.source_link} />,
            store.roles.includes(13) || store.roles.includes(32) ? (
              <></>
            ) : (
              <ChangeStatus id={id} getData={getData} />
            ),
          ]}
        />
        {page === 'statistics' && <Statistics data={data} />}
        {page === 'info' && (
          <Info
            link={link}
            channel={channel}
            traffic={traffic}
            bot={bot}
            data={data}
            bayer={bayer}
          />
        )}
        {page === 'managers' && <Managers />}
        {page === 'settings' && <Settings data={data} bayer={bayer} getData={getData} />}
      </>
    );
  }
);

const AdvertisingWrapper = () => {
  const [page, setPage] = useState('statistics');
  const [bloggerID, setBloggerID] = useState();

  const location = useLocation();
  const {id} = useParams();

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {
          title: `Блогер #${bloggerID || ''}`,
          path: `/traffics/influence/bloggers/${bloggerID}`,
          isActive: true,
        },
        {isSeparator: true},
        {
          title: `Рекламы`,
          path: `/traffics/influence/advertising/${id}`,
          isActive: true,
        },
      ]}
      title={`Рекламная компания #${id}`}
    >
      <Advertising page={page} setPage={setPage} id={id} setBloggerID={setBloggerID} />
    </PageWrapper>
  );
};

export default AdvertisingWrapper;
