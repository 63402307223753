import React from 'react';

const ProfileHeaderStats = ({title, StatsUpDown, stats, StatsUpDownActive = false}) => {
  return (
    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
      <div className='d-flex align-items-center'>
        {StatsUpDown && StatsUpDownActive && (
          <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        {!StatsUpDown && StatsUpDownActive && (
          <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <div className='fs-2 fw-bold'>{stats}</div>
      </div>

      <div className='fw-semibold fs-6 text-gray-400'>{title}</div>
    </div>
  );
};

export default ProfileHeaderStats;
