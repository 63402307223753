import {api} from './api';

const landingsAPI = {
  async getLandings(token, type = 'all') {
    try {
      const response = await api.get(`Resource/getLandingList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async addLanding(data) {
    try {
      const response = await api.post(`Resource/addLanding`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async updateLanding(data) {
    try {
      const response = await api.post(`Resource/updateLanding`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default landingsAPI;
