/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import AddPermissions from './AddPermissions';
import UpdatePermissions from './UpdatePermissions';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';

const PermissionsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Доступы', path: '/permissions', isActive: true},
      ]}
      title={'Доступы'}
    >
      <Permissions />
    </PageWrapper>
  );
};

const Permissions = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  return (
    <>
      <Card className='card card-flush '>
        <div className='card-header mt-6'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1 me-5'>
              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>{' '}
              <input
                type='text'
                data-kt-permissions-table-filter='search'
                className='form-control form-control-solid w-250px ps-13'
                placeholder='Search Permissions'
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <AddPermissions />
          </div>
        </div>

        <div className='card-body pt-0'>
          <div
            id='kt_permissions_table_wrapper'
            className='dataTables_wrapper dt-bootstrap4 no-footer'
          >
            <div className='table-responsive'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5 mb-0 dataTable no-footer'
                id='kt_permissions_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th
                      className='min-w-125px sorting'
                      tabindex='0'
                      aria-controls='kt_permissions_table'
                      rowSnap='1'
                      colspan='1'
                      aria-label='Name: activate to sort column ascending'
                      style={{width: '211.797px'}}
                    >
                      Name
                    </th>
                    <th
                      className='min-w-250px sorting_disabled'
                      rowSnap='1'
                      colspan='1'
                      aria-label='Assigned to'
                      style={{width: '462.469px'}}
                    >
                      Assigned to
                    </th>
                    <th
                      className='min-w-125px sorting'
                      tabindex='0'
                      aria-controls='kt_permissions_table'
                      rowSnap='1'
                      colspan='1'
                      aria-label='Created Date: activate to sort column ascending'
                      style={{width: '202.844px'}}
                    >
                      Created Date
                    </th>
                    <th
                      className='text-end min-w-100px sorting_disabled'
                      rowSnap='1'
                      colspan='1'
                      aria-label='Actions'
                      style={{width: '124.391px'}}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  <tr className='odd'>
                    <td>User Management</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                    </td>
                    <td data-order='2023-12-20T20:43:00+02:00'>20 Dec 2023, 8:43 pm </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='even'>
                    <td>Content Management</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-danger fs-7 m-1'
                      >
                        Developer
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-success fs-7 m-1'
                      >
                        Analyst
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-info fs-7 m-1'
                      >
                        Support
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-warning fs-7 m-1'
                      >
                        Trial
                      </a>
                    </td>
                    <td data-order='2023-12-20T20:43:00+02:00'>20 Dec 2023, 8:43 pm </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='odd'>
                    <td>Financial Management</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-success fs-7 m-1'
                      >
                        Analyst
                      </a>
                    </td>
                    <td data-order='2023-11-10T17:30:00+02:00'>10 Nov 2023, 5:30 pm </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='even'>
                    <td>Reporting</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-success fs-7 m-1'
                      >
                        Analyst
                      </a>
                    </td>
                    <td data-order='2023-05-05T11:05:00+03:00'>05 May 2023, 11:05 am </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='odd'>
                    <td>Payroll</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-success fs-7 m-1'
                      >
                        Analyst
                      </a>
                    </td>
                    <td data-order='2023-09-22T11:30:00+03:00'>22 Sep 2023, 11:30 am </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='even'>
                    <td>Disputes Management</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-danger fs-7 m-1'
                      >
                        Developer
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-info fs-7 m-1'
                      >
                        Support
                      </a>
                    </td>
                    <td data-order='2023-06-24T17:20:00+03:00'>24 Jun 2023, 5:20 pm </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='odd'>
                    <td>API Controls</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-danger fs-7 m-1'
                      >
                        Developer
                      </a>
                    </td>
                    <td data-order='2023-03-10T10:30:00+02:00'>10 Mar 2023, 10:30 am </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='even'>
                    <td>Database Management</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-danger fs-7 m-1'
                      >
                        Developer
                      </a>
                    </td>
                    <td data-order='2023-12-20T18:05:00+02:00'>20 Dec 2023, 6:05 pm </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                  <tr className='odd'>
                    <td>Repository Management</td>
                    <td>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-primary fs-7 m-1'
                      >
                        Administrator
                      </a>
                      <a
                        href='/metronic8/demo1/../demo1/apps/user-management/roles/view.html'
                        className='badge badge-light-danger fs-7 m-1'
                      >
                        Developer
                      </a>
                    </td>
                    <td data-order='2023-06-24T18:05:00+03:00'>24 Jun 2023, 6:05 pm </td>
                    <td className='text-end'>
                      <UpdatePermissions />
                      <button
                        className='btn btn-icon btn-active-light-primary w-30px h-30px'
                        data-kt-permissions-table-filter='delete_row'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>{' '}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <div className='dataTables_length' id='kt_permissions_table_length'>
                  <label>
                    <select
                      name='kt_permissions_table_length'
                      aria-controls='kt_permissions_table'
                      className='form-select form-select-sm form-select-solid'
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_permissions_table_paginate'
                >
                  <ul className='pagination'>
                    <li
                      className='paginate_button page-item previous disabled'
                      id='kt_permissions_table_previous'
                    >
                      <a
                        href='#'
                        aria-controls='kt_permissions_table'
                        data-dt-idx='0'
                        tabindex='0'
                        className='page-link'
                      >
                        <i className='previous'></i>
                      </a>
                    </li>
                    <li className='paginate_button page-item active'>
                      <a
                        href='#'
                        aria-controls='kt_permissions_table'
                        data-dt-idx='1'
                        tabindex='0'
                        className='page-link'
                      >
                        1
                      </a>
                    </li>
                    <li
                      className='paginate_button page-item next disabled'
                      id='kt_permissions_table_next'
                    >
                      <a
                        href='#'
                        aria-controls='kt_permissions_table'
                        data-dt-idx='2'
                        tabindex='0'
                        className='page-link'
                      >
                        <i className='next'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
});

export default PermissionsWrapper;
