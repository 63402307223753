import React from 'react';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import numeral from 'numeral';
import Card from '../../../../../shared/Card';

const Managers = ({list = []}) => {

  return (
    <Card className='card mb-0'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Менеджера</span>

          <span className='text-muted mt-1 fw-semibold fs-7'>
            {/* {subTitle}
            {teams?.users_count || 0} */}
          </span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-300px rounded-start text-start'>Менеджер</th>
                <th className='min-w-150px'>Обращения</th>
                <th className='min-w-150px'>Регистрации</th>
                <th className='min-w-150px'>Квалификации</th>
                <th className='min-w-150px'>LTC</th>
              </tr>
            </thead>

            <tbody>
              {list.map((el) => {
                return (
                  <tr key={el.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img
                            src={
                              el?.teamleader?.avatar
                                ? `${process.env.REACT_APP_API_HOST}/${el?.teamleader?.avatar}`
                                : imagePlug
                            }
                            alt=''
                          />
                        </div>

                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                            {el.teamleader.first_name} {el.teamleader.last_name}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            #{el.id}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {el.statistics.count_users}
                      </span>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {el.statistics.count_appeal}
                      </span>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {el.statistics.count_qualifications}
                      </span>
                    </td>

                    <td>
                      <span className={`badge badge-light-${el.status} fs-7 fw-bold`}>
                        {el.statistics.ltc}%
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default Managers;
