/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import {useNavigate} from 'react-router-dom';
import numeral from 'numeral';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Card from '../../../../shared/Card';
import { formatNumberToCash, formatNumber, formatNumber2 } from '../../../../functions/formatNumber';

const CardRD = ({data}) => {
  const navigate = useNavigate();

  const viewBlogger = (id) => {
    navigate(`${id}/`);
  };


  return (
    <>
      <div
        className='col-md-6 col-xl-4'
        style={{cursor: 'pointer'}}
        onClick={() => viewBlogger(data.manager.id)}
      >
        <Card className='card border-hover-primary'>
          <div className='card-header border-0 pt-5'>
            <div className='card-title m-0'>
              <div className='symbol symbol-50px w-50px bg-light'>
                <img
                  style={{borderRadius: '5px'}}
                  src={
                    data?.manager?.avatar
                      ? `${process.env.REACT_APP_API_HOST}/${data?.manager?.avatar}`
                      : imagePlug
                  }
                  alt='#'
                />
              </div>
            </div>
            <div className='card-toolbar'>
              <span className={`badge badge-light-${data?.indicator?.indicator}`}>
                {data?.indicator?.name}
              </span>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='d-flex align-items-center fs-3 fw-bold text-dark'>
              {data?.manager?.first_name} {data?.manager?.last_name}
              {!!data?.manager?.is_verified && (
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  placement='right'
                  overlay={(props) => (
                    <Tooltip className='fs-8' {...props}>
                      {!!data?.manager?.verify_date
                        ? `Верифицирован ${data?.manager?.verify_date}`
                        : 'Time not set'}
                    </Tooltip>
                  )}
                >
                  <div className='symbol symbol-30px symbol-circle'>
                    <span className='d-flex align-items-center'>
                      <i className='ki-duotone ki-verify fs-1 text-primary'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  </div>
                </OverlayTrigger>
              )}
              {!!data?.manager?.is_ban && (
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  placement='right'
                  overlay={(props) => (
                    <Tooltip className='' {...props}>
                      {!!data?.manager?.ban_date
                        ? `Заблокирован ${data?.manager?.ban_date}`
                        : 'Time not set'}
                    </Tooltip>
                  )}
                >
                  <div className='symbol symbol-30px symbol-circle'>
                    <span className='d-flex align-items-center'>
                      <i className='text-danger ki-duotone ki-lock-3 fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>

            <p className='text-gray-400 fw-semibold fs-5 mt-1 mb-7'>#{data?.manager?.id}</p>

            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <div className='fs-6 text-gray-800 fw-bold'>${data?.rd}</div>
                <div className='fw-semibold text-gray-400'>RD</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bold'>
                  {formatNumber(data?.count_leads)}
                </div>
                <div className='fw-semibold text-gray-400'>УД</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bold'>
                  {formatNumber2(data?.activity)}%
                </div>
                <div className='fw-semibold text-gray-400'>Активность</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <div className='fs-6 text-gray-800 fw-bold'>
                  {formatNumber(data?.count_qualifications)}
                </div>
                <div className='fw-semibold text-gray-400'>Квалификации</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bold'>
                  {formatNumberToCash(data?.amounts_deposits)}
                </div>
                <div className='fw-semibold text-gray-400'>Сумма депозитов</div>
              </div>
            </div>

            <div
              className='h-4px w-100 bg-light mb-5'
              data-bs-toggle='tooltip'
              title='This project 50% completed'
            >
              <div
                className={`bg-${data?.indicator?.indicator} rounded h-4px`}
                role='progressbar'
                style={{width: '100%'}}
                aria-valuenow='100'
                aria-valuemin='0'
                aria-valuemax='100'
              ></div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default CardRD;
