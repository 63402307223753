import React from 'react';
import userPlug from '../../../_metronic/assets/media/user.png';
import moment from 'moment';

const Log = ({data}) => {
  function formatTimeAgo(milliseconds) {
    if (new Date().getTime() - milliseconds * 1000 < 3600000) {
      return moment(milliseconds * 1000)
        .startOf('minute')
        .fromNow();
    }

    return moment(milliseconds * 1000)
      .startOf('hour')
      .fromNow();
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Логирование трансфера</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px'>#</th>
                  <th className='min-w-320px' style={{minWidth: '150px'}}>
                    Откуда
                  </th>
                  <th className='min-w-320px' style={{minWidth: '150px'}}>
                    Куда
                  </th>
                  <th className='min-w-125px' style={{minWidth: '150px'}}>
                    Дата
                  </th>
                  <th className='min-w-320px' style={{minWidth: '150px'}}>
                    Ответственный
                  </th>
                </tr>
              </thead>

              <tbody>
                {data?.transfers
                  ?.sort((a, b) => b.created_at - a.created_at)
                  .map((item, index) => {
                    return (
                      <tr key={`${index}`}>
                        <td className='ps-4'>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                            #{item?.id}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {item?.type?.toUpperCase()}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px overflow-hidden me-3'>
                              <span>
                                <div className='symbol-label'>
                                  <img
                                    src={
                                      !!item?.old_manager?.avatar
                                        ? `${process.env.REACT_APP_API_HOST}/${item?.old_manager?.avatar}`
                                        : userPlug
                                    }
                                    alt='#'
                                    className='w-100'
                                  />
                                </div>
                              </span>
                            </div>

                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                                {!item?.old_manager?.first_name &&
                                  !item?.old_manager?.last_name &&
                                  'Не был назначен'}
                                {(!!item?.old_manager?.first_name ||
                                  !!item?.old_manager?.last_name) && (
                                  <>
                                    {item?.old_manager?.first_name} {item?.old_manager?.last_name}
                                  </>
                                )}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                #{item?.old_manager?.id}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px overflow-hidden me-3'>
                              <span>
                                <div className='symbol-label'>
                                  <img
                                    src={
                                      !!item?.new_manager?.avatar
                                        ? `${process.env.REACT_APP_API_HOST}/${item?.new_manager?.avatar}`
                                        : userPlug
                                    }
                                    alt='#'
                                    className='w-100'
                                  />
                                </div>
                              </span>
                            </div>

                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                                {!item?.new_manager?.first_name &&
                                  !item?.new_manager?.last_name &&
                                  'Не был назначен'}
                                {(!!item?.new_manager?.first_name ||
                                  !!item?.new_manager?.last_name) && (
                                  <>
                                    {item?.new_manager?.first_name} {item?.new_manager?.last_name}
                                  </>
                                )}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                #{item?.new_manager?.id}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {moment(item?.created_at * 1000).format('DD/MM/YYYY (HH:mm)')}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {formatTimeAgo(item?.created_at)}
                          </span>
                        </td>

                        {!item?.user?.id && (
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50px overflow-hidden me-3'>
                                <span>
                                  <div className='symbol-label'>
                                    <img src={userPlug} alt='#' className='w-100' />
                                  </div>
                                </span>
                              </div>

                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                                  Система
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                        {!!item?.user?.id && (
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50px overflow-hidden me-3'>
                                <span>
                                  <div className='symbol-label'>
                                    <img
                                      src={
                                        !!item?.user?.avatar
                                          ? `${process.env.REACT_APP_API_HOST}/${item?.user?.avatar}`
                                          : userPlug
                                      }
                                      alt='#'
                                      className='w-100'
                                    />
                                  </div>
                                </span>
                              </div>

                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                                  {!item?.user?.first_name &&
                                    !item?.user?.last_name &&
                                    'Не был назначен'}
                                  {(!!item?.user?.first_name || !!item?.user?.last_name) && (
                                    <>
                                      {item?.user?.first_name} {item?.user?.last_name}
                                    </>
                                  )}
                                </span>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  #{item?.user?.id}
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Log;
