/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';

const UpdatePermissions = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <button
        className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
        onClick={() => setShowCreateAppModal(true)}
      >
        <i className='ki-duotone ki-setting-3 fs-3'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </i>{' '}
      </button>
      <CreateAppModal
        customWidth='mw-650px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        title={'Update Permission'}
      >
        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
          <i className='ki-duotone ki-information fs-2tx text-warning me-4'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>

          <div className='d-flex flex-stack flex-grow-1 '>
            <div className=' fw-semibold'>
              <div className='fs-6 text-gray-700 '>
                <strong className='me-1'>Warning!</strong> By editing the permission name, you might
                break the system permissions functionality. Please ensure you're absolutely certain
                before proceeding.
              </div>
            </div>
          </div>
        </div>

        <form
          id='kt_modal_update_permission_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          action='#'
        >
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Permission Name</span>

              <span
                className='ms-2'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-information fs-7'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
              </span>
            </label>

            <input
              className='form-control form-control-solid'
              placeholder='Enter a permission name'
              name='permission_name'
            />

            <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-permissions-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        </form>
      </CreateAppModal>
    </>
  );
};

export default UpdatePermissions;
