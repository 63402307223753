/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import '../../styles/globals.scss';
import {Outlet, useLocation} from 'react-router-dom';
import {HeaderWrapper} from './components/header';
import {RightToolbar} from '../partials/layout/RightToolbar';
import {ScrollTop} from './components/scroll-top';
import {Content} from './components/content';
import {FooterWrapper} from './components/footer';
import {Sidebar} from './components/sidebar';

import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
  useThemeMode,
} from '../partials';
import {PageDataProvider} from './core';
import {reInitMenu} from '../helpers';
import {ToolbarWrapper} from './components/toolbar';
import {observer} from 'mobx-react';
import {useStore} from '../../store/store';
import Layout from '../../app/modules/Layout';

const MasterLayout = () => {
  const location = useLocation();

  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <Layout>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  {/* <ToolbarWrapper /> */}
                  <Content>
                    <Outlet />
                  </Content>
                </div>

                <FooterWrapper />
              </div>
            </div>
          </div>
        </Layout>
        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        {/* <UpgradePlan /> */}
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export default observer(MasterLayout);
