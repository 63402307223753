import numeral from 'numeral';

export const formatNumberToCash = (num) => {
  return numeral(num).format('0,0.00$');
};

export const formatNumber = (num) => {
  return numeral(num).format('0,0');
};

export const formatNumber2 = (num) => {
  return numeral(num).format('0.00');
};
