/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../store/store';
import {observer} from 'mobx-react';
import Preloader from '../modules/Preloader';

const Card = ({children, className, style}) => {
  const store = useStore();

  return (
    <div className={className} style={{...style, position: 'relative'}}>
      {children}
      {/* {!!localActivePreloader.length && <Preloader />} */}
    </div>
  );
};

export default observer(Card);
