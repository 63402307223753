/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';

const ButtonExel = ({fieldsToKeep, data, columnFormats = {}, time, fileName}) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!Array.isArray(data)) return setLoader(true);
    setLoader(false);
  }, [data]);

  const createExcelFile = useCallback(() => {
    if (!Array.isArray(data)) return;

    // Фільтруємо дані, залишаючи тільки потрібні поля
    const filteredData = data.map((item) => {
      if (typeof item !== 'object') return {};
      return Object.keys(item).reduce((acc, key) => {
        if (fieldsToKeep.includes(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {});
    });

    // Створюємо новий робочий лист з відфільтрованими даними
    const ws = XLSX.utils.json_to_sheet(filteredData, {header: fieldsToKeep});

    // Застосовуємо форматування до вказаних колонок

    const range = XLSX.utils.decode_range(ws['!ref']);
    Object.entries(columnFormats).forEach(([columnName, format]) => {
      const columnIndex = fieldsToKeep.indexOf(columnName);

      if (columnIndex === -1) {
        console.error(`Column "${columnName}" not found`);
        return;
      }

      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({c: columnIndex, r: R});
        if (ws[cellAddress]) {
          ws[cellAddress].z = format;
        }
      }
    });

    // Створюємо нову робочу книгу
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // Створюємо файл Excel
    XLSX.writeFile(
      wb,
      time.config === 'all_time' ? `${fileName} all_time.xlsx` : `${fileName} ${moment.tz(time.start * 1000, 'Europe/Kiev').format('YYYY-MM-DD')} - ${moment
        .tz(new Date(time.end * 1000).setUTCHours(0, 0, 0), 'Europe/Kiev')
        .format('YYYY-MM-DD')}.xlsx`
    );
  }, [data, fieldsToKeep, columnFormats]);

  return (
    <button
      className='btn btn-sm shadow-xs d-flex align-items-center me-4 btn-active-light-success'
      onClick={() => !loader && createExcelFile()}
    >
      {!loader && (
        <i className='ki-duotone ki-file-down fs-1 pe-0'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      )}
      {!!loader && <span className='exelLoader'></span>}
    </button>
  );
};

// btn-light-success border border-success

export default ButtonExel;
