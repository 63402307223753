import React from 'react';
import {KTIcon} from '../../_metronic/helpers';
import Card from '../shared/Card';

const Statistics = ({
  title,
  subTitle,
  state,
  className = 'card-xl-stretch mb-xl-8 h-100',
  color = 'primary',
  children,
}) => {
  return (
    <Card className={`card card-flush h-xl-100`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className='card-title d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>
          </div>

          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{subTitle}</span>
            <span className='fw-bold fs-2x pt-1'>{state}</span>
          </div>
        </div>

        <div
          className='shadow-xs card-rounded mx-4 mx-sm-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {children}
        </div>
      </div>
    </Card>
  );
};

export const Item = ({title, icon, subTitle, stats, indicator = 'up'}) => {
  return (
    <>
      <div className='d-flex align-items-center mb-6'>
        <div className='symbol symbol-45px w-40px me-5'>
          <span className='symbol-label bg-lighten'>{icon}</span>
        </div>

        <div className='d-flex align-items-center flex-wrap w-100'>
          <div className='mb-1 pe-3 flex-grow-1'>
            <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>{title}</span>
            <div className='text-gray-400 fw-semibold fs-7'>{subTitle}</div>
          </div>

          <div className='d-flex align-items-center'>
            <div className='fw-bold fs-5 text-gray-800 pe-1'>{stats}</div>
            {indicator === 'up' && (
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' />
            )}
            {indicator === 'down' && (
              <KTIcon iconName='arrow-down' className='fs-5 text-danger ms-1' />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
