/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import ModeToolbar from '../../modules/ModeToolbar';
import {PageTitle} from '../../../_metronic/layout/core';
import {useLocation} from 'react-router';
import Create from './create/Create';
import View from './view/View';

const Payments = () => {
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/payments/create': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: ''},
            {isSeparator: true},
            {title: 'Создать', path: '/payments/create', isActive: true},
          ],
          title: 'Создать выплату',
        });
        break;
      }
      case '/payments/view': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: ''},
            {isSeparator: true},
            {title: 'Просмотр', path: '/payments/view', isActive: true},
          ],
          title: 'Просмотр выплат',
        });
        break;
      }
    }
  }, [location]);

  return (
    <>
      <ModeToolbar />
      <PageTitle breadcrumbs={breadcrumbs.br}>{breadcrumbs.title}</PageTitle>
      {location.pathname === '/payments/create' && <Create />}
      {location.pathname === '/payments/view' && <View />}
    </>
  );
};

export default Payments;
