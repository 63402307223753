/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import Card from './Card';
import PageWrapper from '../../../../modules/PageWrapper';

const BayersWrapper = () => {
  return (
    <PageWrapper
      firstTimeConfig='30day'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Facebook', path: '/traffics/facebook/bayers'},
        {isSeparator: true},
        {title: 'Байера', path: '/traffics/facebook/bayers', isActive: true},
      ]}
      title={'Facebook Байера'}
    >
      <Bayers />
    </PageWrapper>
  );
};

const Bayers = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();
  const [data, setData] = useState([]);

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const getBayerStats = await store.trafficsFacebookGetBayersStats(tc);
    const getRomiList = await store.financeCategoryGetRomiList();

    const newBayers = getBayerStats.bayers.map((el) => {
      const romi = Math.round(+el.romi);
      const indicator = getRomiList.find(
        (item) => romi >= +item.romi_min && romi <= +item.romi_max
      );
      return {...el, indicator};
    });

    setData(newBayers);
    setPreloader(false);
  };

  return (
    <>
      <div className='row g-6 g-xl-9'>
        {data.map((el) => {
          return <Card data={el} key={el?.bayer?.id} />;
        })}
      </div>
    </>
  );
});

export default BayersWrapper;
