/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import Pagination from '../../../shared/Pagination'
import imagePlug from '../../../../_metronic/assets/media/user.png'
import {CreateAppModal} from '../../../../_metronic/partials'
import AddCosts from './AddCosts'
import {Button} from '../../../shared/Buttons'
import {Dropdown1} from '../../../shared/dropdown/Dropdown1'
import Filter from '../../../shared/Filter'
import Card from '../../../shared/Card'

const Costs = () => {
  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-13'
                placeholder='Search user'
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <Filter>
                <Dropdown1 />
              </Filter>

              <ButtonExport />

              <ButtonAdd />
            </div>
          </div>
        </div>
        <Table />
      </Card>
    </>
  )
}

const users = [
  {where: {name: 'Елена РМ', id: 45334}, category: 'Facebook', sum: '2,234.00', date: new Date()},
  {where: {name: 'Елена РМ', id: 45335}, category: 'Facebook', sum: '2,234.00', date: new Date()},
  {where: {name: 'Елена РМ', id: 45336}, category: 'Facebook', sum: '2,234.00', date: new Date()},
  {where: {name: 'Елена РМ', id: 45337}, category: 'Facebook', sum: '2,234.00', date: new Date()},
  {where: {name: 'Елена РМ', id: 45338}, category: 'Facebook', sum: '2,234.00', date: new Date()},
  {where: {name: 'Елена РМ', id: 45339}, category: 'Facebook', sum: '2,234.00', date: new Date()},
  {where: {name: 'Елена РМ', id: 45330}, category: 'Facebook', sum: '2,234.00', date: new Date()},
]

const Table = () => {
  const [portionUsers, setPortionUsers] = useState([])
  const [limit, setLimit] = useState(3)
  const [offset, setOffset] = useState(1)

  useEffect(() => {
    const newUsers = []
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (users.length > i) {
        newUsers.push(users[i])
      }
    }
    setPortionUsers(newUsers)
  }, [offset])

  return (
    <div className='card-body py-4'>
      <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
        <thead>
          <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
            <th className='w-10px pe-2'>
              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  data-kt-check='true'
                  data-kt-check-target='#kt_table_users .form-check-input'
                  value='1'
                />
              </div>
            </th>
            <th className='min-w-125px'>Куда</th>
            <th className='min-w-125px'>Категория</th>
            <th className='min-w-125px'>Сумма</th>
            <th className='min-w-125px'>Дата</th>
            <th className='text-end min-w-100px'>Действие</th>
          </tr>
        </thead>

        <tbody className='text-gray-600 fw-semibold'>
          {portionUsers.map((el, i) => {
            return <User key={el.where.id} data={el} />
          })}
        </tbody>
      </table>
      {users.length >= limit && (
        <Pagination arrLength={users.length} limit={limit} offset={offset} setOffset={setOffset} />
      )}
    </div>
  )
}

const User = ({data}) => {
  return (
    <tr>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input' type='checkbox' value='1' />
        </div>
      </td>
      <td className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
          <div>
            <div className='symbol-label'>
              <img src={imagePlug} alt='Emma Smith' className='w-100' />
            </div>
          </div>
        </div>

        <div className='d-flex flex-column'>
          <span className='text-gray-800 text-hover-primary mb-1' style={{cursor: 'pointer'}}>
            {data.where.name}
          </span>
          <span>#{data.where.id}</span>
        </div>
      </td>
      <td>
        <div className='badge badge-primary fw-bold'>{data.category}</div>
      </td>
      <td>
        <div className='badge badge-light fw-bold text-danger'>{data.sum}$</div>
      </td>
      <td>22 Sep 2023, 11:05 am</td>
      <td className='text-end'>
        <Button
          color='btn-light btn-active-light-primary'
          ktMenuTrigger='click'
          ktMenuPlacement='bottom-end'
        >
          <>
            Actions
            <i className='ki-duotone ki-down fs-5 ms-1'></i>
          </>
        </Button>

        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <span className='menu-link px-3'>Edit</span>
          </div>

          <div className='menu-item px-3'>
            <span className='menu-link px-3' data-kt-users-table-filter='delete_row'>
              Delete
            </span>
          </div>
        </div>
      </td>
    </tr>
  )
}

const ButtonAdd = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  const [warning, setWarning] = useState(false)
  const [save, setSave] = useState(false)

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true)
          setSave(false)
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-900px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false)
          } else {
            setWarning(true)
          }
        }}
        title={'Добавление расходов'}
      >
        <AddCosts
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
        />
      </CreateAppModal>
    </>
  )
}

const ButtonExport = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)

  return (
    <>
      <Button
        className='me-3'
        color='btn-light-primary'
        action={() => {
          setShowCreateAppModal(true)
        }}
      >
        <>
          <i className='ki-duotone ki-exit-up fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Export
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-650px'
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
        }}
        title={'Export Users'}
      >
        <form id='kt_modal_export_users_form' className='form' action='#'>
          <div className='fv-row mb-10'>
            <label className='fs-6 fw-semibold form-label mb-2'>Select Roles:</label>

            <select
              name='role'
              data-control='select2'
              data-placeholder='Select a role'
              data-hide-search='true'
              className='form-select form-select-solid fw-bold'
            >
              <option></option>
              <option value='Administrator'>Administrator</option>
              <option value='Analyst'>Analyst</option>
              <option value='Developer'>Developer</option>
              <option value='Support'>Support</option>
              <option value='Trial'>Trial</option>
            </select>
          </div>

          <div className='fv-row mb-10'>
            <label className='required fs-6 fw-semibold form-label mb-2'>
              Select Export Format:
            </label>

            <select
              name='format'
              data-control='select2'
              data-placeholder='Select a format'
              data-hide-search='true'
              className='form-select form-select-solid fw-bold'
            >
              <option></option>
              <option value='excel'>Excel</option>
              <option value='pdf'>PDF</option>
              <option value='cvs'>CVS</option>
              <option value='zip'>ZIP</option>
            </select>
          </div>

          <div className='text-center'>
            <Button className='me-3'>Discard</Button>
            <Button>
              <>
                <span className='indicator-label'>Submit</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </>
            </Button>
          </div>
        </form>
      </CreateAppModal>
    </>
  )
}

export default Costs
