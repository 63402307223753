import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {toast} from 'react-toastify';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';
import Warning from '../../../modules/Warning';

const UpdateAccount = ({show, getAdvertisersList, warning, setWarning, isUpdate = false, id}) => {
  const store = useStore();
  const [data, setData] = useState({});
  const [error, setError] = useState([]);

  useEffect(() => {
    (async () => {
      if (isUpdate) {
        const result = await store.financeAccountsGetAccount(id);
        setData(result);
      }
    })();
  }, []);

  function allowOnlyNumbers(str) {
    const inputValue = str;

    const regex = /^\d+(\.\d{0,2})?$/;

    if (regex.test(inputValue) || inputValue === '') {
      return inputValue;
    } else {
      return inputValue.slice(0, -1);
    }
  }

  const handleChange = (e) => {
    let {name, value} = e.target;

    if (name === 'start_balance') {
      value = allowOnlyNumbers(value);
    }

    const newData = {...data};

    newData[name] = value;
    setData(newData);
  };

  const update = async () => {
    if (!findError()) {
      return;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(`${key}`, value);
    }

    const result = await store.financeAccountsUpdate(formData, id);

    !!result &&
      toast.success(`Успешно обновлено!`, {
        onOpen: async () => {
          await getAdvertisersList();
          show(false);
        },
      });
    !result &&
      toast.error(`Произошла ошибка в обновлении!`, {
        theme: 'colored',
      });
  };

  const findError = () => {
    const names = [];

    !data.name && names.push('name');
    !data.start_balance && names.push('start_balance');

    if (!!names.length) {
      setError(names);
      return false;
    }

    setError([]);
    return true;
  };

  return (
    <>
      <form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
        <div className='fv-row mb-10'>
          <label className='form-label'>
            <span className='required'>Название счета</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            value={data.name || ''}
            name='name'
            onChange={handleChange}
          />
          {error.includes('name') && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label'>
            <span className='required'>Стартовый баланс $</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            value={data.start_balance || ''}
            name='start_balance'
            onChange={handleChange}
          />
          {error.includes('start_balance') && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
        <div className='d-flex flex-end pt-10'>
          <Button
            action={() => {
              update();
            }}
          >
            Сохранить изменения
          </Button>
        </div>

        <CreateAppModal
          customWidth='mw-400px'
          padding='pb-lg-10 pt-lg-10 px-lg-10'
          show={warning}
          handleClose={() => setWarning(false)}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            buttons={[
              {
                action: () => {
                  setWarning(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },
              {
                action: () => {
                  setWarning(false);
                  show(false);
                },
                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </form>
    </>
  );
};

export default observer(UpdateAccount);
