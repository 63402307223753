/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {toast} from 'react-toastify';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import Card from '../../../../../shared/Card';
import {Button} from '../../../../../shared/Buttons';

const Settings = ({data}) => {
  const store = useStore();

  const [image, setImage] = useState('');
  const [mainData, setMainData] = useState({});
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (!!mainData.avatar) {
      formaterImage(mainData?.avatar);
    }
  }, [mainData]);

  useEffect(() => {
    const newData = {
      bayer_id: data?.bayer?.id,
      id: data?.id,
      departament_id: data?.departament_id,
      name: data?.name,
      source_link: data?.source_link,
    };

    !newData.hasOwnProperty('source_link') && delete newData.source_link;

    setMainData(newData);

    formaterImage(data?.avatar);
  }, [data]);

  const formaterImage = (avatar) => {
    if (typeof avatar === 'object' && !!avatar) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageDataUrl = e.target.result;

        return setImage(`url(${imageDataUrl})`);
      };

      reader.readAsDataURL(avatar);
    } else if (!!avatar && typeof avatar === 'string') {
      return setImage(`url(${process.env.REACT_APP_API_HOST}/${avatar})`);
    } else if (!avatar) {
      return setImage(`url(${imagePlug})`);
    }
  };

  const handleChange = (e) => {
    const {name, value, files} = e.target;

    const newMainData = {...mainData};

    if (name === 'name') setNameError(false);

    if (name === 'avatar') {
      newMainData[name] = files[0];
      return setMainData(newMainData);
    }

    newMainData[name] = value;
    setMainData(newMainData);
  };

  const save = async () => {
    if (!mainData.name.length) {
      return setNameError(true);
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(mainData)) {
      formData.append(`${key}`, value);
    }

    // const result = await store.trafficsUpdateTraffic(formData);

    // !!result &&
    //   toast.success(`Успешно обновлено!`, {
    //
    //   });
    // !result &&
    //   toast.error(`Произошла ошибка в обновлении!`, {
    //     theme: 'colored',
    //   });
  };

  return (
    <Card className='card'>
      <div className='card-header'>
        <div className='card-title fs-3 fw-bold'>Настройки</div>
      </div>

      <form id='kt_project_settings_form' className='form'>
        <div className='card-body p-9'>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Название</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <input
                type='text'
                className='form-control form-control-solid'
                name='name'
                value={mainData?.name || ''}
                onChange={handleChange}
              />
              {nameError && <div className='text-danger mt-2'>Поле обязательно для заполнения</div>}
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Байер</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <input
                type='text'
                className='form-control form-control-solid'
                name='type'
                defaultValue={`${data?.bayer?.first_name || ''} ${data?.bayer?.last_name || ''}`}
                disabled
              />
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <Button action={() => save()}>Сохранить</Button>
        </div>
      </form>
    </Card>
  );
};

export default observer(Settings);
