import React from 'react';
import classNames from 'classnames';

const Stepper = ({links = [], page}) => {
  return (
    <div className='stepper stepper-links d-flex flex-column'>
      <div className='w-100 stepper-nav mb-5 d-flex justify-content-center'>
        <div className='stepper-nav mb-5'>
          {links.map((el) => {
            return (
              <div
                className={classNames('stepper-item', page === el.link && 'current')}
                data-kt-stepper-element='nav'
                key={el.link}
              >
                <h3 className='stepper-title'>{el.title}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
