import {api} from './api';

const departmentsAPI = {
  async departamentsGetList(token) {
    try {
      const response = await api.get(`Departaments/getList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default departmentsAPI;
