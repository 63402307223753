/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import {makeAutoObservable} from 'mobx';
import {enableStaticRendering, useLocalObservable} from 'mobx-react';
import {runInAction} from 'mobx';
import {makePersistable, hydrateStore} from 'mobx-persist-store';
import {createContext, useContext} from 'react';
import api from './api/api';

enableStaticRendering(typeof window === 'undefined');

let store;
const server = typeof window === 'undefined';

class Store {
  user = (!server && localStorage?.appState && JSON.parse(localStorage?.appState)?.user) || null;
  tokens =
    (!server && localStorage?.appState && JSON.parse(localStorage?.appState)?.tokens) || null;

  roles = [];
  currentLocation = '';
  isVerify = false;

  toolbarType = '';
  toolbarLabel = '';
  configToolbarUserFilter = {notWorking: true, working: true, status: '1'};
  timeConfig = {time_config: '', time_start: '', time_end: ''};
  errorsMessages = [];

  preloaderQ = [];

  constructor() {
    // console.log('store::constructor');
    makeAutoObservable(this, {}, {autoBind: true});

    if (!server) {
      makePersistable(this, {
        name: 'appState',
        properties: ['user', 'tokens', 'theme'],
        storage: window.localStorage,
        removeOnExpiration: true,
        // debugMode: true,
      }).then(() => {
        console.log('store::persistable');
      });
    }
  }

  setPreloader(status) {
    if (status) {
      const newPreloaderQ = [...this.preloaderQ];
      newPreloaderQ.push(status);
      runInAction(() => {
        this.preloaderQ = newPreloaderQ;
      });
    } else {
      const timeout = setTimeout(() => {
        const newPreloaderQ = [...this.preloaderQ];
        newPreloaderQ.pop();
        runInAction(() => {
          this.preloaderQ = newPreloaderQ;
        });

        return () => clearTimeout(timeout);
      }, 400);
    }
  }

  filterData(data) {
    if (!data?.success) {
      const shareErrors = Object.entries(data?.data);
      const errorsArr = shareErrors.reduce((sum, current) => {
        return [...sum, ...current[1]];
      }, []);

      this.errorsMessages = errorsArr;
      return null;
    } else if (!!data?.success && typeof data?.success !== 'string') {
      return data?.data;
    } else {
      this.errorsMessages = [data.success];
      return null;
    }
  }

  setCurrentLocation(location) {
    this.currentLocation = location;
  }

  setIsVerify(bool) {
    this.isVerify = bool;
  }

  //Auth//////////////////////////

  async signOut() {
    const token = this.user.main_token;
    await api.revokeToken(token, token);
    this.timeConfig = {time_config: ''};
    this.user = null;
    this.tokens = null;
  }

  async getIp() {
    const ip = await api.getIp();
    return ip.data;
  }

  async getTemporaryToken(ip, locale, userAgent) {
    this.user = null;
    this.tokens = null;
    this.user = {user_ip: ip, user_locale: locale, user_agent: userAgent};

    const token = await api.getTemporaryToken({ip, locale, userAgent});
    const authToken = token.data.auth_token;
    const tempToken = token.data.temp_token;
    const createdAt = token.data.created_at;
    const towHours = 2 * 60 * 60 * 1000;

    this.tokens = {authToken, tempToken, createdAt: createdAt * 1000 + towHours};

    this.user = {...this.user};

    return token;
  }

  setMainToken(token) {
    this.user = {...this.user, main_token: token};
  }

  async verifyAuth({ip, locale, userAgent}) {
    const {main_token, user_ip, user_locale, user_agent} = this.user;
    const user = await api.verifyAuth({
      main_token,
      user_ip: ip,
      user_locale: locale,
      user_agent: userAgent,
    });

    if (!user) {
      return null;
    }

    runInAction(() => {
      this.user = {
        ...this.user,
        ...user,
        // auth: {
        //   roles: [
        //     // {departament_id: 1, id: 9, level: 1, name: 'Manager LTC'},
        //     // {departament_id: 1, id: 33, level: 1, name: 'ManagerDeputyLTC'},
        //     // {departament_id: 1, id: 16, level: 1, name: 'TeamLeader LTC'},
        //     // {departament_id: 1, id: 15, level: 1, name: 'Manager RD'},
        //     // {departament_id: 1, id: 34, level: 1, name: 'ManagerDeputyRD'},
        //     // {departament_id: 1, id: 17, level: 1, name: 'TeamLeader RD'},
        //     // {departament_id: 1, id: 8, level: 1, name: 'Head of Sales'},
        //     // {departament_id: 1, id: 11, level: 1, name: 'Head of Influence'},
        //     // {departament_id: 1, id: 10, level: 1, name: 'Head of Facebook'},
        //     // {departament_id: 1, id: 6, level: 1, name: 'Facebook Bayer (Profit)'},
        //     // {departament_id: 1, id: 19, level: 1, name: 'Influence Bayer (Cost)'},
        //     // {departament_id: 1, id: 13, level: 1, name: 'Head of Finance'},
        //     // {departament_id: 1, id: 32, level: 1, name: 'Assistant of Finance'},
        //     // {departament_id: 1, id: 26, level: 1, name: 'Hr'},
        //     // {departament_id: 1, id: 28, level: 1, name: 'Manager Support'},
        //     // {departament_id: 1, id: 29, level: 1, name: 'Manager Cold'},
        //     // {departament_id: 1, id: 30, level: 1, name: 'Head Assistant Of Sales'},
        //     // {departament_id: 1, id: 31, level: 1, name: 'CRM Backend'},
        //   ],
        // },
      };
    });
    return user;
  }

  setRoles(roles) {
    this.roles = !!roles?.length ? [...roles] : [];
  }

  async revokeToken(revoke_token) {
    const token = this.user.main_token;

    const result = await api.revokeToken(token, revoke_token);

    return result;
  }

  async revokeTempToken(token) {
    await api.revokeTempToken(token);
  }

  //Auth/////////////////////////

  //dashboards/////////////////

  async getSalesStats(timeConfig, filter = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData('Sales/mainStats', token, timeConfig, filter);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getMainStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData('Dashboard/mainStats', token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getMainStatsFacebook(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData('Facebook/mainStats', token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getMainStatsInfluence(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData('Influence/mainStats', token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getGeoQualifications(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/geoQualifications',
      token,
      timeConfig
    );
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getQualificationSchedule(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/qualificationSchedule',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getQualificationScheduleFacebook(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Facebook/qualificationSchedule',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getQualificationScheduleInfluence(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Influence/qualificationSchedule',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getQualificationScheduleManagerLtc(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Manager/qualificationSchedule',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getFacebookBayerQualificationSchedule(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Facebook/Bayer/qualificationSchedule',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async getInfluenceBayerQualificationSchedule(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Influence/Bayer/qualificationSchedule',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getInfluenceBayerMainStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Influence/Bayer/mainStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async getFacebookBayerMainStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Facebook/Bayer/mainStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getStatsManagerLtc(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Manager/mainStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getStatsManagerRd(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Rd/Manager/mainStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getLtcStatsManagerLtc(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Manager/getLtcStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getRdStatsManagerRd(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Rd/Manager/getRdStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getForecastPayoutManagerLtc(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Manager/getForecastPayout',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getForecastPayoutManagerRd(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Rd/Manager/getForecastPayout',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getDashboardManagersStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Teams/getManagersStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getDashboardManagersStatsRd(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Rd/Teams/getManagersStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getDashboardTeamsLtcMainStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Teams/mainStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getDashboardTeamsRdMainStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Rd/Teams/mainStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getDashboardTeamsLtcStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Ltc/Teams/getLtcStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getDashboardTeamsRdStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData(
      'Dashboard/Sales/Rd/Teams/getRdStats',
      token,
      timeConfig
    );

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async dashboardSalesTeamLeadGetActives(date) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.dashboardSalesTeamLeadGetActives({token, date});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async dashboardSalesAssistantGetActives(params) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.dashboardsAPI.dashboardSalesAssistantGetActives({token, ...params});
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async dashboardSalesAssistantGetActives2(params) {
    const token = this.user.main_token;

    const response = await api.dashboardsAPI.dashboardSalesAssistantGetActives({token, ...params});
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  //dashboards/////////////////

  //hr//////////////////////////
  async hrGetEmployeesStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData('Hr/getEmployeesStats', token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async hrGetEmployeesGeo(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.dashboardsAPI.getData('Hr/getEmployeesGeo', token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  //hr//////////////////////////

  //timeConfig///////////////////

  setTimeConfig(data) {
    return (this.timeConfig = {...data});
  }

  //timeConfig///////////////////

  //toolbars///////////////////////////
  setToolbarType(type) {
    this.toolbarType = type;
  }

  setToolbarLabel(str) {
    this.toolbarLabel = str;
  }

  setConfigToolbarUserFilter(conf, cleaning = false) {
    if (!cleaning) {
      const newConfig = {...this.configToolbarUserFilter, ...conf};
      return (this.configToolbarUserFilter = newConfig);
    }
    return (this.configToolbarUserFilter = {notWorking: true, working: true, status: '1'});
  }

  //toolbars///////////////////////////

  //finance////////////////////
  async financeCategoryGetLtcList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.financeAPI.financeCategoryGetLtcList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async financeCategoryGetRdList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.financeAPI.financeCategoryGetRdList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async financeCategoryGetRomiList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.financeAPI.financeCategoryGetRomiList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async financeCategoryGetRomiListNoPreloader() {
    const token = this.user.main_token;
    const response = await api.financeAPI.financeCategoryGetRomiList(token);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
  }

  async financeAccountsGetAccountsList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.financeAPI.financeAccountsGetAccountsList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async financeAccountsAdd(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.financeAPI.financeAccountsAdd(data);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async financeAccountsGetAccount(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.financeAPI.financeAccountsGetAccount(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async financeAccountsUpdate(data, id) {
    const token = this.user.main_token;
    data.append('token', token);
    data.append('id', id);
    const response = await api.financeAPI.financeAccountsUpdate(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }
  //finance////////////////////

  //employees////////////////////

  async getEmployees() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersGetUsers(token);
    const result = this.filterData(response);
    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getEmployeeData(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response1 = await api.employeesAPI.usersGetMainData(token, id);
    const response2 = await api.employeesAPI.getData(token, id);

    const result1 = this.filterData(response1);
    const result2 = this.filterData(response2);

    if (!!result1 && !!result2) {
      this.setPreloader(false);
      const newData = {...result1, ...result2};
      return newData;
    }
  }

  async usersGetAllAccess() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersGetAllAccess(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersRolesGetList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersRolesGetList(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersRolesGetUserList(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersRolesGetUserList(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersBanUser(id, status) {
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersBanUser(token, id, status);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async usersVerifyUser(id, status) {
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersVerifyUser(token, id, status);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async usersGetSettings(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersGetSettings(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersGetWallets(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersGetWallets(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersGetTransactions(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersGetTransactions(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersGetAuthData(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersGetAuthData(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersAddUsers(data) {
    const token = this.user.main_token;
    data.append(`token`, token);

    const response = await api.employeesAPI.usersAddUsers(data);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async usersUpdateUser(data) {
    const token = this.user.main_token;
    data.append(`token`, token);

    const response = await api.employeesAPI.usersUpdateUser(data);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async CRMWorkspaces(type) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.employeesAPI.CRMWorkspaces(token, type);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async usersUpdateDayoff(is_dayoff, id) {
    const token = this.user.main_token;
    const response = await api.employeesAPI.usersUpdateDayoff({
      token,
      is_dayoff,
      id,
    });
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  //employees////////////////////

  //myAccount////////////////////
  async usersGetMainData() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response1 = await api.myAccountAPI.getMainData(token);
    const response2 = await api.myAccountAPI.getData(token);
    const result1 = this.filterData(response1);
    const result2 = this.filterData(response2);

    if (!!result1 && !!result2) {
      this.setPreloader(false);
      const newData = {...result1, ...result2};
      return newData;
    }
  }

  async getAuthData() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.myAccountAPI.getAuthData(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getSettings() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.myAccountAPI.getSettings(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async updateSettings(data) {
    const token = this.user.main_token;
    const response = await api.myAccountAPI.updateSettings(token, data);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async getWallets() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.myAccountAPI.getWallets(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async addWallet(data) {
    const token = this.user.main_token;
    const response = await api.myAccountAPI.addWallet({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async updateWallet(data) {
    const token = this.user.main_token;
    const response = await api.myAccountAPI.updateWallet({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async getTransactions() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.myAccountAPI.getTransactions(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getWallet() {
    const token = this.user.main_token;
    const response = await api.myAccountAPI.getWallet(token);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
  }

  async deleteWallet(id) {
    const token = this.user.main_token;
    const response = await api.myAccountAPI.deleteWallet(token, id);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }
  //myAccount////////////////////

  //Leads///////////////////////

  async leadsGetLeads(page = '', perPage = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsGetLeads(token, page, perPage);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsSearchTelegramId(telegram_id, bot_id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsSearchTelegramId(token, telegram_id, bot_id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsSearchLeadId(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsSearchLeadId(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsSearchPlatformId(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsSearchPlatformId(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsGetLead(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsGetLead(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsGetStatuses() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsGetStatuses(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsGetLeadRegistrations(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsGetLeadRegistrations(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsGetLeadDeposits(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsGetLeadDeposits(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsTranfersGetLead(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsTranfersGetLead(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsGetChats(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsGetChats(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async leadsTranfersAddLtcTransfer(data) {
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsTranfersAddLtcTransfer({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async leadsTranfersAddRdTransfer(data) {
    const token = this.user.main_token;
    const response = await api.leadsAPI.leadsTranfersAddRdTransfer({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  //Leads///////////////////////

  //dev/////////////////////////

  async getServers(status = '', server_id = '', ip = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.devAPI.getServers('getServers', token, status, server_id, ip);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async addServer(data) {
    const token = this.user.main_token;
    const response = await api.devAPI.addServer('addServer', {...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async updateServer(data) {
    const token = this.user.main_token;
    const response = await api.devAPI.updateServer('updateServer', {...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async getDomains(status = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.devAPI.getServers('getDomains', token, status);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourceGetDomainsList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.devAPI.resourceGetDomainsList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async addDomain(data) {
    const token = this.user.main_token;
    const response = await api.devAPI.addServer('addDomain', {...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async updateDomain(data) {
    const token = this.user.main_token;
    const response = await api.devAPI.updateServer('updateDomain', {...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async resourceGetDomain(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.devAPI.resourceGetDomain(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourceGetServer(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.devAPI.resourceGetServer(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourcePWAgetApps(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWAgetApps(token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourcePWAgetApp(timeConfig, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWAgetApp(token, timeConfig, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourcePWAmainStats(timeConfig, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWAmainStats(token, timeConfig, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourcePWAgetAppPush(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWAgetAppPush({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return result;
    }
  }

  async resourcePWAgetAppPushForExel(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWAgetAppPush({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async resourcePWAGetAppPushActivity(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWAGetAppPushActivity({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return result;
    }
  }

  async resourcePWADeleteAppPush(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.resourcePWADeleteAppPush({token, id});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async PWAPushGetMainStats(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.PWAPushGetMainStats({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async PWAPushGetActivity(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.PWAPushGetActivity({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async PWAGetBayers(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pwaAPI.PWAGetBayers({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async resourceTelegramGetAccounts(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.telegramAPI.resourceTelegramGetAccounts({token, ...timeConfig});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async tgAccountsGetTgAccounts() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.telegramAPI.tgAccountsGetTgAccounts({token});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  //dev/////////////////////////

  //Offers/////////////////////////////

  async offersGetOffers() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.offersAPI.offersGetOffers(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async offersGetOffersList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.offersAPI.offersGetOffersList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async offersGetOffer(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.offersAPI.offersGetOffer(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async offersGetOfferStats(timeConfig, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.offersAPI.offersGetOfferStats(token, timeConfig, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async offersAddOffer(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.offersAPI.offersAddOffer(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async offersUpdateOffer(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.offersAPI.offersUpdateOffer(data);

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  //Offers/////////////////////////////

  //Channels///////////////////////////

  async channelsGetChannels(type = 'all') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.channelsAPI.channelsGetChannels(token, type);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async channelsGetChannelsList(type, bot_id = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.channelsAPI.channelsGetChannelsList(token, type, bot_id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async channelsGetChannel(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.channelsAPI.channelsGetChannel(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async channelsAddChannel(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.channelsAPI.channelsAddChannel(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async channelsUpdateChannel(data, id) {
    const token = this.user.main_token;
    data.append('token', token);
    data.append('id', id);
    const response = await api.channelsAPI.channelsUpdateChannel(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  //Channels///////////////////////////

  //Bots///////////////////////////

  async botsGetBots() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.botsAPI.botsGetBots(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async botsGetListBots() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.botsAPI.botsGetListBots(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async botsGetBot(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.botsAPI.botsGetBot(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async botsAddBot(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.botsAPI.botsAddBot({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async botsUpdateBot(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.botsAPI.botsUpdateBot({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async botsGetBotInfo(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.botsAPI.botsGetBotInfo(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  //Bots///////////////////////////

  //Pixels///////////////////////////

  async getPixels() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pixelsAPI.getPixels(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getPixel(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pixelsAPI.getPixel(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  // async getPixelLogs(id, timeConfig, page, perPage) {
  //   this.setPreloader(true);
  //   const token = this.user.main_token;
  //   const response = await api.pixelsAPI.getPixelLogs(token, id, timeConfig, page, perPage);

  //   const result = this.filterData(response);

  //   if (!!result) {
  //     this.setPreloader(false);
  //     return result;
  //   }
  // }

  async getPixelLogsPortion(data) {
    const token = this.user.main_token;
    const response = await api.pixelsAPI.getPixelLogs({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
  }

  async getFacebookEvents() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pixelsAPI.getFacebookEvents(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async pixelsGetFacebookEvents() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.pixelsAPI.pixelsGetFacebookEvents(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async addPixel(data) {
    const token = this.user.main_token;
    const response = await api.pixelsAPI.addPixel({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
  }

  async updatePixel(data) {
    const token = this.user.main_token;
    const response = await api.pixelsAPI.updatePixel({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
  }

  //Pixels///////////////////////////

  //Departments///////////////////////////

  async departamentsGetList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.departmentsAPI.departamentsGetList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  //Departments///////////////////////////

  //Landings///////////////////////////

  async getLandings() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.landingsAPI.getLandings(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async addLanding(data) {
    const token = this.user.main_token;
    const response = await api.landingsAPI.addLanding({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async updateLanding(data) {
    const token = this.user.main_token;
    const response = await api.landingsAPI.updateLanding({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  //Departments///////////////////////////

  //Sales//////////////////////////////

  async salesGetManagersTop(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesGetManagersTop({token, timeConfig});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesTeamsLtcGetStages(id, workspace_id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsLtcGetStages({token, id, workspace_id});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesTeamsRdGetStages(id, workspace_id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsRdGetStages({token, id, workspace_id});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesManagerLtcGetStages(id, workspace_id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerLtcGetStages({token, id, workspace_id});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesManagerRdGetStages(id, workspace_id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerRdGetStages({token, id, workspace_id});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesLtcGetLeadActivity(time_config) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesLtcGetLeadActivity(token, time_config);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesRdAnalysisGetMainStats(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdAnalysisGetMainStats({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesRdAnalysisGetSoft(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdAnalysisGetSoft({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesRdAnalysisGetMainStatsSoft(data) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdAnalysisGetMainStatsSoft(token, data);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesGetLtcStats(time_config, filter = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesGetLtcStats(token, time_config, filter);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesGetRdStats(time_config, filter = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesGetRdStats(token, time_config, filter);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesGetKpiLtcList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesGetKpiLtcList(token);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsLtcGetTeams(time_config, filter = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsLtcGetTeams(token, time_config, filter);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesTeamsLtcGetTop(time_config) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsLtcGetTop(token, time_config);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesTeamsRdGetTeams(time_config, filter = '') {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsRdGetTeams(token, time_config, filter);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesGetKpiRdList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesGetKpiRdList(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesGetLtcManagersList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesGetLtcManagersList(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async getRdManagersList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.getRdManagersList(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsAddTeam(data) {
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsAddTeam({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async salesTeamsUpdateTeam(data) {
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsUpdateTeam({...data, token});

    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
    return null;
  }

  async salesTeamsLtcMainStats(time_config, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsLtcMainStats(token, time_config, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsRdMainStats(time_config, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsRdMainStats(token, time_config, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsLtcGetManagersStats(time_config, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsLtcGetManagersStats(token, time_config, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsRdGetManagersStats(time_config, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsRdGetManagersStats(token, time_config, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsGetList(type) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsGetList(token, type);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesLtcGetManagersStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesLtcGetManagersStats(token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesRdGetManagersStats(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdGetManagersStats(token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesManagerRdMainStats(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerRdMainStats(token, id, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesManagerLtcMainStats(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerLtcMainStats(token, id, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesManagerLtcGeoQualifications(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerLtcGeoQualifications(token, id, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesManagerLtcQualificationSchedule(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerLtcQualificationSchedule(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesManagerRdDepositsSchedule(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerRdDepositsSchedule(token, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesLtcManagersGetTop(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesLtcManagersGetTop(token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesRdManagersGetTop(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdManagersGetTop(token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return {data: result, time: response.time};
    }
  }

  async salesTeamsRdGetLeadsMetadata(timeConfig, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsRdGetLeadsMetadata(token, timeConfig, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesManagerRdGetLeadsMetadata(timeConfig, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerRdGetLeadsMetadata(token, timeConfig, id);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }
  async salesRdLeadsGetLeadsMetadata(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdLeadsGetLeadsMetadata(token, timeConfig);

    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async salesTeamsRdGetLeads(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesTeamsRdGetLeads({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return result;
    }
  }

  async salesManagerRdGetLeads(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerRdGetLeads({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return result;
    }
  }

  async salesRdLeadsGetLeads(data) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.salesAPI.salesRdLeadsGetLeads({token, ...data});

    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return result;
    }
  }

  async salesManagerUpdateTraffic(is_traffic, id) {
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerUpdateTraffic({
      token,
      is_traffic,
      manager_id: id,
    });
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async salesManagerUpdateDayoff(is_dayoff, id) {
    const token = this.user.main_token;
    const response = await api.salesAPI.salesManagerUpdateDayoff({
      token,
      is_dayoff,
      manager_id: id,
    });
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }
  //Sales//////////////////////////////

  //traffic/////////////////////////////

  async trafficsInfluenceGetTrafficsList({page, selectedBayer, queryText, timeConfig}) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceGetTrafficsList({
      token,
      page,
      selectedBayer,
      queryText,
      timeConfig
    });
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceGetTrafficsListNoPreloader({page, selectedBayer, queryText, timeConfig}) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceGetTrafficsList({
      token,
      page,
      selectedBayer,
      queryText,
      timeConfig
    });
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }
  }

  async trafficsInfluenceGetTrafficsListForExelNoPreloader({ selectedBayer, queryText, timeConfig}) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceGetTrafficsListForExel({
      token,
      selectedBayer,
      queryText,
      timeConfig
    });
    const result = this.filterData(response);

    if (!!result) {
      return {data: result, time:response.time};
    }
  }

  async trafficsInfluenceBayerGetTrafficsList(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceBayerGetTrafficsList(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsGetInfo(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsGetInfo(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookGetTrafficsList(timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookGetTrafficsList(token, timeConfig);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceGetBayersList() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceGetBayersList(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsBayerGetInfo(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsBayerGetInfo(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsAddTraffic(data, type) {
    const token = this.user.main_token;
    data.append('token', token);
    data.append('type', type);
    const response = await api.trafficAPI.trafficsAddTraffic(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsLinksUpdateStatus(data) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsLinksUpdateStatus({...data, token});
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsInfluenceGetTrafficStats(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsInfluenceGetTrafficStats(token, id, timeConfig);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookGetTrafficConversions(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsFacebookGetTrafficConversions(
      token,
      id,
      timeConfig
    );
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookLinksGetConversions(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsFacebookLinksGetConversions(
      token,
      id,
      timeConfig
    );
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceGetLinks(id) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsInfluenceGetLinks(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookGetLinks(params) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsFacebookGetLinks({token, ...params});
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookGetLinksWithFilter(params) {
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsFacebookGetLinks({token, ...params});
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceLinksGetStatuses() {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsInfluenceLinksGetStatuses(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookGetTrafficStats(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.trafficAPI.trafficsFacebookGetTrafficStats(token, id, timeConfig);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsUpdateTraffic(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.trafficAPI.trafficsUpdateTraffic(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsInfluencelinksUpdateLink(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.trafficAPI.trafficsInfluencelinksUpdateLink(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsFacebookGetBayersStats(tc) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookGetBayersStats(token, tc);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookBayerMainStats(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookBayerMainStats(token, timeConfig, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceGetBayersStats(tc) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceGetBayersStats(token, tc);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceBayerMainStats(id, timeConfig) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceBayerMainStats(token, timeConfig, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookLinksGetMainStats(tc, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookLinksGetMainStats(token, tc, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceLinksGetMainStats(tc, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceLinksGetMainStats(token, tc, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceLinksGetInfo(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceLinksGetInfo(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookLinksGetInfo(id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookLinksGetInfo(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookLinksTrackerGetCompanies(timeConfig, id) {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookLinksTrackerGetCompanies(
      token,
      timeConfig,
      id
    );
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async trafficsFacebookLinksTrackerGetAdsets(timeConfig, id) {
    // this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookLinksTrackerGetAdsets(
      token,
      timeConfig,
      id
    );
    const result = this.filterData(response);

    if (!!result) {
      // this.setPreloader(false);
      return result;
    }
  }

  async trafficsInfluenceLinksAddLink(data) {
    const token = this.user.main_token;
    const newData = {...data, token};
    const response = await api.trafficAPI.trafficsInfluenceLinksAddLink(newData);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsInfluenceLinksDeleteLink(id) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsInfluenceLinksDeleteLink({token, id});
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsGetLogs(data) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsGetLogs({token, ...data});
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsGetActives(id, date) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsGetActives({token, team_id: id, date});
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async trafficsFacebookBayerInfo(timeConfig, id) {
    const token = this.user.main_token;
    const response = await api.trafficAPI.trafficsFacebookBayerInfo({token, id, timeConfig});
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  //traffic/////////////////////////////

  //Advertisers///////////////////////

  async getAdvertisersList() {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.advertisersAPI.salesTeamsGetList(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async advertisersGetAdvertiser(id) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.advertisersAPI.advertisersGetAdvertiser(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async advertisersGetOffers(id) {
    this.setPreloader(true);
    const token = this.user.main_token;

    const response = await api.advertisersAPI.advertisersGetOffers(token, id);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  async advertisersAddAdvertiser(data) {
    const token = this.user.main_token;
    data.append('token', token);
    const response = await api.advertisersAPI.advertisersAddAdvertiser(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  async advertisersUpdateAdvertiser(data, id) {
    const token = this.user.main_token;
    data.append('token', token);
    data.append('id', id);
    const response = await api.advertisersAPI.advertisersUpdateAdvertiser(data);
    const result = this.filterData(response);

    if (!!result) {
      return result;
    }

    return null;
  }

  //Advertisers///////////////////////

  //Countries/////////////////////////

  async getCountries() {
    this.setPreloader(true);
    const token = this.user.main_token;
    const response = await api.countriesAPI.getCountries(token);
    const result = this.filterData(response);

    if (!!result) {
      this.setPreloader(false);
      return result;
    }
  }

  //Countries/////////////////////////

  async hydrate(initialProps) {
    // console.log('store::hydrate')
    Object.keys(initialProps).forEach((key) => {
      // console.log('store::hydrate, adding initial');
      // console.log(key, initialProps[`${key}`]);
      this[`${key}`] = initialProps[`${key}`];
    });
    await hydrateStore(this);
  }
}

const initialize = (initialProps) => {
  const _store = store ?? new Store();
  if (initialProps) {
    (async () => {
      await _store.hydrate(initialProps);
    })();
  }
  return _store;
};

const StoreContext = createContext(null);

const StoreProvider = ({store, children}) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

const initStore = (initialProps) => {
  // console.log('store::initStore');
  // console.log('initialProps', initialProps);
  return initialize(initialProps);
};

const useStore = () => {
  return useContext(StoreContext);
};

export {StoreProvider, initStore, useStore, Store};

// export default new Store();
