import React from 'react'
import {Button} from '../../../shared/Buttons'
import Card from '../../../shared/Card'

const Invoices = () => {
  return (
    <>
      <Card className='card card-flush pt-3 mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h2>Invoices</h2>
          </div>

          <div className='card-toolbar'>
            <ul
              className='nav nav-stretch fs-5 fw-semibold nav-line-tabs nav-line-tabs-2x border-transparent'
              role='tablist'
            >
              <li className='nav-item' role='presentation'>
                <a
                  id='kt_referrals_year_tab'
                  className='nav-link text-active-primary active'
                  data-bs-toggle='tab'
                  role='tab'
                  href='#kt_customer_details_invoices_1'
                >
                  This Year
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  id='kt_referrals_2019_tab'
                  className='nav-link text-active-primary ms-3'
                  data-bs-toggle='tab'
                  role='tab'
                  href='#kt_customer_details_invoices_2'
                >
                  2020
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  id='kt_referrals_2018_tab'
                  className='nav-link text-active-primary ms-3'
                  data-bs-toggle='tab'
                  role='tab'
                  href='#kt_customer_details_invoices_3'
                >
                  2019
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  id='kt_referrals_2017_tab'
                  className='nav-link text-active-primary ms-3'
                  data-bs-toggle='tab'
                  role='tab'
                  href='#kt_customer_details_invoices_4'
                >
                  2018
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='card-body pt-2'>
          <div id='kt_referred_users_tab_content' className='tab-content'>
            <div
              id='kt_customer_details_invoices_1'
              className='tab-pane fade show active'
              role='tabpanel'
            >
              <div className='table-responsive'>
                <table
                  id='kt_customer_details_invoices_table_1'
                  className='table align-middle table-row-dashed fs-6 fw-bold gs-0 gy-4 p-0 m-0'
                >
                  <thead className='border-bottom border-gray-200 fs-7 text-uppercase fw-bold'>
                    <tr className='text-start text-gray-400'>
                      <th className='min-w-100px'>Order ID</th>
                      <th className='min-w-100px'>Amount</th>
                      <th className='min-w-100px'>Status</th>
                      <th className='min-w-125px'>Date</th>
                      <th className='w-100px'>Invoice</th>
                    </tr>
                  </thead>
                  <tbody className='fs-6 fw-semibold text-gray-600'>
                    <tr>
                      <td>
                        <span className='text-gray-600 text-hover-primary'>102445788</span>
                      </td>
                      <td className='text-success'>$38.00</td>
                      <td>
                        <span className='badge badge-light-danger'>Rejected</span>
                      </td>
                      <td>Nov 01, 2020</td>
                      <td className=''>
                        <Button color='btn-light btn-active-light-primary'>Download</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className='text-gray-600 text-hover-primary'>423445721</span>
                      </td>
                      <td className='text-danger'>$-2.60</td>
                      <td>
                        <span className='badge badge-light-info'>In progress</span>
                      </td>
                      <td>Oct 24, 2020</td>
                      <td className=''>
                        <Button color='btn-light btn-active-light-primary'>Download</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className='text-gray-600 text-hover-primary'>312445984</span>
                      </td>
                      <td className='text-success'>$76.00</td>
                      <td>
                        <span className='badge badge-light-success'>Approved</span>
                      </td>
                      <td>Oct 08, 2020</td>
                      <td className=''>
                        <Button color='btn-light btn-active-light-primary'>Download</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className='text-gray-600 text-hover-primary'>312445984</span>
                      </td>
                      <td className='text-success'>$5.00</td>
                      <td>
                        <span className='badge badge-light-success'>Approved</span>
                      </td>
                      <td>Sep 15, 2020</td>
                      <td className=''>
                        <Button color='btn-light btn-active-light-primary'>Download</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className='text-gray-600 text-hover-primary'>523445943</span>
                      </td>
                      <td className='text-danger'>$-1.30</td>
                      <td>
                        <span className='badge badge-light-danger'>Rejected</span>
                      </td>
                      <td>May 30, 2020</td>
                      <td className=''>
                        <Button color='btn-light btn-active-light-primary'>Download</Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default Invoices
