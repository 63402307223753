/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';

const Settings = ({isUser, getSettings, updateSettings}) => {
  const store = useStore();
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      const result = await getSettings(isUser);

      setData(result);
      return;
    })();
  }, []);

  const handleChange = async (e) => {
    const {checked, name} = e.target;

    const result = await updateSettings({...data, [name]: checked, isUser});

    notify(!!result, !!result ? 'Успешно обновлено!' : 'Произошла ошибка!');

    setData(result);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  return (
    <>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Настройки</h3>
          </div>
        </div>
        <div id='kt_account_settings_connected_accounts' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='py-2'>
              <div className='d-flex flex-stack'>
                <div className='d-flex'>
                  <div className='d-flex flex-column'>
                    <span className='fs-5 text-dark text-hover-primary fw-bold'>
                      Выплата зарплаты
                    </span>
                    <div className='fs-6 fw-semibold text-gray-400'>
                      Вы можете хранить деньги у себя на аккаунте
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='form-check form-check-solid form-check-custom form-switch'>
                    <input
                      className='form-check-input w-45px h-30px'
                      style={{cursor: 'pointer'}}
                      type='checkbox'
                      name='is_payment'
                      checked={!!data?.is_payment}
                      onChange={handleChange}
                      disabled={isUser ? true : false}
                    />
                    <label className='form-check-label' htmlFor='githubswitch'></label>
                  </div>
                </div>
              </div>

              <div className='separator separator-dashed my-5'></div>

              <div className='d-flex flex-stack'>
                <div className='d-flex'>
                  <div className='d-flex flex-column'>
                    <span className='fs-5 text-dark text-hover-primary fw-bold'>
                      Уведомления в телеграм бот
                    </span>
                    <div className='fs-6 fw-semibold text-gray-400'>
                      Получайте важные уведомления о вашем доходе
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='form-check form-check-solid form-check-custom form-switch'>
                    <input
                      className='form-check-input w-45px h-30px'
                      style={{cursor: 'pointer'}}
                      type='checkbox'
                      name='is_notification'
                      checked={!!data?.is_notification}
                      onChange={handleChange}
                      disabled={isUser ? true : false}
                    />
                    <label className='form-check-label' htmlFor='slackswitch'></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Button>Сохранить</Button>
          </div> */}
        </div>
      </Card>
    </>
  );
};

export default observer(Settings);
