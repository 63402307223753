/* eslint-disable no-unused-vars */
import React from 'react';
import Card from '../shared/Card';

const Finances = ({color, stats, title, subTitle, background}) => {
  const dynamicURL = require(`../../_metronic/assets/media/${background}.png`);
  const style = {backgroundImage: `url(${dynamicURL})`};

  return (
    <>
      <Card className='card card-flush h-xl-100 pb-10'>
        <div
          className='card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px'
          style={style}
          data-bs-theme='light'
        >
          <h3 className='card-title align-items-start flex-column text-white pt-15'>
            <span className='fw-bold fs-2x mb-3'>{title}</span>
            <div className='fs-4 text-white'>
              <span className='opacity-75'>{subTitle}</span>
            </div>
          </h3>
        </div>
        <div className='mx-xxl-8 mx-xl-5 mx-10 mt-n20'>
          <div className='mt-n20 position-relative'>
            <div className='row g-3 g-lg-6'>
              {stats?.map((el, i) => {
                return (
                  <div className='col-6' key={`${el.number}-${el.title}`}>
                    <div className='bg-gray-100 bg-opacity-70 rounded-2 px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'>
                      <div className='symbol symbol-30px me-5 mb-8'>{el.icon}</div>

                      <div className='m-0'>
                        <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                          {el.number}
                        </span>

                        <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>
                          {el.title}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Finances;
